import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth";
import taskReducer from "./tasks";
import adminReducer from "./admin";
import vendorReducer from "./vendors";
import agentReducer from "./agents";
import jobTypeReducer from "./jobType";
import businessTypeReducer from "./businessType";
import operationalCityReducer from "./operationalCity";
import zonesReducer from "./zones";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = () =>
  combineReducers({
    auth: authReducer,
    admin: adminReducer,
    task: taskReducer,
    vendor: vendorReducer,
    agent: agentReducer,
    jobType: jobTypeReducer,
    businessType: businessTypeReducer,
    operationalCity: operationalCityReducer,
    zones: zonesReducer,
  });

export default persistReducer(persistConfig, rootReducer());
