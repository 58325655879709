import { call, takeLatest, put, select } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createJobTypeService,
  getAllJobTypeService,
  fetchJobTypeService,
  updateJobTypeService,
  deleteJobTypeService,
} from "../../services/jobType";
import { toast } from "react-toastify";
import {
  CREATE_JOB_TYPE,
  GET_ONE_JOB_TYPE,
  GET_ALL_JOB_TYPES,
  UPDATE_JOB_TYPE,
  DELETE_JOB_TYPE,
} from "../actions";

export function* getAllJobTypeSaga(action) {
  try {
    const { paginationData } = yield select((state) => state.jobType);
    let res = yield call(
      getAllJobTypeService,
      action.payload ? action.payload : paginationData
    );
    if (res.success) {
      yield put(GET_ALL_JOB_TYPES.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_JOB_TYPES.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_JOB_TYPES.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* createJobTypeSaga(action) {
  try {
    let res = yield call(createJobTypeService, action.payload);
    if (res.success) {
      yield put(CREATE_JOB_TYPE.SUCCESS(res.data));
      toast.success("Job Type Created!");
    } else {
      yield put(CREATE_JOB_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_JOB_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateJobTypeSaga(action) {
  try {
    let res = yield call(updateJobTypeService, action.payload);
    console.log("RES : ", res);
    if (res.success) {
      yield put(UPDATE_JOB_TYPE.SUCCESS(action.payload));
      yield action.payload.navigate("/jobType");
      toast.success("Job Type Updated!");
    } else {
      yield put(UPDATE_JOB_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_JOB_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* fetchJobTypeSaga(action) {
  try {
    let res = yield call(fetchJobTypeService, action.payload);
    if (res.success) {
      yield put(GET_ONE_JOB_TYPE.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_JOB_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_JOB_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteJobTypeSaga(action) {
  try {
    let res = yield call(deleteJobTypeService, action.payload);
    if (res.success) {
      yield put(DELETE_JOB_TYPE.SUCCESS(action.payload));
      toast.success("Job Type Deleted!");
    } else {
      yield put(DELETE_JOB_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_JOB_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const jobTypeSaga = () => [
  takeLatest(ActionTypes.JobTypes.CREATE_JOB_TYPE.REQUEST, createJobTypeSaga),
  takeLatest(ActionTypes.JobTypes.GET_ALL_JOB_TYPES.REQUEST, getAllJobTypeSaga),
  takeLatest(ActionTypes.JobTypes.GET_ONE_JOB_TYPE.REQUEST, fetchJobTypeSaga),
  takeLatest(ActionTypes.JobTypes.UPDATE_JOB_TYPE.REQUEST, updateJobTypeSaga),
  takeLatest(ActionTypes.JobTypes.DELETE_JOB_TYPE.REQUEST, deleteJobTypeSaga),
];
export default jobTypeSaga();
