import ToggleButton from "../ToggleButton";
import { BsFillCaretDownFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions";
import { get } from "lodash";

const Navbar = ({ toggleShowSidebar }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { userData, deviceToken } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const toggleShowDropdown = (e) => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    dispatch(
      logout.REQUEST({ deviceToken, email: get(userData, "email", "") })
    );
  };

  const profileDropdown = [
    {
      label: "Profile",
    },
    {
      label: "Logout",
      clickHandler: handleLogout,
    },
  ];

  return (
    <nav className={`p-4 flex items-center justify-between`}>
      <label
        htmlFor="my-drawer"
        className="btn btn-ghost drawer-button visible md:invisible"
      >
        <GiHamburgerMenu
          // className="md:hidden cursor-pointer"
          size={20}
          // onClick={toggleShowSidebar}
        />
      </label>
      <section className="flex items-center">
        <ToggleButton />
        <span
          className="cursor-pointer px-2 font-bold ignoreClick"
          onClick={toggleShowDropdown}
        >
          <div class="dropdown dropdown-end flex">
            <label tabindex="0" class="flex items-center cursor-pointer">
              {userData?.name || ""}
            </label>
            <span
              className="mx-2 text-red hover:bg-red hover:text-white px-4 py-2 rounded text-sm"
              onClick={handleLogout}
            >
              Logout
            </span>
          </div>
        </span>
      </section>
    </nav>
  );
};

export default Navbar;
