import React, { useEffect, useState } from "react";
import { EditFieldModal, SecondaryNavbar, Table } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_JOB_TYPE,
  GET_ALL_JOB_TYPES,
  setJobTypePagination,
  UPDATE_JOB_TYPE,
} from "../../store/actions";
import { isArray } from "lodash";
import { getJobTypeColumns } from "../../constants/tableHeaders";
import { useNavigate, useParams } from "react-router-dom";

const initData = {
  title: "",
};

const JobTypes = () => {
  const { action, id } = useParams();
  const { paginationData, allJobTypes: data, pages } = useSelector(
    (state) => state.jobType
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({ ...initData });
  const [readOnly, setReadOnly] = useState(true);

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  useEffect(() => {
    dispatch(GET_ALL_JOB_TYPES.REQUEST(paginationData));

    return () => {
      console.log("Component Unmounted from JobTypes");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const onChangePageNumber = (val) => {
    dispatch(setJobTypePagination({ ...paginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(setJobTypePagination({ ...paginationData, perPage: val }));
  };

  const onToggleEditModal = () => {
    if (window.location.pathname?.includes("create")) {
      navigate("/jobType");
    }
    if (showEditModal) {
      setEditModalData({ ...initData });
    }
    setShowEditModal(!showEditModal);
  };

  const onCreateJobType = () => {
    const isCreate = window.location.pathname?.includes("create");
    if (isCreate) {
      // create moved to form
      // if (editModalData.title.length) {
      //   let jobTypeObj = {
      //     ...editModalData,
      //     categoryId: id,
      //   };
      //   // dispatch(CREATE_JOB_TYPE.REQUEST(jobTypeObj));
      // }
    } else {
      // edit case
      if (editModalData.title.length) {
        let jobTypeObj = {
          title: editModalData.title,
          _id: editModalData._id,
        };
        dispatch(UPDATE_JOB_TYPE.REQUEST(jobTypeObj));
        onToggleEditModal();
      }
    }
  };

  return (
    <>
      <SecondaryNavbar
        title="Job Type"
        btnTitle="Add"
        onClick={() => {
          onToggleEditModal();
          navigate("/jobType/create");
        }}
      />
      <EditFieldModal
        title={"Job Types"}
        name={"title"}
        type={"text"}
        value={editModalData.title}
        onChange={(e) =>
          setEditModalData({ ...editModalData, title: e.target.value })
        }
        onSubmit={() => onCreateJobType()}
        isOpen={showEditModal}
        closeModal={onToggleEditModal}
        paramAction={"Edit"}
      />
      {isArray(data) && (
        <Table
          darkTheme={darkTheme}
          columns={getJobTypeColumns(onToggleEditModal, setEditModalData)}
          data={data}
          paginationData={paginationData}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
          totalPages={pages}
        />
      )}
    </>
  );
};

export default JobTypes;
