import React, { useEffect, useState } from "react";
import { EditFieldModal, SecondaryNavbar, Table } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_BUSINESS_TYPE,
  GET_ALL_BUSINESS_TYPES,
  setBusinessTypePagination,
  UPDATE_BUSINESS_TYPE,
} from "../../store/actions";
import { isArray } from "lodash";
import { getBusinessTypeColumns } from "../../constants/tableHeaders";
import { useNavigate } from "react-router-dom";

const initBusinessType = {
  title: "",
  vendors: 0,
  _id: "",
};

const BusinessTypes = () => {
  const {
    paginationData,
    allBusinessTypes: data,
    pages,
    selectedBusinessType,
  } = useSelector((state) => state.businessType);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    dispatch(GET_ALL_BUSINESS_TYPES.REQUEST(paginationData));

    return () => {
      console.log("Component Unmounted from BusinessTypes");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({ ...initBusinessType });

  const onChangePageNumber = (val) => {
    dispatch(setBusinessTypePagination({ ...paginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(setBusinessTypePagination({ ...paginationData, perPage: val }));
  };

  const onToggleEditModal = () => {
    if (window.location.pathname?.includes("create")) {
      navigate("/businessType");
    }
    if (showEditModal) {
      setEditModalData({ ...initBusinessType });
    }
    setShowEditModal(!showEditModal);
  };

  const handleSubmit = () => {
    if (window.location.pathname?.includes("create")) {
      let tempSelectedBusinessType = { ...editModalData };
      dispatch(CREATE_BUSINESS_TYPE.REQUEST(tempSelectedBusinessType));
    } else {
      let tempSelectedBusinessType = { ...editModalData };
      dispatch(UPDATE_BUSINESS_TYPE.REQUEST(tempSelectedBusinessType));
    }
    onToggleEditModal();
  };

  return (
    <>
      <SecondaryNavbar
        title="Business Type"
        btnTitle="Add"
        onClick={() => {
          onToggleEditModal();
          navigate("/businessType/create");
        }}
      />
      {isArray(data) && (
        <Table
          darkTheme={darkTheme}
          columns={getBusinessTypeColumns(onToggleEditModal, setEditModalData)}
          data={data}
          paginationData={paginationData}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
          totalPages={pages}
        />
      )}
      <EditFieldModal
        title={"Business Type"}
        name={"title"}
        type={"text"}
        value={editModalData.title}
        onChange={(e) =>
          setEditModalData({ ...editModalData, title: e.target.value })
        }
        onSubmit={handleSubmit}
        isOpen={showEditModal}
        closeModal={onToggleEditModal}
      />
    </>
  );
};

export default BusinessTypes;
