import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const GET_ALL_BUSINESS_TYPES = actionCreator(
  ActionTypes.BusinessTypes.GET_ALL_BUSINESS_TYPES
);

export const CREATE_BUSINESS_TYPE = actionCreator(
  ActionTypes.BusinessTypes.CREATE_BUSINESS_TYPE
);

export const GET_ONE_BUSINESS_TYPE = actionCreator(
  ActionTypes.BusinessTypes.GET_ONE_BUSINESS_TYPE
);

export const UPDATE_BUSINESS_TYPE = actionCreator(
  ActionTypes.BusinessTypes.UPDATE_BUSINESS_TYPE
);

export const DELETE_BUSINESS_TYPE = actionCreator(
  ActionTypes.BusinessTypes.DELETE_BUSINESS_TYPE
);

export const setBusinessTypePagination = (payload) => ({
  type: ActionTypes.BusinessTypes.SET_BUSINESS_TYPE_PAGINATION,
  payload,
});
