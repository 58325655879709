import { useState } from "react";
import { useSelector } from "react-redux";

const ArrayText = ({
  type,
  value,
  onChange,
  placeholder,
  showError,
  isReadOnly,
  errorMessage,
  title,
  name,
  inputProps,
}) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);
  const [val, setVal] = useState("");
  return (
    <section className={`flex flex-col mb-4`}>
      <h2
        className={`${
          darkTheme ? "text-white" : "text-text_inp_title"
        } cursor-default text-sm`}
      >
        {title}
      </h2>
      <input
        name={name}
        type={type}
        value={val}
        onChange={(e) => {
          if (e.target.value[e.target.value?.length - 1] === ",") {
            let tempVal = [...value, val];
            setVal("");
            onChange(tempVal);
          } else {
            setVal(e.target.value);
          }
        }}
        placeholder={placeholder}
        className={`p-2 mt-1 rounded-md bg-transparent text-black ${
          !darkTheme && "hover:border-black"
        } ${isReadOnly ? "" : "border"}`}
        disabled={isReadOnly}
        {...inputProps}
      />
      <div className="">
        {value?.map((v, index) => {
          return (
            <span
              className={`${
                darkTheme ? "border" : "bg-gray-200"
              } mx-2 px-1 rounded-xl flex items-center justify-between my-2 py-1`}
            >
              <span key={index}>{`${v}`}</span>
              <button
                className="bg-gray-300 px-2 py-2 items-center justify-center rounded-full"
                onClick={() => {
                  let tempVal = value;
                  tempVal.splice(index, 1);
                  onChange(tempVal);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-2 w-2"
                  fill="none"
                  viewBox="0 0 12 12"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 9L9 3M3 3l6 6"
                  />
                </svg>
              </button>
            </span>
          );
        })}
      </div>
      {!isReadOnly && (
        <span
          className={`text-red text-xs px-2 ${
            showError ? "visible" : "invisible"
          }`}
        >
          {errorMessage}
        </span>
      )}
    </section>
  );
};

export default ArrayText;
