import { get, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button2, Dropdown, LocalTable, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import {
  brandManufacturerProductsColumns,
  brandProductsColumns,
} from "../../constants/tableHeaders";
import {
  CREATE_OPERATIONAL_CITY,
  FETCH_OPERATIONAL_CITY,
  UPDATE_OPERATIONAL_CITY,
} from "../../store/actions";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import { getMiddle } from "../../utils";

const initGeneralData = {
  city: "",
  zone: "",
};

export default function OperationalCityForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const [path, setPath] = useState([]);
  const [zoneData, setZoneData] = useState({});
  const [generalData, setGeneralData] = useState({ ...initGeneralData });
  const [allZonesOptions, setAllZonesOptions] = useState([]);

  const { selectedOperationalCity } = useSelector(
    (state) => state.operationalCity
  );
  const { allZones } = useSelector((state) => state.zones);
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  useEffect(() => {
    if (id && id !== "default")
      dispatch(FETCH_OPERATIONAL_CITY.REQUEST({ id }));
    let tempAllZones = [];
    allZones.map((zone) =>
      tempAllZones.push({ label: zone.geolocation?.name, value: zone._id })
    );
    setAllZonesOptions(tempAllZones);
  }, [id]);

  useEffect(() => {
    let selectedZone = selectedOperationalCity?.zone;
    if (
      action !== "create" &&
      !isNil(selectedZone) &&
      typeof selectedZone === "object"
    ) {
      let data = get(selectedZone, "geolocation", {});
      setZoneData(data);
      if (data && !isNil(data.coordinates)) {
        let pathData = get(data, "coordinates[0]", []).map((point) => {
          return {
            lat: point[1],
            lng: point[0],
          };
        });
        setPath(pathData);
      }
    }
    if (action !== "create" && selectedOperationalCity) {
      setGeneralData({
        city: selectedOperationalCity.city,
        zone: selectedOperationalCity.zone?._id,
      });
    }
  }, [selectedOperationalCity]);

  const getLabel = () => {
    let name = "";
    if (generalData.zone && allZones) {
      name =
        allZones.filter((zone) => zone._id === generalData.zone)[0]?.geolocation
          ?.name || "";
    }
    return name;
  };

  const handleSubmit = () => {
    if (action === "create") {
      if (generalData.city && generalData.zone) {
        dispatch(
          CREATE_OPERATIONAL_CITY.REQUEST({
            data: generalData,
            navigate: navigate,
          })
        );
      }
    } else if (action === "edit") {
      if (generalData.city) {
        dispatch(
          UPDATE_OPERATIONAL_CITY.REQUEST({
            data: { city: generalData.city, id: selectedOperationalCity._id },
            navigate: navigate,
          })
        );
      }
    }
  };

  return (
    <div className="">
      <FormLayout title={"General"}>
        <section
          className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
        >
          <TextInput
            title={"City"}
            name={"city"}
            type={"text"}
            value={generalData?.city}
            onChange={(e) => {
              setGeneralData({ ...generalData, city: e.target.value });
            }}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={"Please enter city"}
          />
          <Dropdown
            title={"Zone"}
            name={"Select zone"}
            value={{
              value: generalData.zone,
              label: getLabel(),
            }}
            onChange={(e) => {
              console.log(e);
              setGeneralData({ ...generalData, zone: e.value });
            }}
            placeholder={""}
            showError={false}
            isReadOnly={readOnly}
            errorMessage={""}
            options={allZonesOptions}
          />
        </section>
      </FormLayout>
      {action === "view" && (
        <FormLayout title={"Zone"}>
          <div
            className={`grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Name"}
              name={"name"}
              type={"text"}
              value={selectedOperationalCity?.zone?.geolocation?.name}
              onChange={() => {}}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter zone name"}
            />
            <TextInput
              title={"Code"}
              name={"code"}
              type={"text"}
              value={selectedOperationalCity?.zone?.geolocation?.code}
              onChange={() => {}}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter zone code"}
            />
          </div>
          <div>
            <div style={{ height: "50vh" }}>
              <GoogleMap
                mapContainerClassName="App-map"
                center={{
                  lat:
                    path.length === 0
                      ? parseFloat(43.6532)
                      : getMiddle("lat", path),
                  lng:
                    path.length === 0
                      ? parseFloat(-79.3832)
                      : getMiddle("lng", path),
                }}
                zoom={11}
                version="weekly"
                on
                mapContainerStyle={{ height: "100%" }}
                options={{
                  gestureHandling: action === "view" ? "none" : "all",
                }}
                onClick={(event) => {
                  setPath([
                    ...path,
                    { lng: event.latLng.lng(), lat: event.latLng.lat() },
                  ]);
                }}
              >
                <Polygon
                  // Make the Polygon editable / draggable
                  editable={action !== "view"}
                  draggable={false}
                  path={path}
                  onMouseUp={() => {}}
                  onLoad={() => {}}
                  onUnmount={() => {}}
                />
              </GoogleMap>
            </div>
          </div>
        </FormLayout>
      )}
      {/* <LocalTable
          columns={brandProductsColumns}
          data={selectedBrand?.products || []}
          darkTheme={darkTheme}
          title={"Products"}
        /> */}
      {action !== "view" && (
        <Button2 title={"Submit"} handleClick={handleSubmit} />
      )}
    </div>
  );
}
