import { get } from "lodash";
import { RenderActions } from "../components";
import {
  BLOCK_AGENT,
  BLOCK_VENDOR,
  CANCEL_TASK,
  DELETE_BUSINESS_TYPE,
  DELETE_JOB_TYPE,
  DELETE_OPERATIONAL_CITY,
  DELETE_ZONE,
} from "../store/actions";

export const taskColumns = [
  {
    Header: "Status",
    accessor: "status",
    minWidth: 100,
  },
  {
    Header: "Order Category",
    accessor: "orderCategory",
    minWidth: 100,
    renderCell: (params) => get(params, "title", ""),
  },
  {
    Header: "Payment Mode",
    accessor: "paymentMode",
    minWidth: 100,
  },
  {
    Header: "Pickup Time",
    accessor: "pickupTime",
    minWidth: 150,
    renderCell: (params) => {
      let formattedDate = new Date(params).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return `${formattedDate}`;
    },
  },
  {
    Header: "Delivery Time",
    accessor: "deliveryTime",
    minWidth: 150,
    renderCell: (params) => {
      let formattedDate = new Date(params).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
      return `${formattedDate}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/task/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/task/edit/{{id}}"}
          />
          <RenderActions
            action="cancel"
            referenceId={get(params, "id", "")}
            deleteAction={CANCEL_TASK.REQUEST}
          />
        </div>
      );
    },
  },
];

export const getVendorColumns = (blockVendor, toggleBlockModal) => [
  {
    Header: "Name",
    accessor: "vendorId.name",
    minWidth: 100,
  },
  {
    Header: "Email",
    accessor: "vendorId.email",
    minWidth: 100,
  },
  {
    Header: "Contact Number",
    accessor: "contactNo",
    minWidth: 100,
  },
  {
    Header: "Completed Orders",
    accessor: "completedOrders",
    minWidth: 50,
    renderCell: (params) => {
      return `${get(params, "length", 0)}`;
    },
  },
  {
    Header: "Active Orders",
    accessor: "activeOrders",
    minWidth: 50,
    renderCell: (params) => {
      return `${get(params, "length", 0)}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/vendor/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/vendor/edit/{{id}}"}
          />
          <RenderActions
            action="blockModal"
            referenceId={get(params, "vendorId._id", "")}
            data={params}
            handleAction={(refId, data) => {
              blockVendor({
                name: get(data, "vendorId.name", ""),
                _id: refId,
              });
              toggleBlockModal();
            }}
          />
        </div>
      );
    },
  },
];

export const getAgentColumns = (blockAgent, toggleBlockModal) => [
  {
    Header: "Name",
    accessor: "agentId.name",
    minWidth: 100,
  },
  {
    Header: "Email",
    accessor: "agentId.email",
    minWidth: 100,
  },
  {
    Header: "Contact Number",
    accessor: "agentId.contactNo",
    minWidth: 100,
  },
  {
    Header: "Online",
    accessor: "isOnline",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Completed Jobs",
    accessor: "completedJobs",
    minWidth: 100,
    renderCell: (params) => {
      return `${get(params, "length", 0)}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/agent/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/agent/edit/{{id}}"}
          />
          <RenderActions
            action="blockModal"
            referenceId={get(params, "agentId._id", "")}
            data={params}
            handleAction={(refId, data) => {
              blockAgent({
                name: get(data, "agentId.name", ""),
                _id: refId,
              });
              toggleBlockModal();
            }}
          />
        </div>
      );
    },
  },
];
export const ZonesColumn = [
  {
    Header: "Name",
    accessor: "geolocation.name",
    minWidth: 100,
  },
  {
    Header: "Code",
    accessor: "geolocation.code",
    minWidth: 100,
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "geolocation.code", "")}
            navigateTo={"/zones/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "geolocation.code", "")}
            navigateTo={"/zones/edit/{{id}}"}
          />
          <RenderActions
            action="delete"
            referenceId={get(params, "_id", "")}
            deleteAction={DELETE_ZONE.REQUEST}
          />
        </div>
      );
    },
  },
];

export const getBusinessTypeColumns = (onToggleEditModal, setEditModalData) => {
  return [
    {
      Header: "Title",
      accessor: "title",
      minWidth: 100,
    },
    {
      Header: "Vendors",
      accessor: "vendors",
      minWidth: 100,
      renderCell: (params) => {
        return `${get(params, "length", 0)}`;
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div className="flex px-4">
            <RenderActions
              action="view"
              referenceId={get(params, "_id", "")}
              navigateTo={"/businessType/view/{{id}}"}
            />
            <RenderActions
              action="editModal"
              referenceId={get(params, "_id", "")}
              data={params}
              handleAction={(refId, data) => {
                setEditModalData({
                  title: data.title,
                  _id: refId,
                });
                onToggleEditModal();
              }}
            />
            <RenderActions
              action="delete"
              referenceId={get(params, "_id", "")}
              deleteAction={DELETE_BUSINESS_TYPE.REQUEST}
            />
          </div>
        );
      },
    },
  ];
};

export const getOperationalCityColumns = (
  onToggleEditModal,
  setEditModalData
) => {
  return [
    {
      Header: "City",
      accessor: "city",
      minWidth: 100,
    },
    {
      Header: "Vendors",
      accessor: "vendors",
      minWidth: 100,
      renderCell: (params) => {
        return `${get(params, "length", 0)}`;
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div className="flex px-4">
            <RenderActions
              action="view"
              referenceId={get(params, "_id", "")}
              navigateTo={"/operationalCity/view/{{id}}"}
            />
            <RenderActions
              action="edit"
              referenceId={get(params, "_id", "")}
              data={params}
              navigateTo={"/operationalCity/edit/{{id}}"}
              // handleAction={(refId, data) => {
              //   setEditModalData({
              //     title: data.title,
              //     _id: refId,
              //   });
              //   onToggleEditModal();
              // }}
            />
            <RenderActions
              action="delete"
              referenceId={get(params, "_id", "")}
              deleteAction={DELETE_OPERATIONAL_CITY.REQUEST}
            />
          </div>
        );
      },
    },
  ];
};

export const getJobTypeColumns = (onToggleEditModal, setEditModalData) => [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 100,
  },
  {
    Header: "Type",
    accessor: "type",
    minWidth: 100,
  },
  {
    Header: "Target Hours",
    accessor: "targetHours",
    minWidth: 100,
  },
  {
    Header: "Target Days",
    accessor: "targetDays",
    minWidth: 100,
  },
  {
    Header: "Expected Earning",
    accessor: "expectedEarning",
    minWidth: 100,
  },
  {
    Header: "Agents",
    accessor: "agents",
    minWidth: 100,
    renderCell: (params) => {
      return `${get(params, "length", 0)}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/jobType/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/jobType/edit/{{id}}"}
          />
          <RenderActions
            action="delete"
            referenceId={get(params, "_id", "")}
            deleteAction={DELETE_JOB_TYPE.REQUEST}
          />
        </div>
      );
    },
  },
];

export const adminColumns = [
  {
    Header: "Name",
    accessor: "name",
    minWidth: 150,
  },
  {
    Header: "Email",
    accessor: "email",
    minWidth: 150,
  },
  {
    Header: "Is Blocked",
    accessor: "isBlocked",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/all-admins/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/all-admins/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];
export const vendorColumns = [
  {
    Header: "Name",
    accessor: "name",
    minWidth: 150,
  },
  {
    Header: "Email",
    accessor: "email",
    minWidth: 150,
  },
  {
    Header: "Is Blocked",
    accessor: "isBlocked",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/all-admins/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/all-admins/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const activeOrdersColumn = [
  {
    Header: "Name",
    accessor: "name",
    minWidth: 150,
  },
  {
    Header: "Email",
    accessor: "email",
    minWidth: 150,
  },
  {
    Header: "Is Blocked",
    accessor: "isBlocked",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            // navigateTo={"/all-admins/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            // navigateTo={"/all-admins/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];
