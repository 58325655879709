import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { store } from "./store";
import { saveDeviceToken } from "./store/actions";

const firebaseConfig = {
  apiKey: "AIzaSyDo-Ehd8z8VI5cuP45Antei4EttdQy0ZrQ",
  authDomain: "hldelivery.firebaseapp.com",
  projectId: "hldelivery",
  storageBucket: "hldelivery.appspot.com",
  messagingSenderId: "692241513812",
  appId: "1:692241513812:web:ac19979cebf96dfa309cc1",
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const checkTokenExist = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BCSZsuVqvxIiGiAokJAKFKXXOyR1P7I3MhcG8qNRcIEmlUoIWyO_uYpcGdNEp5VaS_HO1_t2Gf_e1sMfaCJa9Zs",
  })
    .then((currentToken) => {
      const state = store.getState();
      console.log("Current Token ===> ", currentToken);
      if (currentToken && currentToken !== state.auth.deviceToken) {
        store.dispatch(saveDeviceToken(currentToken));
        return true;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return false;
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export default {
  messaging,
  checkTokenExist,
  onMessageListener,
};
