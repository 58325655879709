import React, { useEffect, useState } from "react";
import { SecondaryNavbar, Table } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  BLOCK_AGENT,
  GET_ALL_AGENTS,
  setAgentPagination,
} from "../../store/actions";
import { get, isArray } from "lodash";
import { getAgentColumns } from "../../constants/tableHeaders";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BlockModal from "../../components/BlockModal";

const Agents = () => {
  const { paginationData, allAgents: data, pages } = useSelector(
    (state) => state.agent
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    dispatch(GET_ALL_AGENTS.REQUEST(paginationData));

    return () => {
      console.log("Component Unmounted from Agents");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [blockData, setBlockData] = useState({ title: "", _id: "" });

  const onChangePageNumber = (val) => {
    dispatch(setAgentPagination({ ...paginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(setAgentPagination({ ...paginationData, perPage: val }));
  };

  const blockAgent = (data) => {
    setBlockData({ ...data });
  };

  const toggleBlockModal = () => {
    setShowBlockModal(!showBlockModal);
  };

  const handleBlock = () => {
    console.log(".....", blockData);
    if (blockData && blockData._id) {
      if (blockData.reason) {
        dispatch(BLOCK_AGENT.REQUEST({ value: blockData }));
        toggleBlockModal();
      } else {
        toast.error("Enter reason to block!");
      }
    }
  };

  return (
    <>
      <SecondaryNavbar
        title="Agents"
        btnTitle="Add"
        onClick={() => navigate("/agent/create")}
      />
      <BlockModal
        name={get(blockData, "name", "")}
        value={get(blockData, "reason", "")}
        onChange={(e) => setBlockData({ ...blockData, reason: e.target.value })}
        isOpen={showBlockModal}
        closeModal={toggleBlockModal}
        onSubmit={handleBlock}
      />
      {/* <div className={`my-8 mb-10`}>
        {[].map((item, ind) => {
          return (
            <span
              key={`span ${ind}`}
              className={`mx-2 transition duration-500 text-xl py-2 ${
                ind === selectedIndex
                  ? `${
                      !darkTheme
                        ? "text-dark_grey border-dark_grey"
                        : "text-white border-white"
                    } border-b-2 `
                  : "text-grey_text"
              } cursor-pointer`}
              onClick={() => setSelectedIndex(ind)}
            >
              {item}
            </span>
          );
        })}
      </div> */}
      {isArray(data) && (
        <Table
          darkTheme={darkTheme}
          columns={getAgentColumns(blockAgent, toggleBlockModal)}
          data={data}
          paginationData={paginationData}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
          totalPages={pages}
        />
      )}
    </>
  );
};

export default Agents;
