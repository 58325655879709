import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const getAllVendorService = async (data) => {
  try {
    let URL = apiURL + "vendor/getAll";
    let config = {
      params: {
        page: data.page,
        perPage: data.perPage,
        status: data.status,
      },
    };
    let res = await authRequest(URL, "GET", {}, config);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const createVendorService = async (data) => {
  try {
    let URL = apiURL + "vendor/create";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const addVendorAddressService = async (data) => {
  try {
    let URL = apiURL + "vendor/addAddress";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateVendorService = async (data) => {
  try {
    let URL = apiURL + "vendor/updateData";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const addVendorAadharService = async (data) => {
  try {
    let URL = apiURL + "vendor/addAadhar";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const addVendorPanNumberService = async (data) => {
  try {
    let URL = apiURL + "vendor/addPanNumber";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const fetchVendorService = async (data) => {
  try {
    let URL = apiURL + "vendor/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const blockVendorService = async (data) => {
  try {
    let URL = apiURL + "vendor/block/" + data.value._id;
    let res = await authRequest(URL, "POST", {}, { reason: data.value.reason });
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
