import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const getAllZonesService = async (data) => {
  try {
    let URL = apiURL + "zone/getAll";
    let config = {
      params: {
        page: data.page,
        perPage: data.perPage,
        status: data.status,
      },
    };
    let res = await authRequest(URL, "GET", {}, config);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const createZoneService = async (data) => {
  try {
    let URL = apiURL + "zone/create";
    let res = await authRequest(URL, "POST", {}, data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateZoneService = async (data) => {
  try {
    let URL = apiURL + "zone/update/" + data.id;
    delete data.id;
    let res = await authRequest(URL, "PUT", {}, data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const fetchZoneService = async (data) => {
  try {
    let URL = apiURL + "zone/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const deleteZoneService = async (data) => {
  try {
    let URL = apiURL + "zone/delete/" + data.id;
    let res = await authRequest(URL, "DELETE", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
