import {
  Login,
  Agent,
  AgentForm,
  BusinessType,
  BusinessTypeForm,
  JobType,
  JobTypeForm,
  OperationalCity,
  OperationalCityForm,
  Task,
  TaskForm,
  Vendor,
  VendorForm,
  AccessControl,
  AccessControlForm,
  Zones,
  ZonesForm,
} from "../screens";

export const routes = [
  {
    path: "/",
    Component: Login,
    isPublic: true,
    exact: true,
  },
  {
    path: "/forgotPassword",
    Component: Login,
    isPublic: true,
    exact: true,
  },
  {
    path: "/task",
    Component: Task,
    isPublic: false,
    exact: true,
  },
  {
    path: "/task/:action/:id",
    Component: TaskForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/task/:action",
    Component: TaskForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/agent",
    Component: Agent,
    isPublic: false,
    exact: true,
  },
  {
    path: "/agent/:action/:id",
    Component: AgentForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/agent/:action",
    Component: AgentForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/vendor",
    Component: Vendor,
    isPublic: false,
    exact: true,
  },
  {
    path: "/vendor/:action/:id",
    Component: VendorForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/vendor/:action",
    Component: VendorForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/operationalCity",
    Component: OperationalCity,
    isPublic: false,
    exact: true,
  },
  {
    path: "/operationalCity/:action/:id",
    Component: OperationalCityForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/operationalCity/:action",
    Component: OperationalCityForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/jobType",
    Component: JobType,
    isPublic: false,
    exact: true,
  },
  {
    path: "/jobType/:action/:id",
    Component: JobTypeForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/jobType/:action",
    Component: JobTypeForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/businessType",
    Component: BusinessType,
    isPublic: false,
    exact: true,
  },
  {
    path: "/businessType/:action/:id",
    Component: BusinessTypeForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/businessType/:action",
    Component: BusinessType,
    isPublic: false,
    exact: true,
  },
  {
    path: "/zones",
    Component: Zones,
    isPublic: false,
    exact: true,
  },
  {
    path: "/zones/:action/:code",
    Component: ZonesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/zones/:action",
    Component: ZonesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins",
    Component: AccessControl,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins/:action/:id",
    Component: AccessControlForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins/:action",
    Component: AccessControlForm,
    isPublic: false,
    exact: true,
  },
];
