import { call, takeLatest, put, select } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createAgentService,
  getAllAgentService,
  fetchAgentService,
  updateAgentService,
  blockAgentService,
  updateAgentAddressService,
  updateAgentVehicleTypeService,
  updateAgentJobTypeService,
  updateAgentOperationalCityService,
  addAgentAadharService,
  addAgentPanNumberService,
  addAgentBankDetailsService,
  addAgentEmergencyContactService,
} from "../../services/agent";
import { toast } from "react-toastify";
import {
  CREATE_AGENT,
  FETCH_AGENT,
  GET_ALL_AGENTS,
  UPDATE_AGENT,
  BLOCK_AGENT,
  UPDATE_AGENT_ADDRESS,
  UPDATE_AGENT_VEHICLE_TYPE,
  UPDATE_AGENT_JOB_TYPE,
  UPDATE_AGENT_OPERATIONAL_CITY,
  ADD_AGENT_AADHAR,
  ADD_AGENT_PAN,
  ADD_AGENT_BANK_DETAILS,
  ADD_AGENT_EMERGENCY_CONTACT,
} from "../actions";

export function* getAllAgentSaga(action) {
  try {
    const { paginationData } = yield select((state) => state.agent);
    let res = yield call(
      getAllAgentService,
      action.payload ? action.payload : paginationData
    );
    if (res.success) {
      yield put(GET_ALL_AGENTS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_AGENTS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_AGENTS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* createAgentSaga(action) {
  try {
    let res = yield call(createAgentService, action.payload);
    if (res.success) {
      yield put(CREATE_AGENT.SUCCESS(res.data));
      action.payload.navigate("/agent");
      toast.success("Agent Created!");
    } else {
      yield put(CREATE_AGENT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_AGENT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateAgentSaga(action) {
  try {
    let res = yield call(updateAgentService, action.payload);
    if (res.success) {
      yield put(UPDATE_AGENT.SUCCESS(res.data));
      action.payload.navigate("/agent");
      toast.success("Agent Updated!");
    } else {
      yield put(UPDATE_AGENT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_AGENT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}
export function* addAgentAadharSaga(action) {
  try {
    let res = yield call(addAgentAadharService, action.payload);
    if (res.success) {
      yield put(ADD_AGENT_AADHAR.SUCCESS(res.data));
      // action.payload.navigate("/agent");
      toast.success("Aadhar Updated!");
    } else {
      yield put(ADD_AGENT_AADHAR.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      ADD_AGENT_AADHAR.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}
export function* addAgentPanNumberSaga(action) {
  try {
    let res = yield call(addAgentPanNumberService, action.payload);
    if (res.success) {
      yield put(ADD_AGENT_PAN.SUCCESS(res.data));
      // action.payload.navigate("/agent");
      toast.success("PAN Updated!");
    } else {
      yield put(ADD_AGENT_PAN.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      ADD_AGENT_PAN.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}
export function* addAgentBankDetailsSaga(action) {
  try {
    let res = yield call(addAgentBankDetailsService, action.payload);
    if (res.success) {
      yield put(ADD_AGENT_BANK_DETAILS.SUCCESS(res.data));
      action.payload.navigate("/agent");
      toast.success("Bank Details Updated!");
    } else {
      yield put(ADD_AGENT_BANK_DETAILS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      ADD_AGENT_BANK_DETAILS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}
export function* addAgentEmergencyContactSaga(action) {
  try {
    let res = yield call(addAgentEmergencyContactService, action.payload);
    if (res.success) {
      yield put(ADD_AGENT_EMERGENCY_CONTACT.SUCCESS(res.data));
      // action.payload.navigate("/agent");
      toast.success("Emergency Contact Updated!");
    } else {
      yield put(ADD_AGENT_EMERGENCY_CONTACT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      ADD_AGENT_EMERGENCY_CONTACT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateAgentAddressSaga(action) {
  try {
    let res = yield call(updateAgentAddressService, action.payload);
    if (res.success) {
      yield put(UPDATE_AGENT_ADDRESS.SUCCESS(res.data));
      toast.success("Address Updated!");
    } else {
      yield put(UPDATE_AGENT_ADDRESS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_AGENT_ADDRESS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateAgentVehicleTypeSaga(action) {
  try {
    let res = yield call(updateAgentVehicleTypeService, action.payload);
    if (res.success) {
      yield put(UPDATE_AGENT_VEHICLE_TYPE.SUCCESS(res.data));
      toast.success("Vehicle Type Updated!");
    } else {
      yield put(UPDATE_AGENT_VEHICLE_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_AGENT_VEHICLE_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateAgentJobTypeSaga(action) {
  try {
    let res = yield call(updateAgentJobTypeService, action.payload);
    if (res.success) {
      yield put(UPDATE_AGENT_JOB_TYPE.SUCCESS(res.data));
      toast.success("Job Type Updated!");
    } else {
      yield put(UPDATE_AGENT_JOB_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_AGENT_JOB_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateAgentOperationalCitySaga(action) {
  try {
    let res = yield call(updateAgentOperationalCityService, action.payload);
    if (res.success) {
      yield put(UPDATE_AGENT_OPERATIONAL_CITY.SUCCESS(res.data));
      toast.success("Operational City Updated!");
    } else {
      yield put(UPDATE_AGENT_OPERATIONAL_CITY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_AGENT_OPERATIONAL_CITY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* fetchAgentSaga(action) {
  try {
    let res = yield call(fetchAgentService, action.payload);
    if (res.success) {
      yield put(FETCH_AGENT.SUCCESS(res.data));
    } else {
      yield put(FETCH_AGENT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      FETCH_AGENT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* blockAgentSaga(action) {
  try {
    let res = yield call(blockAgentService, action.payload);
    if (res.success) {
      yield put(BLOCK_AGENT.SUCCESS(res.data));
      toast.success("Agent Blocked!");
    } else {
      yield put(BLOCK_AGENT.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      BLOCK_AGENT.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const agentSaga = () => [
  takeLatest(ActionTypes.Agents.CREATE_AGENT.REQUEST, createAgentSaga),
  takeLatest(ActionTypes.Agents.GET_ALL_AGENTS.REQUEST, getAllAgentSaga),
  takeLatest(ActionTypes.Agents.FETCH_AGENT.REQUEST, fetchAgentSaga),
  takeLatest(ActionTypes.Agents.UPDATE_AGENT.REQUEST, updateAgentSaga),
  takeLatest(ActionTypes.Agents.ADD_AGENT_AADHAR.REQUEST, addAgentAadharSaga),
  takeLatest(ActionTypes.Agents.ADD_AGENT_PAN.REQUEST, addAgentPanNumberSaga),
  takeLatest(
    ActionTypes.Agents.ADD_AGENT_BANK_DETAILS.REQUEST,
    addAgentBankDetailsSaga
  ),
  takeLatest(
    ActionTypes.Agents.ADD_AGENT_EMERGENCY_CONTACT.REQUEST,
    addAgentEmergencyContactSaga
  ),
  takeLatest(
    ActionTypes.Agents.UPDATE_AGENT_VEHICLE_TYPE.REQUEST,
    updateAgentVehicleTypeSaga
  ),
  takeLatest(
    ActionTypes.Agents.UPDATE_AGENT_JOB_TYPE.REQUEST,
    updateAgentJobTypeSaga
  ),
  takeLatest(
    ActionTypes.Agents.UPDATE_AGENT_OPERATIONAL_CITY.REQUEST,
    updateAgentOperationalCitySaga
  ),
  takeLatest(
    ActionTypes.Agents.UPDATE_AGENT_ADDRESS.REQUEST,
    updateAgentAddressSaga
  ),
  takeLatest(ActionTypes.Agents.BLOCK_AGENT.REQUEST, blockAgentSaga),
];
export default agentSaga();
