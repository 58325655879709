import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Table, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import { vendorColumns } from "../../constants/tableHeaders";
import { GET_ONE_BUSINESS_TYPE } from "../../store/actions";

export default function ProductsForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const { selectedBusinessTypes } = useSelector((state) => state.businessType);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  const [data, setData] = useState({});
  const [vendorPaginationData, setVendorPaginationData] = useState({
    page: 1,
    perPage: 5,
  });

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_BUSINESS_TYPE.REQUEST({ id }));
  }, [id]);

  useEffect(() => {
    if (selectedBusinessTypes && id) {
      setData(selectedBusinessTypes);
    }
  }, [selectedBusinessTypes]);

  const handleChange = (e) => {
    let updatedData = { ...data };
    updatedData[e.target.name] = e.target.value;
    setData(updatedData);
  };
  const onChangePageNumber = (val) => {
    dispatch(setVendorPaginationData({ ...vendorPaginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(
      setVendorPaginationData({ ...vendorPaginationData, perPage: val })
    );
  };
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  return (
    <div className="">
      <FormLayout title={"General"}>
        <div>
          <div
            className={`grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Title"}
              name={"title"}
              type={"text"}
              value={data?.title}
              onChange={handleChange}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter title"}
            />
          </div>
        </div>
      </FormLayout>
      {readOnly && (
        <FormLayout title={"Vendors"}>
          <Table
            darkTheme={darkTheme}
            columns={vendorColumns}
            data={data?.vendors || []}
            paginationData={vendorPaginationData}
            onChangePageNumber={onChangePageNumber}
            onChangePageSize={onChangePageSize}
            totalPages={
              Array.isArray(data?.vendors)
                ? data?.vendors?.length / vendorPaginationData.perPage
                : 1
            }
          />
        </FormLayout>
      )}
    </div>
  );
}
