const Button2 = ({ title, handleClick, disabled, isInline }) => {
  return (
    <div className={`${isInline ? "inline" : "flex justify-end "}`}>
      <button
        className={`btn btn-active btn-primary bg-blue py-2 px-4 `}
        onClick={handleClick}
        disabled={disabled}
      >
        {title}
      </button>
    </div>
  );
};

export default Button2;
