import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const GET_ALL_VENDORS = actionCreator(
  ActionTypes.Vendors.GET_ALL_VENDORS
);

export const CREATE_VENDOR = actionCreator(ActionTypes.Vendors.CREATE_VENDOR);

export const FETCH_VENDOR = actionCreator(ActionTypes.Vendors.FETCH_VENDOR);
export const ADD_VENDOR_AADHAR = actionCreator(
  ActionTypes.Vendors.ADD_VENDOR_AADHAR
);
export const ADD_VENDOR_PAN = actionCreator(ActionTypes.Vendors.ADD_VENDOR_PAN);

export const UPDATE_VENDOR = actionCreator(ActionTypes.Vendors.UPDATE_VENDOR);

export const BLOCK_VENDOR = actionCreator(ActionTypes.Vendors.BLOCK_VENDOR);

export const ADD_VENDOR_ADDRESS = actionCreator(
  ActionTypes.Vendors.ADD_VENDOR_ADDRESS
);

export const setVendorPagination = (payload) => ({
  type: ActionTypes.Vendors.SET_VENDOR_PAGINATION,
  payload,
});
