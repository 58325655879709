import { get } from "lodash";
import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const getAllAgentService = async (data) => {
  try {
    let URL = apiURL + "agent/getAll";
    let config = {
      params: {
        page: data.page,
        perPage: data.perPage,
        status: data.status,
      },
    };
    let res = await authRequest(URL, "GET", {}, config);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const createAgentService = async (data) => {
  try {
    let URL = apiURL + "agent/create";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateAgentService = async (data) => {
  try {
    let URL = apiURL + "agent/update/" + data.value.agentId;
    delete data.value.agentId;
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateAgentAddressService = async (data) => {
  try {
    let URL = apiURL + "agent/addAddress";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateAgentVehicleTypeService = async (data) => {
  try {
    let URL = apiURL + "agent/addVehicleType";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateAgentJobTypeService = async (data) => {
  try {
    let URL = apiURL + "agent/addJobType";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
export const addAgentAadharService = async (data) => {
  try {
    let URL = apiURL + "agent/addAadhar";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
export const addAgentPanNumberService = async (data) => {
  try {
    let URL = apiURL + "agent/addPanNumber";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
export const addAgentBankDetailsService = async (data) => {
  try {
    let URL = apiURL + "agent/addBankAccount";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
export const addAgentEmergencyContactService = async (data) => {
  try {
    let URL = apiURL + "agent/addEmergencyContact";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateAgentOperationalCityService = async (data) => {
  try {
    let URL = apiURL + "agent/addWorkLocation";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const fetchAgentService = async (data) => {
  try {
    let URL = apiURL + "agent/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const blockAgentService = async (data) => {
  console.log("Data : ", data);
  try {
    let URL = apiURL + "agent/block/" + data.value?._id;
    let res = await authRequest(
      URL,
      "POST",
      {},
      { reason: get(data, "value.reason", "") }
    );
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
