import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Sidebar, Navbar } from "../../components";
import sidebarContent from "../../constants/sidebar";

export default function Layout({ children }) {
  const location = useLocation();

  const [showSidebar, setShowSidebar] = useState(false);
  const { darkTheme, isLoggedIn } = useSelector((state) => state.auth);

  const toggleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return !isLoggedIn || location.pathname === "/404" ? (
    <>{children}</>
  ) : (
    // <div className={`flex`}>
    //   <Sidebar
    //     showSidebar={showSidebar}
    //     sidebarData={sidebarContent}
    //     toggleShowSidebar={toggleShowSidebar}
    //   />
    //   <div
    //     className={`transition duration-500 p-4 flex-1 h-screen	overflow-y-auto overflow-x-hidden	scroll-smooth pl-8 ${
    //       !darkTheme ? "bg-light" : "bg-dark text-white"
    //     }`}
    //   >
    //     <Navbar toggleShowSidebar={toggleShowSidebar} />
    //     {children}
    //   </div>
    // </div>
    <div
      className={`drawer md:drawer-mobile ${
        !darkTheme ? "bg-light" : "bg-dark text-white"
      }`}
    >
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content px-4">
        {/* <label htmlFor="my-drawer" className="btn btn-primary drawer-button">
          Open drawer
        </label> */}
        <Navbar toggleShowSidebar={toggleShowSidebar} />
        {children}
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer" className="drawer-overlay"></label>
        <Sidebar
          showSidebar={true}
          sidebarData={sidebarContent}
          toggleShowSidebar={toggleShowSidebar}
        />
      </div>
    </div>
  );
}
