import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function FormLayout({ children, title }) {
  const { darkTheme, isLoggedIn } = useSelector((state) => state.auth);

  return (
    <div className={`mt-8 mb-12 min-w-min mx-4`}>
      <span className={`text-2xl mx-6`}>{title}</span>
      <div
        className={`border-b border-r border-l p-6 mt-4 rounded-b-xl transition duration-500 shadow-md`}
      >
        {children}
      </div>
    </div>
  );
}
