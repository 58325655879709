import { all } from "redux-saga/effects";
import auth from "./auth";
import tasks from "./tasks";
import admin from "./admin";
import vendors from "./vendors";
import agents from "./agents";
import jobType from "./jobType";
import businessType from "./businessType";
import operationalCity from "./operationalCity";
import zones from "./zones";

const sagas = [
  ...auth,
  ...admin,
  ...tasks,
  ...vendors,
  ...agents,
  ...jobType,
  ...businessType,
  ...operationalCity,
  ...zones,
];

export function* rootSaga() {
  yield all(sagas);
}
