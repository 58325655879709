import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createZoneService,
  updateZoneService,
  getAllZonesService,
  fetchZoneService,
  deleteZoneService,
} from "../../services/zones";
import { toast } from "react-toastify";
import {
  CREATE_ZONE,
  GET_ONE_ZONE,
  GET_ALL_ZONES,
  UPDATE_ZONE,
  DELETE_ZONE,
} from "../actions";

export function* getAllZonesSaga(action) {
  try {
    let res = yield call(getAllZonesService, action.payload);
    if (res.success) {
      yield put(GET_ALL_ZONES.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_ZONES.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_ZONES.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* createZoneSaga(action) {
  try {
    let res = yield call(createZoneService, action.payload.data);
    if (res.success) {
      yield put(CREATE_ZONE.SUCCESS(res.data));
      action.payload.navigate("/zones");
      toast.success("Zone Created!");
    } else {
      yield put(CREATE_ZONE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_ZONE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateZoneSaga(action) {
  try {
    let res = yield call(updateZoneService, action.payload.data);
    if (res.success) {
      yield put(UPDATE_ZONE.SUCCESS(res.data));
      action.payload.navigate("/zones");
      toast.success("Zone Updated!");
    } else {
      yield put(UPDATE_ZONE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_ZONE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* fetchZoneSaga(action) {
  try {
    let res = yield call(fetchZoneService, action.payload);
    if (res.success) {
      yield put(GET_ONE_ZONE.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_ZONE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_ZONE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteZoneSaga(action) {
  try {
    let res = yield call(deleteZoneService, action.payload);
    if (res.success) {
      yield put(DELETE_ZONE.SUCCESS(action.payload));
      toast.success("Zone Deleted!");
    } else {
      yield put(DELETE_ZONE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_ZONE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const zoneSaga = () => [
  takeLatest(ActionTypes.Zones.CREATE_ZONE.REQUEST, createZoneSaga),
  takeLatest(ActionTypes.Zones.GET_ALL_ZONES.REQUEST, getAllZonesSaga),
  takeLatest(ActionTypes.Zones.GET_ONE_ZONE.REQUEST, fetchZoneSaga),
  takeLatest(ActionTypes.Zones.UPDATE_ZONE.REQUEST, updateZoneSaga),
  takeLatest(ActionTypes.Zones.DELETE_ZONE.REQUEST, deleteZoneSaga),
];
export default zoneSaga();
