import ActionTypes from "../actions/types";

const operationalCityInitialState = {
  allOperationalCities: [],
  selectedOperationalCity: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const operationalCityReducer = (
  state = operationalCityInitialState,
  action
) => {
  let tempAllOC = state.allOperationalCities;
  switch (action.type) {
    case ActionTypes.OperationalCity.SET_OPERATIONAL_CITY_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.OperationalCity.GET_ALL_OPERATIONAL_CITIES.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.OperationalCity.GET_ALL_OPERATIONAL_CITIES.SUCCESS:
      return {
        ...state,
        loading: false,
        allOperationalCities: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.OperationalCity.GET_ALL_OPERATIONAL_CITIES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        pages: 1,
        allOperationalCities: [],
      };

    case ActionTypes.OperationalCity.CREATE_OPERATIONAL_CITY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.OperationalCity.CREATE_OPERATIONAL_CITY.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.OperationalCity.CREATE_OPERATIONAL_CITY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.OperationalCity.FETCH_OPERATIONAL_CITY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.OperationalCity.FETCH_OPERATIONAL_CITY.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedOperationalCity: action.payload.operationalCity,
        error: null,
      };
    case ActionTypes.OperationalCity.FETCH_OPERATIONAL_CITY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.OperationalCity.UPDATE_OPERATIONAL_CITY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.OperationalCity.UPDATE_OPERATIONAL_CITY.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        // selectedOperationalCitys: action.payload,
        error: null,
      };
    case ActionTypes.OperationalCity.UPDATE_OPERATIONAL_CITY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.OperationalCity.DELETE_OPERATIONAL_CITY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.OperationalCity.DELETE_OPERATIONAL_CITY.SUCCESS:
      tempAllOC = tempAllOC.filter((oc) => oc._id !== action.payload.id);
      return {
        ...state,
        allOperationalCities: tempAllOC,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.OperationalCity.DELETE_OPERATIONAL_CITY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default operationalCityReducer;
