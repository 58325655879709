import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const GET_ALL_AGENTS = actionCreator(ActionTypes.Agents.GET_ALL_AGENTS);

export const CREATE_AGENT = actionCreator(ActionTypes.Agents.CREATE_AGENT);
export const ADD_AGENT_AADHAR = actionCreator(
  ActionTypes.Agents.ADD_AGENT_AADHAR
);
export const ADD_AGENT_PAN = actionCreator(ActionTypes.Agents.ADD_AGENT_PAN);
export const ADD_AGENT_BANK_DETAILS = actionCreator(
  ActionTypes.Agents.ADD_AGENT_BANK_DETAILS
);
export const ADD_AGENT_EMERGENCY_CONTACT = actionCreator(
  ActionTypes.Agents.ADD_AGENT_EMERGENCY_CONTACT
);

export const FETCH_AGENT = actionCreator(ActionTypes.Agents.FETCH_AGENT);

export const UPDATE_AGENT = actionCreator(ActionTypes.Agents.UPDATE_AGENT);

export const UPDATE_AGENT_VEHICLE_TYPE = actionCreator(
  ActionTypes.Agents.UPDATE_AGENT_VEHICLE_TYPE
);
export const UPDATE_AGENT_OPERATIONAL_CITY = actionCreator(
  ActionTypes.Agents.UPDATE_AGENT_OPERATIONAL_CITY
);
export const UPDATE_AGENT_JOB_TYPE = actionCreator(
  ActionTypes.Agents.UPDATE_AGENT_JOB_TYPE
);

export const UPDATE_AGENT_ADDRESS = actionCreator(
  ActionTypes.Agents.UPDATE_AGENT_ADDRESS
);

export const BLOCK_AGENT = actionCreator(ActionTypes.Agents.BLOCK_AGENT);

export const setAgentPagination = (payload) => ({
  type: ActionTypes.Agents.SET_AGENT_PAGINATION,
  payload,
});
