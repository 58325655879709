import ActionTypes from "../actions/types";

const authInitialState = {
  userData: {},
  authToken: "",
  loading: false,
  isLoggedIn: false,
  error: null,
  darkTheme: false,
  role: "",
  deviceToken: null,
  notificationRegistered: false,
};

const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case ActionTypes.Auth.SAVE_DEVICE_TOKEN:
      return {
        ...state,
        deviceToken: action.payload,
      };
    case ActionTypes.Auth.TOGGLE_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme,
      };
    case ActionTypes.Auth.LOGIN_WITH_EMAIL.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Auth.LOGIN_WITH_EMAIL.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        userData: action.payload.adminData,
        role: action.payload.role,
        authToken: action.payload.token,
        error: null,
      };
    case ActionTypes.Auth.LOGIN_WITH_EMAIL.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
        userData: {},
      };
    case ActionTypes.Auth.UPDATE_DEVICE_TOKEN.REQUEST:
      return { ...state, notificationRegistered: false };
    case ActionTypes.Auth.UPDATE_DEVICE_TOKEN.SUCCESS:
      return { ...state, notificationRegistered: true };
    case ActionTypes.Auth.UPDATE_DEVICE_TOKEN.FAILURE:
      return { ...state, notificationRegistered: false };
    case ActionTypes.Auth.LOGOUT.REQUEST:
      return {
        ...authInitialState,
        deviceToken: state.deviceToken,
        authToken: state.authToken,
        notificationRegistered: state.notificationRegistered,
      };
    case ActionTypes.Auth.LOGOUT.SUCCESS:
      return {
        ...authInitialState,
        deviceToken: state.deviceToken,
      };
    case ActionTypes.Auth.LOGOUT.FAILURE:
      return {
        ...authInitialState,
        deviceToken: state.deviceToken,
        notificationRegistered: state.notificationRegistered,
      };

    default:
      return state;
  }
};

export default authReducer;
