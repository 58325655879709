import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_TASK = actionCreator(ActionTypes.Tasks.CREATE_TASK);

export const UPDATE_TASK = actionCreator(ActionTypes.Tasks.UPDATE_TASK);

export const GET_ONE_TASK = actionCreator(ActionTypes.Tasks.GET_ONE_TASK);

export const GET_ALL_TASKS = actionCreator(ActionTypes.Tasks.GET_ALL_TASKS);

export const CANCEL_TASK = actionCreator(ActionTypes.Tasks.CANCEL_TASK);

export const setTaskPagination = (payload) => ({
  type: ActionTypes.Tasks.SET_TASKS_PAGINATION,
  payload,
});
