import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createBusinessTypeService,
  getAllBusinessTypeService,
  fetchBusinessTypeService,
  updateBusinessTypeService,
  deleteBusinessTypeService,
} from "../../services/businessType";

import { toast } from "react-toastify";
import {
  CREATE_BUSINESS_TYPE,
  GET_ONE_BUSINESS_TYPE,
  GET_ALL_BUSINESS_TYPES,
  UPDATE_BUSINESS_TYPE,
  DELETE_BUSINESS_TYPE,
} from "../actions";

export function* getAllBusinessTypeSaga(action) {
  try {
    let res = yield call(getAllBusinessTypeService, action.payload);
    if (res.success) {
      yield put(GET_ALL_BUSINESS_TYPES.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_BUSINESS_TYPES.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_BUSINESS_TYPES.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* createBusinessTypeSaga(action) {
  try {
    let res = yield call(createBusinessTypeService, action.payload);
    if (res.success) {
      yield put(CREATE_BUSINESS_TYPE.SUCCESS(res.data));
      toast.success("Business Type Created!");
    } else {
      yield put(CREATE_BUSINESS_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_BUSINESS_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateBusinessTypeSaga(action) {
  try {
    let res = yield call(updateBusinessTypeService, action.payload);
    if (res.success) {
      yield put(UPDATE_BUSINESS_TYPE.SUCCESS(action));
      toast.success("Business Type Updated!");
    } else {
      yield put(UPDATE_BUSINESS_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_BUSINESS_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* fetchBusinessTypeSaga(action) {
  try {
    let res = yield call(fetchBusinessTypeService, action.payload);
    if (res.success) {
      yield put(GET_ONE_BUSINESS_TYPE.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_BUSINESS_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_BUSINESS_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteBusinessTypeSaga(action) {
  try {
    let res = yield call(deleteBusinessTypeService, action.payload);
    if (res.success) {
      yield put(DELETE_BUSINESS_TYPE.SUCCESS(action));
      toast.success("Business Type Deleted!");
    } else {
      yield put(DELETE_BUSINESS_TYPE.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_BUSINESS_TYPE.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const businessTypeSaga = () => [
  takeLatest(
    ActionTypes.BusinessTypes.CREATE_BUSINESS_TYPE.REQUEST,
    createBusinessTypeSaga
  ),
  takeLatest(
    ActionTypes.BusinessTypes.GET_ALL_BUSINESS_TYPES.REQUEST,
    getAllBusinessTypeSaga
  ),
  takeLatest(
    ActionTypes.BusinessTypes.GET_ONE_BUSINESS_TYPE.REQUEST,
    fetchBusinessTypeSaga
  ),
  takeLatest(
    ActionTypes.BusinessTypes.UPDATE_BUSINESS_TYPE.REQUEST,
    updateBusinessTypeSaga
  ),
  takeLatest(
    ActionTypes.BusinessTypes.DELETE_BUSINESS_TYPE.REQUEST,
    deleteBusinessTypeSaga
  ),
];
export default businessTypeSaga();
