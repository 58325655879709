import React, { useEffect, useState } from "react";
import { EditFieldModal, SecondaryNavbar, Table } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_OPERATIONAL_CITY,
  GET_ALL_OPERATIONAL_CITIES,
  GET_ALL_ZONES,
  setOperationalCityPagination,
  UPDATE_OPERATIONAL_CITY,
} from "../../store/actions";
import { isArray } from "lodash";
import { getOperationalCityColumns } from "../../constants/tableHeaders";
import { useNavigate } from "react-router-dom";

const initOperationalCity = {
  title: "",
  vendors: 0,
  _id: "",
};

const OperationalCities = () => {
  const {
    paginationData,
    allOperationalCities: data,
    pages,
    selectedOperationalCity,
  } = useSelector((state) => state.operationalCity);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    dispatch(GET_ALL_OPERATIONAL_CITIES.REQUEST(paginationData));
    dispatch(GET_ALL_ZONES.REQUEST(paginationData));

    return () => {
      console.log("Component Unmounted from OperationalCities");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({
    ...initOperationalCity,
  });

  const onChangePageNumber = (val) => {
    dispatch(setOperationalCityPagination({ ...paginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(setOperationalCityPagination({ ...paginationData, perPage: val }));
  };

  const onToggleEditModal = () => {
    if (window.location.pathname?.includes("create")) {
      navigate("/operationalCity");
    }
    if (showEditModal) {
      setEditModalData({ ...initOperationalCity });
    }
    setShowEditModal(!showEditModal);
  };

  const handleSubmit = () => {
    // console.log(tempSelectedOperationalCity);
    if (window.location.pathname?.includes("create")) {
      let tempSelectedOperationalCity = { ...editModalData };
      dispatch(CREATE_OPERATIONAL_CITY.REQUEST(tempSelectedOperationalCity));
    } else {
      let tempSelectedOperationalCity = { ...editModalData };
      dispatch(UPDATE_OPERATIONAL_CITY.REQUEST(tempSelectedOperationalCity));
    }
    onToggleEditModal();
  };

  return (
    <>
      <SecondaryNavbar
        title="Operational City"
        btnTitle="Add"
        onClick={() => {
          onToggleEditModal();
          navigate("/operationalCity/create");
        }}
      />
      {/* <div className={`my-8 mb-10`}>
        {[].map((item, ind) => {
          return (
            <span
              key={`span ${ind}`}
              className={`mx-2 transition duration-500 text-xl py-2 ${
                ind === selectedIndex
                  ? `${
                      !darkTheme
                        ? "text-dark_grey border-dark_grey"
                        : "text-white border-white"
                    } border-b-2 `
                  : "text-grey_text"
              } cursor-pointer`}
              onClick={() => setSelectedIndex(ind)}
            >
              {item}
            </span>
          );
        })}
      </div> */}
      {isArray(data) && (
        <Table
          darkTheme={darkTheme}
          columns={getOperationalCityColumns(
            onToggleEditModal,
            setEditModalData
          )}
          data={data}
          paginationData={paginationData}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
          totalPages={pages}
        />
      )}
      <EditFieldModal
        title={"Operational City"}
        name={"city"}
        type={"text"}
        value={editModalData.city}
        onChange={(e) =>
          setEditModalData({ ...editModalData, city: e.target.value })
        }
        onSubmit={handleSubmit}
        isOpen={showEditModal}
        closeModal={onToggleEditModal}
      />
    </>
  );
};

export default OperationalCities;
