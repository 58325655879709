import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const CREATE_ZONE = actionCreator(ActionTypes.Zones.CREATE_ZONE);

export const UPDATE_ZONE = actionCreator(ActionTypes.Zones.UPDATE_ZONE);

export const GET_ALL_ZONES = actionCreator(ActionTypes.Zones.GET_ALL_ZONES);

export const GET_ONE_ZONE = actionCreator(ActionTypes.Zones.GET_ONE_ZONE);

export const DELETE_ZONE = actionCreator(ActionTypes.Zones.DELETE_ZONE);

export const setZonePagination = (payload) => ({
  type: ActionTypes.Zones.SET_ZONES_PAGINATION,
  payload,
});
