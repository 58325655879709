import React, { useState, useEffect } from "react";
import PeopleImg from "../../assets/PeopleImg.jpeg";
import LoginImg from "../../assets/LoginImg.jpeg";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { FORGOT_PASSWORD, LOGIN_WITH_EMAIL } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "kartik@gmail.com",
    password: "Test@123#",
  });

  const { isLoggedIn } = useSelector((state) => state?.auth);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/task");
    }
  }, [isLoggedIn, navigate]);

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  const onChangeHandler = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    if (get(credentials, "email", "")) {
      isForgotPass
        ? dispatch(
            FORGOT_PASSWORD.REQUEST({
              value: { email: get(credentials.email) },
              navigate: navigate,
            })
          )
        : dispatch(LOGIN_WITH_EMAIL.REQUEST(credentials, navigate));
    } else {
      toast.error("Please enter email!");
    }
  };

  const onForgotPassword = () => {
    navigate("/forgotPassword");
  };

  const isForgotPass = window.location.pathname?.includes("forgotPassword");

  return (
    <div className={`flex`}>
      <div
        className={`transition duration-500 flex flex-col h-screen w-full	md:w-2/5 items-center px-16 min-w-max	${
          !darkTheme ? "bg-white" : "bg-dark text-white"
        }`}
      >
        <img src={LoginImg} className={`w-28 my-20`} alt="" />
        <h1 className={isForgotPass ? `text-4xl mb-4` : `text-4xl mb-16`}>
          {isForgotPass ? "Forgot Password" : "Log In"}
        </h1>
        {isForgotPass && (
          <p className="mb-16">
            Enter your registered email address and we will send you an email to
            resre your password.
          </p>
        )}
        <Input
          type={"email"}
          header={"Email"}
          value={get(credentials, "email", "")}
          onChange={onChangeHandler}
          darkTheme={darkTheme}
          name="email"
        />
        {!isForgotPass && (
          <Input
            type={"password"}
            header={"Password"}
            value={get(credentials, "password", "")}
            onChange={onChangeHandler}
            darkTheme={darkTheme}
            name="password"
          />
        )}
        {!isForgotPass && (
          <span
            className={`w-11/12 text-end mb-4 cursor-pointer`}
            onClick={onForgotPassword}
          >
            Forgot Password?
          </span>
        )}
        <Button text={isForgotPass ? "Submit" : "Login"} onClick={onSubmit} />
      </div>
      <img className={`h-screen w-0 md:w-3/5`} src={PeopleImg} alt="" />
    </div>
  );
};

export default Login;
