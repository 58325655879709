import ActionTypes from "../actions/types";

const tasksInitialState = {
  allTasks: [],
  selectedTask: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const taskReducer = (state = tasksInitialState, action) => {
  console.log(action);
  let tempAllTasks = [...state.allTasks];
  switch (action.type) {
    case ActionTypes.Tasks.SET_TASKS_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.Tasks.CREATE_TASK.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Tasks.CREATE_TASK.SUCCESS:
      tempAllTasks = [...state.allTasks];
      tempAllTasks.push(action?.payload);
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allTasks: tempAllTasks,
        error: null,
      };
    case ActionTypes.Tasks.CREATE_TASK.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Tasks.UPDATE_TASK.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Tasks.UPDATE_TASK.SUCCESS:
      tempAllTasks = [...state.allTasks];
      let index = tempAllTasks.findIndex(
        (task) => task._id === action.payload._id
      );
      tempAllTasks[index] = action.payload;
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allTasks: tempAllTasks,
        error: null,
      };
    case ActionTypes.Tasks.UPDATE_TASK.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Tasks.GET_ONE_TASK.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Tasks.GET_ONE_TASK.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedTask: action.payload,
        error: null,
      };
    case ActionTypes.Tasks.GET_ONE_TASK.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Tasks.GET_ALL_TASKS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Tasks.GET_ALL_TASKS.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allTasks: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Tasks.GET_ALL_TASKS.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Tasks.CANCEL_TASK.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Tasks.CANCEL_TASK.SUCCESS:
      tempAllTasks = [...state.allTasks].filter(
        (task) => task._id !== action?.payload?.id
      );
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allTasks: tempAllTasks,
        error: null,
      };
    case ActionTypes.Tasks.CANCEL_TASK.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default taskReducer;
