import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormLayout from "../../components/FormLayout";
import {
  Dropdown,
  TextInput,
  Checkbox,
  TextArea,
  Button2,
  Button,
} from "../../components";
import {
  CREATE_TASK,
  GET_ALL_BUSINESS_TYPES,
  GET_ALL_VENDORS,
  GET_ONE_TASK,
} from "../../store/actions";
import { get } from "lodash";
import Address from "../../components/AddressAutoComplete";
import Avatar from "../../components/Avatar";
import ImageInput from "../../components/ImageInput";
import ArrayText from "../../components/ArrayText";

const style = {
  width: "fit-content",
  minHeight: "40vh",
  marginTop: "20px",
  backgroundColor: "white",
  borderRadius: "10px",
};

const paymentMethods = [
  {
    value: "prePaid",
    label: "Pre-Paid",
  },
  {
    value: "postPaid",
    label: "Post-Paid",
  },
];

const initCreateData = {
  origins: [{}],
  task: {
    paymentMode: "postPaid",
  },
};

export default function TaskForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const { selectedTask } = useSelector((state) => state.task);
  const { allBusinessTypes } = useSelector((state) => state.businessType);
  const { allVendors } = useSelector((state) => state.vendor);
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  const [generalData, setGeneralData] = useState({});
  const [createData, setCreateData] = useState({ ...initCreateData });
  const [newContent, setNewContent] = useState({ title: "", quantity: 1 });
  const [allBT, setAllBT] = useState([]);
  const [allOrigins, setAllOrigins] = useState([]);
  const [allVendorsOptions, setAllVendorsOptions] = useState([]);
  const [selectedOrigin, setSelectedOrigin] = useState("");

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_TASK.REQUEST({ id }));
    if (allBusinessTypes && allBusinessTypes.length < 1) {
      dispatch(GET_ALL_BUSINESS_TYPES.REQUEST({}));
    }
    if (allVendors && allVendors.length < 1) {
      dispatch(GET_ALL_VENDORS.REQUEST({}));
    }
  }, [id]);

  useEffect(() => {
    if (selectedTask && id) {
      let tempGeneralData = selectedTask;
      setGeneralData(tempGeneralData);
      let tempAllOrigins = [];
      get(selectedTask, "origin", []).map((or, index) =>
        tempAllOrigins.push({ label: or.storeName, value: or._id })
      );
      setAllOrigins(tempAllOrigins);
    }
  }, [selectedTask]);

  useEffect(() => {
    if (allBusinessTypes && Array.isArray(allBusinessTypes)) {
      let tempBT = [];
      allBusinessTypes.map((bt, index) =>
        tempBT.push({ label: bt.title, value: bt._id })
      );
      setAllBT(tempBT);
    } else {
      dispatch(GET_ALL_BUSINESS_TYPES.REQUEST({}));
    }
  }, [allBusinessTypes, allVendors]);

  useEffect(() => {
    if (allVendors && Array.isArray(allVendors)) {
      let tempAV = [];
      allVendors.map((v, index) =>
        tempAV.push({ label: v.vendorId?.name, value: v.vendorId?._id })
      );
      setAllVendorsOptions(tempAV);
    } else {
      dispatch(GET_ALL_BUSINESS_TYPES.REQUEST({}));
    }
  }, [allVendors]);

  const getOrigin = (id) => {
    if (id && generalData.origin && Array.isArray(generalData.origin)) {
      for (let i in generalData.origin) {
        if (generalData.origin[i]._id === id) {
          return generalData.origin[i].storeName;
        }
      }
    }
    return "";
  };

  const getIndex = (id) => {
    if (id && generalData.origin && Array.isArray(generalData.origin)) {
      for (let i in generalData.origin) {
        if (generalData.origin[i]._id === id) {
          return i;
        }
      }
    }
    return 0;
  };

  const addContent = () => {
    if (newContent?.title && newContent.quantity) {
      let updatedTask = { ...createData.task };
      if (updatedTask["contents"] && Array.isArray(updatedTask["contents"])) {
        updatedTask["contents"].push({ ...newContent });
      } else {
        updatedTask["contents"] = [newContent];
      }
      setCreateData({ ...createData, task: updatedTask });
      setNewContent({ title: "", quantity: 1 });
    }
  };

  const handleCreate = () => {
    console.log("Creating task : ", createData);
    if (createData.task) {
      // validations to be added
      dispatch(CREATE_TASK.REQUEST({ value: createData, navigate: navigate }));
    }
  };

  const getSelectedVendor = (vendorId) => {
    if (allVendors && Array.isArray(allVendors)) {
      for (let index in allVendors) {
        if (allVendors[index]?.vendorId?._id === vendorId) {
          return allVendors[index];
        }
      }
    }
    return null;
  };

  return (
    <div className="">
      {action === "create" ? (
        <>
          <FormLayout title={"Create Task"}>
            <div
              className={`mx-2 grid grid-rows-2 sm:grid-rows-2 grid-flow-col gap-4 mb-8`}
            >
              <div
                className={`flex w-full mb-8 justify-between flex-col sm:flex-row`}
              >
                <Avatar
                  src={get(createData, "vendorLogo", " ")}
                  name={"V"}
                  bgColor={"#8F00FF"}
                  textColor={"white"}
                  onChange={(val) => {}}
                  readOnly={true}
                />
                <span className="w-full sm:ml-8">
                  <Dropdown
                    title={"Select Vendor"}
                    name={"vendorId"}
                    value={{
                      value: get(createData, "vendorId", ""),
                      label: "",
                    }}
                    onChange={(e) => {
                      let tempData = { ...createData };
                      tempData["vendorId"] = e.value;
                      let selectedVendor = getSelectedVendor(e.value);
                      if (selectedVendor) {
                        tempData["task"] = { ...tempData.task };
                        tempData["vendorLogo"] = get(
                          selectedVendor,
                          "logo",
                          ""
                        );
                        tempData["task"]["orderCategory"] = get(
                          selectedVendor,
                          "businessCategory._id",
                          ""
                        );
                      }
                      console.log(tempData);
                      console.log(allBT);
                      setCreateData(tempData);
                    }}
                    placeholder={""}
                    showError={false}
                    isReadOnly={readOnly}
                    errorMessage={""}
                    options={allVendorsOptions}
                  />
                </span>
              </div>

              <Dropdown
                title={"Order Category"}
                name={"orderCategory"}
                value={{
                  value: get(createData, "task.orderCategory", ""),
                  label: "",
                }}
                onChange={(e) => {
                  let tempTask = { ...createData?.task };
                  tempTask["orderCategory"] = e.value;
                  setCreateData({ ...createData, task: tempTask });
                }}
                placeholder={""}
                showError={false}
                isReadOnly={true}
                errorMessage={""}
                options={allBT}
              />
            </div>

            <FormLayout title={"Origins"}>
              {createData.origins &&
                Array.isArray(createData.origins) &&
                createData.origins.map((origin, index) => {
                  return (
                    <div key={index} className="border-b-4 mb-10 mt-4 relative">
                      {get(createData, "origins", []).length > 1 && (
                        <button
                          className="btn btn-circle btn-outline absolute"
                          style={{ top: "-35px", right: "0px" }}
                          onClick={() => {
                            let origins = [...createData.origins];
                            origins.splice(index, 1);
                            setCreateData({ ...createData, origins: origins });
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      )}
                      <div
                        className={`mx-2 grid grid-rows-1 sm:grid-rows-1 grid-flow-col gap-4 mt-8`}
                      >
                        <TextInput
                          title={"Store Name"}
                          name={"storeName"}
                          type={"text"}
                          value={origin.storeName}
                          onChange={(e) => {
                            let updatedOrigins = [...createData.origins];
                            updatedOrigins[index][e.target.name] =
                              e.target.value;
                            setCreateData({
                              ...createData,
                              origins: updatedOrigins,
                            });
                          }}
                          placeholder={""}
                          showError={false}
                          isReadOnly={false}
                          errorMessage={"Please enter storeName"}
                        />
                      </div>
                      <Address
                        address={origin || {}}
                        readOnly={readOnly}
                        handleChange={(data) => {
                          let tempCreateData = { ...createData };
                          tempCreateData["origins"][index] = { ...data };
                          setCreateData(tempCreateData);
                        }}
                        updateAddress={() => {
                          // updateAddress();
                          console.log("Dest : ", createData);
                        }}
                        hideTitle={true}
                        title={"Origin Address"}
                        hideBtn={true}
                        getPlaceId={true}
                      />
                    </div>
                  );
                })}
              <Button2
                title={"Add Origin"}
                handleClick={() => {
                  let origins = createData.origins || [];
                  setCreateData({ ...createData, origins: [...origins, {}] });
                }}
              />
            </FormLayout>

            <FormLayout title={"Destination"}>
              <div
                className={`mx-2 grid grid-rows-4 sm:grid-rows-2 grid-flow-col gap-4 mt-8`}
              >
                <TextInput
                  title={"Receiver Name"}
                  name={"receiverName"}
                  type={"text"}
                  value={createData?.destination?.receiverName}
                  onChange={(e) => {
                    let updatedDest = { ...createData.destination };
                    updatedDest[e.target.name] = e.target.value;
                    let tempCustomer = { ...createData.customer };
                    tempCustomer["customerName"] = e.target.value;
                    setCreateData({
                      ...createData,
                      destination: updatedDest,
                      customer: tempCustomer,
                    });
                  }}
                  placeholder={""}
                  showError={false}
                  isReadOnly={false}
                  errorMessage={"Please enter receiverName"}
                />
                <TextInput
                  title={"Receiver Contact Number"}
                  name={"receiverContactNumber"}
                  type={"text"}
                  value={createData?.destination?.receiverContactNumber}
                  onChange={(e) => {
                    let updatedDest = { ...createData.destination };
                    updatedDest[e.target.name] = e.target.value;
                    let tempCustomer = { ...createData.customer };
                    tempCustomer["contactNo"] = e.target.value;
                    setCreateData({
                      ...createData,
                      destination: updatedDest,
                      customer: tempCustomer,
                    });
                  }}
                  placeholder={""}
                  showError={false}
                  isReadOnly={false}
                  errorMessage={"Please enter receiverContactNumber"}
                />
                <TextInput
                  title={"House Flat Or Block"}
                  name={"houseFlatOrBlock"}
                  type={"text"}
                  value={createData?.destination?.houseFlatOrBlock}
                  onChange={(e) => {
                    let updatedDest = { ...createData.destination };
                    updatedDest[e.target.name] = e.target.value;
                    setCreateData({ ...createData, destination: updatedDest });
                  }}
                  placeholder={""}
                  showError={false}
                  isReadOnly={false}
                  errorMessage={"Please enter houseFlatOrBlock"}
                />
                <TextInput
                  title={"Apartment Road or Area"}
                  name={"apartmentRoadOrArea"}
                  type={"text"}
                  value={createData?.destination?.apartmentRoadOrArea}
                  onChange={(e) => {
                    let updatedDest = { ...createData.destination };
                    updatedDest[e.target.name] = e.target.value;
                    setCreateData({ ...createData, destination: updatedDest });
                  }}
                  placeholder={""}
                  showError={false}
                  isReadOnly={false}
                  errorMessage={"Please enter apartmentRoadOrArea"}
                />
              </div>
              <Address
                address={createData?.destination || {}}
                readOnly={readOnly}
                handleChange={(data) => {
                  let tempCreateData = { ...createData };
                  tempCreateData["destination"] = { ...data };
                  setCreateData(tempCreateData);
                }}
                updateAddress={() => {
                  // updateAddress();
                  console.log("Dest : ", createData);
                }}
                hideTitle={true}
                title={"Destination Address"}
                hideBtn={true}
                getPlaceId={true}
              />
            </FormLayout>

            <div className="flex items-center flex-col my-8">
              <div
                className={`w-full mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4`}
              >
                <Dropdown
                  title={"Payment Mode"}
                  name={"paymentMode"}
                  value={{
                    value: createData?.task?.paymentMode,
                    label: "",
                  }}
                  onChange={(e) => {
                    let tempTask = { ...createData?.task };
                    tempTask["paymentMode"] = e.value;
                    setCreateData({ ...createData, task: tempTask });
                  }}
                  placeholder={""}
                  showError={false}
                  isReadOnly={true}
                  errorMessage={""}
                  options={paymentMethods}
                />
                <TextInput
                  title={"Amount"}
                  name={"amount"}
                  type={"number"}
                  value={createData?.task?.amount}
                  onChange={(e) => {
                    let tempTask = { ...createData?.task };
                    tempTask[e.target.name] = e.target.value;
                    setCreateData({ ...createData, task: tempTask });
                  }}
                  placeholder={""}
                  showError={false}
                  isReadOnly={readOnly}
                  errorMessage={"Please enter amount"}
                  initial={"$"}
                />
              </div>
              <Button
                text={"Calculate shipping charges"}
                onClick={() => {}}
                bgColor={"#2F7C31"}
              />
            </div>
            <div
              className={`${
                darkTheme ? "border" : "bg-gray-100"
              } p-4 rounded-xl`}
            >
              <ul className="my-4 mb-8">
                {createData?.task?.contents?.map((content, index) => {
                  return (
                    <li key={index} className="flex justify-between mb-4">
                      <>
                        <span>{`Title : ${content.title}`}</span>
                        <span>{`Quantity : ${content.quantity}`}</span>
                      </>
                    </li>
                  );
                })}
              </ul>
              <div
                className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 `}
              >
                <TextInput
                  title={"Content"}
                  name={"title"}
                  type={"text"}
                  value={newContent.title}
                  onChange={(e) => {
                    setNewContent({ ...newContent, title: e.target.value });
                  }}
                  placeholder={""}
                  showError={false}
                  isReadOnly={false}
                  errorMessage={"Please enter title"}
                />
                <TextInput
                  title={"Content Amount"}
                  name={"quantity"}
                  type={"number"}
                  value={newContent.quantity}
                  onChange={(e) => {
                    setNewContent({ ...newContent, quantity: e.target.value });
                  }}
                  placeholder={""}
                  showError={false}
                  isReadOnly={false}
                  errorMessage={"Please enter quantity"}
                />
              </div>
              <Button2 title={"Add content"} handleClick={addContent} />
            </div>

            <section className="flex flex-col items-center my-8">
              {/* <div className="flex w-full flex-col sm:flex-row overflow-auto">
                {createData.documents &&
                  createData.documents.map((doc, index) => {
                    return (
                      <section className="mx-2 my-2 border-2 border-black rounded">
                        <ImageInput
                          id={"doc_" + index}
                          title={"Select document"}
                          src={doc ? doc : ""}
                          onChange={(val) => {
                            let tempCreateData = { ...createData };
                            tempCreateData["documents"] = [
                              ...createData.documents,
                            ];
                            tempCreateData.documents[index] = val;
                            setCreateData(tempCreateData);
                          }}
                          readOnly={readOnly}
                        />
                      </section>
                    );
                  })}
              </div> */}
              <section className="w-full flex justify-between items-center my-8">
                <Checkbox
                  title={"Documents"}
                  name={"documents"}
                  value={createData.documents}
                  onChange={() => {
                    if (!createData.documents) {
                      setCreateData({ ...createData, documents: [] });
                    } else {
                      let tempCreateData = { ...createData };
                      delete tempCreateData.documents;
                      setCreateData(tempCreateData);
                    }
                  }}
                  placeholder={""}
                  reverse={true}
                  showError={false}
                  isReadOnly={readOnly}
                  errorMessage={""}
                />
                <section className="w-3/5">
                  <ArrayText
                    title={"Documents"}
                    name={"socuments"}
                    type={"text"}
                    value={get(createData, "documents", [])}
                    onChange={(e) => {
                      setCreateData({ ...createData, documents: e });
                    }}
                    placeholder={"Documents"}
                    showError={false}
                    isReadOnly={!createData.documents}
                    errorMessage={"Please enter documents"}
                  />
                </section>
                {/* <Button2
                  title={"Add Documents"}
                  handleClick={() => {
                    let tempCreateData = { ...createData };
                    tempCreateData["documents"] = [...createData.documents];
                    tempCreateData.documents.push("");
                    setCreateData(tempCreateData);
                  }}
                  disabled={!createData.documents}
                /> */}
              </section>
            </section>
            <Button2 title={"Submit"} handleClick={handleCreate} />
          </FormLayout>
        </>
      ) : (
        <>
          <section className="flex justify-start items-center">
            <span className="text-2xl">Task</span>
            <span
              className="mx-2 px-4 py-1 rounded-xl text-white text-xs"
              style={{ backgroundColor: "#106914" }}
            >
              {generalData.status}
            </span>
          </section>
          {readOnly && (
            <span className="text-zinc-500 text-sm ml-1">Last active : </span>
          )}
          <FormLayout title={"Assigned Agent"}>
            {generalData.assignedAgent?.agentId ? (
              <div className="mt-8 mx-4">
                <div className="mb-8 mx-2 flex items-center">
                  <Avatar
                    src={get(generalData, "assignedAgent.agentId.profile", "")}
                    name={get(generalData, "assignedAgent.agentId.name", "")[0]}
                    bgColor={"#8F00FF"}
                    textColor={"white"}
                  />

                  <section className="flex flex-col text-sm ml-8">
                    <span className="mb-4">{`Name : ${get(
                      generalData,
                      "assignedAgent.agentId.name",
                      ""
                    )}`}</span>
                    <span>{`Contact No : ${get(
                      generalData,
                      "assignedAgent.agentId.contactNo",
                      ""
                    )}`}</span>
                  </section>
                </div>
              </div>
            ) : (
              <div className="mt-8 mx-4">
                <Button2
                  title={"Assign Agent"}
                  handleClick={() => {
                    alert("Assign Agent Clicked!");
                  }}
                />
              </div>
            )}
          </FormLayout>
          <FormLayout title={"General Info"}>
            <div
              className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 mb-8`}
            >
              <TextInput
                title={"Pickup Time"}
                name={"pickupTime"}
                type={"text"}
                value={get(generalData, "pickupTime", "")}
                onChange={(e) => {}}
                placeholder={""}
                showError={false}
                isReadOnly={true}
                errorMessage={"Please enter pickupTime"}
              />
              <TextInput
                title={"Delivery Time"}
                name={"deliveryTime"}
                type={"text"}
                value={get(generalData, "deliveryTime", "")}
                onChange={(e) => {}}
                placeholder={""}
                showError={false}
                isReadOnly={true}
                errorMessage={"Please enter deliveryTime"}
              />
            </div>
            <div className="mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 mb-8">
              <section className="flex flex-col mb-4 pr-6">
                <span className="text-lg">Contents</span>
                <ul className="p-2">
                  {generalData.contents?.map((content, ind) => {
                    return (
                      <li key={ind} className="ml-2">
                        <span className="text-sm text-gray-500">{`${get(
                          content,
                          "title",
                          ""
                        )} x ${content.quantity}`}</span>
                      </li>
                    );
                  })}
                </ul>
              </section>
              <TextInput
                title={"Order Category"}
                name={"orderCategory"}
                type={"text"}
                value={get(generalData, "orderCategory.title", "")}
                onChange={(e) => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter orderCategory"}
              />
            </div>
          </FormLayout>
          <FormLayout title={"Payment"}>
            <div className="mx-2 grid grid-rows-3 sm:grid-rows-2 grid-flow-col gap-4 mb-8">
              <TextInput
                title={"Delivery Charge"}
                name={"deliveryCharge"}
                type={"text"}
                value={generalData?.deliveryCharge}
                onChange={(e) => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter deliveryCharge"}
              />
              <TextInput
                title={"Amount"}
                name={"amount"}
                type={"text"}
                value={generalData?.amount}
                onChange={(e) => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter amount"}
              />
              {/* <TextInput
                title={"Payment Method"}
                name={"paymentMode"}
                type={"text"}
                value={generalData?.paymentMode}
                onChange={(e) => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter paymentMode"}
              /> */}
              <Dropdown
                title={"Payment Mode"}
                name={"paymentMode"}
                value={{
                  value: get(generalData, "paymentMode", ""),
                  label: "",
                }}
                onChange={(e) => {}}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={""}
                options={paymentMethods}
              />
            </div>
          </FormLayout>
          <FormLayout title={""}>
            <div className="mt-8 mx-4 flex justify-between flex-col sm:flex-row">
              <div className="mb-8 mx-2 flex items-center justify-center flex-col sm:flex-row">
                <Avatar
                  src={get(generalData, "vendor.logo", "")}
                  name={get(generalData, "assignedAgent.agentId.name", "")[0]}
                  bgColor={"#8F00FF"}
                  textColor={"white"}
                />
                <section className="flex flex-col text-sm mt-2 justify-center items-center">
                  <span className="text-lg">Vendor</span>
                  <span className="my-2">{`Store Name : ${get(
                    generalData,
                    "vendor.storeName",
                    ""
                  )}`}</span>
                  <span>{`Contact No : ${get(
                    generalData,
                    "vendor.contactNo",
                    ""
                  )}`}</span>
                </section>
              </div>
              <div className="mb-8 mx-2 flex items-center justify-center flex-col sm:flex-row">
                <Avatar
                  src={get(generalData, "customer.logo", "")}
                  name={get(generalData, "customer.name", "")[0]}
                  bgColor={"#8F00FF"}
                  textColor={"white"}
                />
                <section className="flex flex-col text-sm mt-2 justify-center items-center">
                  <span className="text-lg">Customer</span>
                  <span className="my-2">{`Name : ${get(
                    generalData,
                    "customer.name",
                    ""
                  )}`}</span>
                  <span>{`Contact No : ${get(
                    generalData,
                    "customer.contactNo",
                    ""
                  )}`}</span>
                </section>
              </div>
            </div>
            <div className="mt-2 mx-8 flex justify-between flex-col sm:flex-row">
              <section className="w-1/3">
                <Dropdown
                  title={"Select Origin"}
                  name={"origin"}
                  value={{
                    value: selectedOrigin,
                    label: getOrigin(selectedOrigin),
                  }}
                  onChange={(e) => {
                    setSelectedOrigin(e.value);
                  }}
                  placeholder={""}
                  showError={false}
                  isReadOnly={false}
                  errorMessage={""}
                  options={allOrigins}
                />
                <span className="text-lg">Origin</span>
                <ul className="text-sm">
                  <li>
                    <span>
                      {generalData.origin &&
                        generalData.origin[getIndex(selectedOrigin)]
                          ?.addressLine1}
                    </span>
                  </li>
                  <li>
                    <span>
                      {generalData.origin &&
                        generalData.origin[getIndex(selectedOrigin)]
                          ?.addressLine2}
                    </span>
                  </li>
                  <li>
                    <span>
                      {generalData.origin &&
                        generalData.origin[getIndex(selectedOrigin)]?.city}
                    </span>
                  </li>
                </ul>
              </section>
              <section className="w-1/3">
                <span className="text-lg">Destination</span>
                <ul className="text-sm">
                  <li>
                    <span>{generalData.destination?.addressLine1}</span>
                  </li>
                  <li>
                    <span>{generalData.destination?.addressLine2}</span>
                  </li>
                  <li>
                    <span>{generalData.destination?.city}</span>
                  </li>
                </ul>
              </section>
            </div>
            {generalData.origin &&
              generalData.origin[getIndex(selectedOrigin)] && (
                <div className="mx-2 flex gap-2 items-center justify-between flex-col sm:flex-row">
                  <div style={style}>
                    <iframe
                      src={`https://maps.google.com/maps?q=${get(
                        generalData,
                        "destination.geolocation.coordinates[1]",
                        "76.7751434"
                      )},${get(
                        generalData,
                        "destination.geolocation.coordinates[0]",
                        "76.7751434"
                      )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                      width={(window.innerWidth / 100) * 30}
                      height={(window.innerHeight / 100) * 40}
                    />
                  </div>
                  <div style={style}>
                    <iframe
                      src={`https://maps.google.com/maps?q=${get(
                        generalData.origin[getIndex(selectedOrigin)],
                        "geolocation.coordinates[1]",
                        "76.7751434"
                      )},${get(
                        generalData.origin[getIndex(selectedOrigin)],
                        "geolocation.coordinates[0]",
                        "76.7751434"
                      )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                      width={(window.innerWidth / 100) * 30}
                      height={(window.innerHeight / 100) * 40}
                    />
                  </div>
                </div>
              )}
          </FormLayout>
          <FormLayout title={""}>
            <div className="mx-2 flex justify-between flex-col sm:flex-row mb-8 gap-4">
              <section className="flex flex-col">
                <span className="text-lg">Journey</span>
                <span className="text-sm">{`Distance : ${generalData?.journey?.distance?.text}`}</span>
                <span className="text-sm">{`Duration : ${generalData?.journey?.duration?.text}`}</span>
              </section>
              <section className="flex flex-col">
                <span className="text-lg">Is Document Required</span>
                {generalData?.isDocumentRequired?.names?.map((doc, index) => {
                  return (
                    <span key={index} className="text-sm">
                      {doc}
                    </span>
                  );
                })}
              </section>
            </div>
            {get(generalData, "pickupPicture", "") && (
              <>
                {" "}
                <span className="text-lg">Pickup picture</span>
                <img
                  src={generalData.pickupPicture}
                  alt="Pickup"
                  className="w-60 h-60 my-2 mb-8 rounded-xl"
                />
              </>
            )}
            <div className="flex sm:justify-between flex-col sm:flex-row">
              <section>
                <span className="text-lg">Instructions</span>
                <ul className="mb-4">
                  {generalData?.instructions?.map((ins, i) => {
                    return (
                      <li key={i}>
                        <span className="ml-2 text-sm">{ins}</span>
                      </li>
                    );
                  })}
                </ul>
              </section>
              <span className="text-lg">
                Session Id :{" "}
                <span className="text-sm">{generalData?.sessionId}</span>
              </span>
            </div>
          </FormLayout>
        </>
      )}
    </div>
  );
}
