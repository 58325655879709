import ActionTypes from "../actions/types";

const zoneInitialState = {
  allZones: [],
  selectedZone: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const zoneReducer = (state = zoneInitialState, action) => {
  let tempAllZones = state.allZones;
  switch (action.type) {
    case ActionTypes.Zones.SET_ZONES_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.Zones.GET_ALL_ZONES.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Zones.GET_ALL_ZONES.SUCCESS:
      return {
        ...state,
        loading: false,
        allZones: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Zones.GET_ALL_ZONES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        pages: 1,
        allZones: [],
      };

    case ActionTypes.Zones.GET_ONE_ZONE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Zones.GET_ONE_ZONE.SUCCESS:
      return {
        ...state,
        loading: false,
        selectedZone: action.payload.zone,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Zones.GET_ONE_ZONE.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        pages: 1,
        allZones: [],
      };

    case ActionTypes.Zones.CREATE_ZONE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Zones.CREATE_ZONE.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Zones.CREATE_ZONE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.Zones.UPDATE_ZONE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Zones.UPDATE_ZONE.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Zones.UPDATE_ZONE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };

    case ActionTypes.Zones.DELETE_ZONE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Zones.DELETE_ZONE.SUCCESS:
      tempAllZones = tempAllZones.filter(
        (zone) => zone._id !== action.payload.id
      );
      return {
        ...state,
        allZones: tempAllZones,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Zones.DELETE_ZONE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default zoneReducer;
