import axios from "axios";
import { get } from "lodash";
import { toast } from "react-toastify";
import { store } from "../store";
import { logout } from "../store/actions";

const customAxios = axios.create({
  baseURL: process.env.PUBLIC_URL,
  timeout: 10000,
  headers: {
    "api-key": "eyJz-CI6Ikp-4pWY-lhdCI6",
  },
});

const responseHandler = (response) => {
  let res = {};
  if (response.status === 401) {
    res.success = false;
    res.logout = true;
    res.data = response.data;
    res.error = "Session Expired";
    // check below condition
    store.dispatch(logout.REQUEST({}));
  } else if (response.status < 205) {
    res.success = true;
    res.logout = false;
    res.data = response.data;
    res.error = "";
  } else {
    res.success = false;
    res.logout = false;
    res.data = {};
    res.error = response.data;
  }

  return res;
};

const errorHandler = (error) => {
  let res = {};
  if (error.response.status === 401) {
    res.success = false;
    res.logout = true;
    res.data = {};
    res.error = error.message;
    // check below condition
    store.dispatch(logout.REQUEST({}));
    return res;
  } else {
    return Promise.reject(error);
  }
};

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;
