import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";

const Loader = () => {
  const { loading } = useSelector((state) => state.auth);

  return (
    <>
      {loading && (
        <div
          className="absolute w-full h-full flex items-center justify-center"
          style={{ backgroundColor: "rgba(0,0,0,0.8)", zIndex: 1000 }}
        >
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#0D70EA"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loading}
          />
        </div>
      )}
    </>
  );
};

export default Loader;
