import Modal from "react-modal";
import { MdOutlineClose } from "react-icons/md";
import TextInput from "../TextInput";
import Button from "../Button";

const EditFieldModal = ({
  title,
  name,
  type,
  value,
  onChange,
  isOpen,
  closeModal,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      contentLabel="Delete Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.55)",
          zIndex: 999,
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        },
        content: {
          width: "50vw",
          maxWidth: "550px",
          minWidth: "300px",
          height: "max-content",
          position: "relative",
          padding: 0,
          paddingTop: "15px",
          margin: 0,
          inset: 0,
        },
      }}
    >
      <div className={`flex justify-between mx-4 items-center`}>
        <h1 className={`capitalize text-xl font-light`}>{`Edit ${title}`}</h1>
        <button
          className={`transition duration-500 hover:bg-grey p-1 rounded-full`}
          onClick={closeModal}
        >
          <MdOutlineClose size={25} />
        </button>
      </div>
      <hr className={`border my-2`} />
      <div className={`p-2 px-4 mb-2`}>
        {/* <input name={name} type={type} value={value} onChange={onChange} /> */}
        <TextInput
          title={"Title"}
          name={name}
          value={value}
          type={type}
          onChange={onChange}
          placeholder={""}
          showError={false}
          isReadOnly={false}
          errorMessage={`Please enter ${title}`}
        />
        <section className="flex justify-end">
          <button
            className={`bg-transparent text-red border border-red hover:bg-gray-100 px-4 py-2 rounded font-light mr-2`}
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className={`bg-blue text-white px-4 py-2 rounded font-light opacity-75 hover:opacity-100`}
            onClick={onSubmit}
          >
            Submit
          </button>
        </section>
      </div>
    </Modal>
  );
};

export default EditFieldModal;
