import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormLayout from "../../components/FormLayout";
import { Dropdown, TextInput, Button2 } from "../../components";
import {
  ADD_AGENT_AADHAR,
  ADD_AGENT_BANK_DETAILS,
  ADD_AGENT_EMERGENCY_CONTACT,
  ADD_AGENT_PAN,
  CREATE_AGENT,
  FETCH_AGENT,
  GET_ALL_JOB_TYPES,
  GET_ALL_OPERATIONAL_CITIES,
  UPDATE_AGENT,
  UPDATE_AGENT_ADDRESS,
  UPDATE_AGENT_JOB_TYPE,
  UPDATE_AGENT_OPERATIONAL_CITY,
  UPDATE_AGENT_VEHICLE_TYPE,
} from "../../store/actions";
import moment from "moment";
import Address from "../../components/AddressAutoComplete";
import { get } from "lodash";
import Avatar from "../../components/Avatar";
import { gender } from "../../constants/common";
import { toast } from "react-toastify";
import ArrayText from "../../components/ArrayText";
import {
  getBase64Image,
  isValid_Aadhaar_Number,
  isValid_Pan_Number,
  validateAddress,
  validateEmail,
  validatePhoneNumber,
  validateVehicleNumber,
} from "../../utils";
import ImageInput from "../../components/ImageInput";

const style = {
  width: "fit-content",
  minHeight: "40vh",
  marginTop: "20px",
  backgroundColor: "white",
  borderRadius: "10px",
};

const allVehicleTypes = [
  { label: "Bike", value: "bike" },
  { label: "Cycle", value: "cycle" },
  { label: "Scooty", value: "scooty" },
];

const allGenders = [
  { label: gender.male, value: gender.male },
  { label: gender.female, value: gender.female },
  { label: gender.other, value: gender.other },
];

const allAppLanguages = [
  { label: "English", value: "english" },
  { label: "Hindi", value: "hindi" },
];

const allAccountTypes = [
  { label: "Current", value: "current" },
  { label: "Saving", value: "saving" },
];

export default function AgentForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const [allOpCities, setAllOpCities] = useState([]);
  const [allJTs, setAllJTs] = useState([]);
  const { selectedAgent } = useSelector((state) => state.agent);
  const { allOperationalCities } = useSelector(
    (state) => state.operationalCity
  );
  const { allJobTypes } = useSelector((state) => state.jobType);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  const [generalData, setGeneralData] = useState({});

  useEffect(() => {
    setGeneralData({});
    if (id && id !== "default") dispatch(FETCH_AGENT.REQUEST({ id }));
  }, [id]);

  useEffect(() => {
    if (selectedAgent && id) {
      let tempGeneralData = { ...selectedAgent };
      setGeneralData(tempGeneralData);
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (!allOperationalCities?.length) {
      dispatch(GET_ALL_OPERATIONAL_CITIES.REQUEST());
    }
    if (!allJobTypes?.length) {
      dispatch(GET_ALL_JOB_TYPES.REQUEST());
    }
  }, []);

  useEffect(() => {
    let updatedOpCities = [];
    allOperationalCities.map((city, index) => {
      updatedOpCities.push({ label: city.city, value: city._id });
    });
    setAllOpCities(updatedOpCities);
  }, [allOperationalCities]);

  useEffect(() => {
    let updatedJTs = [];
    allJobTypes.map((jobType, index) => {
      updatedJTs.push({ label: jobType.title, value: jobType._id });
    });
    setAllJTs(updatedJTs);
  }, [allJobTypes]);

  const handleChange = (e) => {
    let tempGD = { ...generalData };
    tempGD[e.target.name] = e.target.value;
    setGeneralData(tempGD);
  };

  const validateAgent = (agentData) => {
    if (agentData.name.length < 2) {
      toast.error("Name must be 2 characters long!");
      return false;
    }
    if (!validateEmail(agentData.email)) {
      toast.error("Please enter valid email!");
      return false;
    }
    if (!validatePhoneNumber(agentData.contactNo)) {
      toast.error("Please enter valid phone number!");
      return false;
    }
    if (agentData.spokenLanguages.length < 1) {
      toast.error("Please enter preferred languages!");
      return false;
    }
    if (!agentData.gender) {
      toast.error("Please select gender!");
      return false;
    }
    if (agentData.name.length < 2) {
      toast.error("Name must be 2 characters long!");
      return false;
    }
    if (!agentData.preferredLanguage) {
      toast.error("Please select preferred app language!");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    let agentData = {
      name: get(generalData, "agentId.name", ""),
      email: get(generalData, "agentId.email", ""),
      contactNo: get(generalData, "agentId.contactNo", ""),
      gender: get(generalData, "gender", ""),
      spokenLanguages: get(generalData, "spokenLanguages", []),
      preferredLanguage: get(generalData, "preferredLanguage", ""),
    };
    if (action === "create") {
      // create agent
      if (validateAgent(agentData)) {
        dispatch(
          CREATE_AGENT.REQUEST({
            value: { ...agentData },
            navigate: navigate,
          })
        );
      }
    } else {
      // edit agent
      if (validateAgent(agentData)) {
        // profile pictutre validation is left optional
        let base64Profile = getBase64Image(
          get(generalData, "profilePicture", "")
        );
        dispatch(
          UPDATE_AGENT.REQUEST({
            value: {
              ...agentData,
              agentId: get(generalData, "agentId._id", ""),
              profilePicture: base64Profile,
            },
            navigate: navigate,
          })
        );
      }
    }
  };

  const updateAddress = () => {
    if (validateAddress(get(generalData, "address", {}))) {
      const houseFlatOrBlock = get(generalData, "address.houseFlatOrBlock", "");
      const apartmentRoadOrArea = get(
        generalData,
        "address.apartmentRoadOrArea",
        ""
      );
      if (!houseFlatOrBlock) {
        toast.error("Please add house flat or block!");
        return;
      }
      if (!apartmentRoadOrArea) {
        toast.error("Please add apartment road or area!");
        return;
      }
      let tempAddress = {
        houseFlatOrBlock: houseFlatOrBlock,
        apartmentRoadOrArea: apartmentRoadOrArea,
        addressLine1: get(generalData, "address.addressLine1", ""),
        addressLine2: get(generalData, "address.addressLine2", ""),
        city: get(generalData, "address.city", ""),
        state: get(generalData, "address.state", ""),
        country: get(generalData, "address.country", ""),
        placeId: get(generalData, "address.placeId", ""),
        pinCode: get(generalData, "address.pinCode", ""),
        geolocation: get(generalData, "address.geolocation", ""),
        agentId: get(generalData, "agentId._id", ""),
      };
      dispatch(UPDATE_AGENT_ADDRESS.REQUEST({ value: tempAddress }));
    }
  };

  const handleVehicleUpdate = () => {
    const vt = get(generalData, "vehicleType.type", "");
    const vNumber = get(generalData, "vehicleType.vehicleNumber", "");
    if (vt) {
      if (vt === "cycle") {
        dispatch(
          UPDATE_AGENT_VEHICLE_TYPE.REQUEST({
            value: {
              ...generalData.vehicleType,
              agentId: generalData.agentId._id,
            },
          })
        );
      } else {
        if (validateVehicleNumber(vNumber)) {
          dispatch(
            UPDATE_AGENT_VEHICLE_TYPE.REQUEST({
              value: {
                ...generalData.vehicleType,
                agentId: generalData.agentId._id,
              },
            })
          );
        } else {
          toast.error("Please enter correct vehicle number!");
        }
      }
    } else {
      toast.error("Please select vehicle type!");
    }
  };

  const handleJobPreferenceUpdate = () => {
    if (generalData?.operationalCity?._id && generalData?.jobType?._id) {
      dispatch(
        UPDATE_AGENT_JOB_TYPE.REQUEST({
          value: {
            jobType: generalData.jobType._id,
            agentId: generalData.agentId._id,
          },
        })
      );
      dispatch(
        UPDATE_AGENT_OPERATIONAL_CITY.REQUEST({
          value: {
            operationalCity: generalData.operationalCity._id,
            agentId: generalData.agentId._id,
          },
        })
      );
    } else {
      toast.error("Please enter all job preference fields!");
    }
  };

  const handleEditClick = () => {
    let url = window.location.pathname;
    url = url.replace("view", "edit");
    navigate(`${url}`);
  };

  const addAadhar = () => {
    if (
      get(generalData, "aadharNumber.number", "") &&
      isValid_Aadhaar_Number(get(generalData, "aadharNumber.number", ""))
    ) {
      if (generalData.aadharNumber.front) {
        if (generalData.aadharNumber.back) {
          let base64Front = getBase64Image(
            get(generalData, "aadharNumber.front", "")
          );
          let base64Back = getBase64Image(
            get(generalData, "aadharNumber.back", "")
          );
          dispatch(
            ADD_AGENT_AADHAR.REQUEST({
              value: {
                aadharNumber: generalData.aadharNumber.number,
                front: base64Front,
                back: base64Back,
                agentId: get(generalData, "agentId._id", ""),
              },
              navigate: navigate,
            })
          );
        } else {
          toast.error("Please select aadhar back!");
        }
      } else {
        toast.error("Please select aadhar front!");
      }
    } else {
      toast.error("Please add aadhar number!");
    }
  };

  const addPan = () => {
    if (
      get(generalData, "panNumber.number", "") &&
      isValid_Pan_Number(get(generalData, "panNumber.number", ""))
    ) {
      if (get(generalData, "panNumber.picture", "")) {
        var base64result = getBase64Image(
          get(generalData, "panNumber.picture", "")
        );
        dispatch(
          ADD_AGENT_PAN.REQUEST({
            value: {
              panNumber: generalData.panNumber.number,
              pan: base64result,
              agentId: get(generalData, "agentId._id", ""),
            },
            navigate: navigate,
          })
        );
      } else {
        toast.error("Please select PAN image!");
      }
    } else {
      toast.error("Please add PAN number!");
    }
  };
  const addBankDetails = () => {
    if (generalData.bankDetails?.bankName) {
      if (generalData.bankDetails?.accountHolderName) {
        if (generalData.bankDetails?.accountNumber) {
          if (generalData.bankDetails?.accountType) {
            if (generalData.bankDetails?.IFSCCode) {
              dispatch(
                ADD_AGENT_BANK_DETAILS.REQUEST({
                  value: {
                    agentId: get(generalData, "agentId._id", ""),
                    bankName: get(generalData, "bankDetails.bankName", ""),
                    accountNumber: get(
                      generalData,
                      "bankDetails.accountNumber",
                      ""
                    ),
                    accountType: get(
                      generalData,
                      "bankDetails.accountType",
                      ""
                    ),
                    IFSCCode: get(generalData, "bankDetails.IFSCCode", ""),
                    accountHolderName: get(
                      generalData,
                      "bankDetails.accountHolderName",
                      ""
                    ),
                  },
                  navigate: navigate,
                })
              );
            } else {
              toast.error("Please enter IFSC code!");
            }
          } else {
            toast.error("Please select account type!");
          }
        } else {
          toast.error("Please enter account number!");
        }
      } else {
        toast.error("Please enter account holder name!");
      }
    } else {
      toast.error("Please enter bank name!");
    }
  };
  const addEmergencyContact = () => {
    if (get(generalData, "emergencyContact.name", "")) {
      if (
        validatePhoneNumber(
          get(generalData, "emergencyContact.contactNumber", "")
        )
      ) {
        dispatch(
          ADD_AGENT_EMERGENCY_CONTACT.REQUEST({
            value: {
              ...get(generalData, "emergencyContact", {}),
              agentId: get(generalData, "agentId._id", ""),
            },
          })
        );
      } else {
        toast.error("Please enter correct emergency contact number!");
      }
    } else {
      toast.error("Please enter emergency contact person's name!");
    }
  };

  return (
    <>
      {readOnly && (
        <section className="flex justify-between items-center ml-4">
          <section>
            <span className="text-2xl">Agent</span>
            <span
              className="mx-2 px-4 rounded-xl text-white text-xs py-1"
              style={{
                backgroundColor: generalData?.isActive ? "#106914" : "#BF2A0A",
              }}
            >
              {generalData.isActive ? "Active" : "InActive"}
            </span>
            {generalData && generalData?.agentId?.isBlocked && (
              <span
                className="mx-2 px-4 py-1 rounded-xl text-white text-xs"
                style={{ backgroundColor: "#BF2A0A" }}
              >
                Blocked
              </span>
            )}
            {generalData && generalData?.agentId?.isBlocked && (
              <span className="mx-2 px-2 py-1 rounded-xl text-white text-xs">
                {`Reason : ${generalData.gender}`}
                {/* Reason to be changed */}
              </span>
            )}
          </section>
          {action === "view" && (
            <Button2 title={"Edit"} handleClick={handleEditClick} />
          )}
        </section>
      )}
      {readOnly && (
        <span className="text-zinc-500 text-sm ml-4">{`Last active : ${moment(
          `${generalData.lastOnline}`
        ).fromNow()}`}</span>
      )}
      <FormLayout title={action === "create" ? "Create Agent" : "General"}>
        <div>
          {!["create"].includes(action) && (
            <div className="w-full flex justify-center items-center mb-8">
              <Avatar
                src={get(generalData, "profilePicture", " ")}
                name={get(generalData, "agentId.name", " ")[0]}
                bgColor={"#8F00FF"}
                textColor={"white"}
                onChange={(val) => {
                  let tempGenData = { ...generalData };
                  tempGenData["profilePicture"] = val;
                  setGeneralData(tempGenData);
                }}
                readOnly={readOnly}
              />
            </div>
          )}
          <div
            className={`mx-2 grid grid-rows-6 sm:grid-rows-3 grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Name"}
              name={"name"}
              type={"text"}
              value={generalData?.agentId?.name}
              onChange={(e) => {
                let tempVal = {
                  ...generalData.agentId,
                  [e.target.name]: e.target.value,
                };
                let tempE = { target: { name: "agentId", value: tempVal } };
                handleChange(tempE);
              }}
              placeholder={"Name"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter name"}
            />
            <TextInput
              title={"Email"}
              name={"email"}
              type={"text"}
              value={generalData?.agentId?.email}
              onChange={(e) => {
                let tempVal = {
                  ...generalData.agentId,
                  [e.target.name]: e.target.value,
                };
                let tempE = { target: { name: "agentId", value: tempVal } };
                handleChange(tempE);
              }}
              placeholder={"Email"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter email"}
            />
            <TextInput
              title={"Contact No"}
              name={"contactNo"}
              type={"text"}
              value={generalData?.agentId?.contactNo}
              onChange={(e) => {
                let tempVal = {
                  ...generalData.agentId,
                  [e.target.name]: e.target.value,
                };
                let tempE = { target: { name: "agentId", value: tempVal } };
                handleChange(tempE);
              }}
              placeholder={"Contact No"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter contactNo"}
            />
            <ArrayText
              title={"Preferred Languages"}
              name={"contactNo"}
              type={"text"}
              value={get(generalData, "spokenLanguages", [])}
              onChange={(e) => {
                let tempE = {
                  target: { name: "spokenLanguages", value: e },
                };
                handleChange(tempE);
              }}
              placeholder={"Preferred Languages"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter spokenLanguages"}
            />
            <Dropdown
              title={"Gender"}
              name={"gender"}
              value={{
                value: generalData?.gender,
                label: "",
              }}
              onChange={(e) => {
                let tempE = { target: { name: "gender", value: e.value } };
                handleChange(tempE);
              }}
              placeholder={"Gender"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={""}
              options={allGenders}
            />
            <Dropdown
              title={"Preferred app language"}
              name={"preferredLanguage"}
              value={{
                value: get(generalData, "preferredLanguage", ""),
                label: "",
              }}
              onChange={(e) => {
                let tempE = {
                  target: { name: "preferredLanguage", value: e.value },
                };
                handleChange(tempE);
              }}
              placeholder={"Preferred app language"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={""}
              options={allAppLanguages}
            />
          </div>
        </div>
        {!readOnly && <Button2 title={"Submit"} handleClick={handleSubmit} />}
      </FormLayout>
      {!["create"].includes(action) && (
        <FormLayout title="Address">
          {action === "edit" && (
            <div
              className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
            >
              {/* <TextInput
                title={"Receiver Name"}
                name={"receiverName"}
                type={"text"}
                value={generalData?.address?.receiverName}
                onChange={(e) => {
                  let tempVal = {
                    ...generalData.address,
                    [e.target.name]: e.target.value,
                  };
                  let tempE = { target: { name: "address", value: tempVal } };
                  handleChange(tempE);
                }}
                placeholder={"Receiver Name"}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter receiverName"}
              />
              <TextInput
                title={"Receiver Contact Number"}
                name={"receiverContactNumber"}
                type={"number"}
                value={generalData?.address?.receiverContactNumber}
                onChange={(e) => {
                  let tempVal = {
                    ...generalData.address,
                    [e.target.name]: e.target.value,
                  };
                  let tempE = { target: { name: "address", value: tempVal } };
                  handleChange(tempE);
                }}
                placeholder={"Receiver Contact Number"}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter receiverContactNumber"}
              /> */}
              <TextInput
                title={"House Flat Or Block"}
                name={"houseFlatOrBlock"}
                type={"text"}
                value={get(generalData, "address.houseFlatOrBlock", "")}
                onChange={(e) => {
                  let tempVal = {
                    ...generalData.address,
                    [e.target.name]: e.target.value,
                  };
                  let tempE = { target: { name: "address", value: tempVal } };
                  handleChange(tempE);
                }}
                placeholder={"House Flat Or Block"}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter houseFlatOrBlock"}
              />
              <TextInput
                title={"Apartment Road Or Area"}
                name={"apartmentRoadOrArea"}
                type={"text"}
                value={get(generalData, "address.apartmentRoadOrArea", "")}
                onChange={(e) => {
                  let tempVal = {
                    ...generalData.address,
                    [e.target.name]: e.target.value,
                  };
                  let tempE = { target: { name: "address", value: tempVal } };
                  handleChange(tempE);
                }}
                placeholder={"Apartment Road Or Area"}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter apartmentRoadOrArea"}
              />
            </div>
          )}
          <Address
            address={get(generalData, "address", {})}
            readOnly={readOnly}
            handleChange={(data) => {
              let tempGeneralData = { ...generalData };
              tempGeneralData["address"] = { ...data };
              setGeneralData(tempGeneralData);
            }}
            updateAddress={(data) => {
              updateAddress();
            }}
            hideTitle={true}
            getPlaceId={true}
          />
          <div className="mx-2 flex items-center justify-center overflow-x-auto md:overflow-x-visible">
            <div style={style}>
              <iframe
                src={`https://maps.google.com/maps?q=${get(
                  generalData,
                  "address.geolocation.coordinates[1]",
                  "76.7751434"
                )},${get(
                  generalData,
                  "address.geolocation.coordinates[0]",
                  "76.7751434"
                )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                width={(window.innerWidth / 100) * 50}
                height={(window.innerHeight / 100) * 40}
              />
            </div>
          </div>
        </FormLayout>
      )}
      {!["create"].includes(action) && (
        <FormLayout title={"Vehicle Details"}>
          <div
            className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
          >
            <Dropdown
              title={"Vehicle Type"}
              name={"type"}
              value={{
                value: generalData?.vehicleType?.type,
                label: "",
              }}
              onChange={(e) => {
                let tempVal = {
                  ...generalData.vehicleType,
                  type: e.value,
                };
                if (e.value === "cycle") {
                  tempVal["vehicleNumber"] = "";
                }
                let tempE = { target: { name: "vehicleType", value: tempVal } };
                handleChange(tempE);
              }}
              placeholder={"Vehicle Type"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={""}
              options={allVehicleTypes}
            />

            <TextInput
              title={"Vehicle Number"}
              name={"vehicleNumber"}
              type={"text"}
              value={generalData?.vehicleType?.vehicleNumber}
              onChange={(e) => {
                let tempVal = {
                  ...generalData.vehicleType,
                  [e.target.name]: e.target.value,
                };
                let tempE = {
                  target: { name: "vehicleType", value: tempVal },
                };
                handleChange(tempE);
              }}
              placeholder={"Vehicle Number"}
              showError={false}
              isReadOnly={
                readOnly ||
                ["cycle"].includes(get(generalData, "vehicleType.type", ""))
              }
              errorMessage={"Please enter vehicle number"}
            />
          </div>
          {!readOnly && (
            <Button2 title={"Submit"} handleClick={handleVehicleUpdate} />
          )}
        </FormLayout>
      )}
      {!["create"].includes(action) && (
        <FormLayout title={"Job Preference"}>
          <div
            className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
          >
            <Dropdown
              title={"Operational City"}
              name={"city"}
              value={{
                value: get(generalData, "operationalCity._id", ""),
                label: "",
              }}
              onChange={(e) => {
                let tempVal = {
                  ...generalData.operationalCity,
                  city: e.label,
                  _id: e.value,
                };
                let tempE = {
                  target: { name: "operationalCity", value: tempVal },
                };
                handleChange(tempE);
              }}
              placeholder={"Job Preference"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={""}
              options={allOpCities}
            />
            <Dropdown
              title={"Job Type"}
              name={"title"}
              value={{
                value: get(generalData, "jobType._id", ""),
                label: "",
              }}
              onChange={(e) => {
                let tempVal = {
                  ...generalData.jobType,
                  title: e.label,
                  _id: e.value,
                };
                let tempE = { target: { name: "jobType", value: tempVal } };
                handleChange(tempE);
              }}
              placeholder={"Job Type"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={""}
              options={allJTs}
            />
          </div>
          {!readOnly && (
            <Button2 title={"Submit"} handleClick={handleJobPreferenceUpdate} />
          )}
        </FormLayout>
      )}
      {!["create", "edit"].includes(action) && (
        <FormLayout title={"Daily Progress"}>
          <div
            className={`mx-2 grid grid-rows-3 sm:grid-rows-2 grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Total Trips"}
              name={"totalTrips"}
              type={"number"}
              value={generalData?.dailyProgress?.totalTrips}
              onChange={(e) => {
                // handleChange(e);
              }}
              placeholder={"Total Trips"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter total trips"}
            />
            <TextInput
              title={"Total Kms"}
              name={"totalKms"}
              type={"number"}
              value={generalData?.dailyProgress?.totalKms}
              onChange={(e) => {
                // handleChange(e);
              }}
              placeholder={"Total Kms"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter total Kms"}
            />
            <TextInput
              title={"Total online time (sec)"}
              name={"totalOnlineTime"}
              type={"number"}
              value={generalData?.dailyProgress?.totalOnlineTime}
              onChange={(e) => {
                // handleChange(e);
              }}
              placeholder={"Total online time"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter total onlin time"}
            />
          </div>
        </FormLayout>
      )}
      {!["create", "edit"].includes(action) && (
        <FormLayout title={""}>
          <div
            className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Daily targets"}
              name={"dailyTargets"}
              type={"number"}
              value={generalData?.dailyTargets}
              onChange={(e) => {
                // handleChange(e);
              }}
              placeholder={"Daily targets"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter dailyTargets"}
            />
            <TextInput
              title={"Floating cash"}
              name={"floatingCash"}
              type={"number"}
              value={generalData?.floatingCash}
              onChange={(e) => {
                // handleChange(e);
              }}
              placeholder={"Floating cash"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter floatingCash"}
            />
          </div>
        </FormLayout>
      )}
      {console.log(generalData.aadharNumber)}
      {!["create"].includes(action) &&
        (generalData?.aadharNumber?.front ||
          generalData?.aadharNumber?.back ||
          action === "edit") && (
          <FormLayout title={"Aadhar Details"}>
            <TextInput
              title={"Aadhar Number"}
              name={"number"}
              type={"text"}
              value={get(generalData, "aadharNumber.number", "")}
              onChange={(e) => {
                let tempE = {
                  target: {
                    name: "aadharNumber",
                    value: {
                      ...generalData.aadharNumber,
                      number: e.target.value,
                    },
                  },
                };
                handleChange(tempE);
              }}
              placeholder={"Aadhar Number"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter aadhar number"}
            />
            <div
              className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
            >
              {(generalData?.aadharNumber?.front || action === "edit") && (
                <ImageInput
                  id={"aadhar_front"}
                  title={"Select Aadhar front"}
                  src={get(generalData, "aadharNumber.front", "")}
                  onChange={(val) => {
                    let tempData = { ...generalData };
                    tempData["aadharNumber"] = { ...generalData.aadharNumber };
                    tempData.aadharNumber["front"] = val;
                    setGeneralData(tempData);
                  }}
                  readOnly={readOnly}
                />
              )}
              {(generalData?.aadharNumber?.back || action === "edit") && (
                <ImageInput
                  id={"aadhar_back"}
                  title={"Select Aadhar back"}
                  src={get(generalData, "aadharNumber.back", "")}
                  onChange={(val) => {
                    let tempData = { ...generalData };
                    tempData["aadharNumber"] = { ...generalData.aadharNumber };
                    tempData.aadharNumber["back"] = val;
                    setGeneralData(tempData);
                  }}
                  readOnly={readOnly}
                />
              )}
            </div>
            <Button2 title={"Submit"} handleClick={addAadhar} />
          </FormLayout>
        )}
      {!["create"].includes(action) &&
        (generalData?.panNumber || action === "edit") && (
          <FormLayout title={"PAN number"}>
            <div
              className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
            >
              <TextInput
                title={"PAN Number"}
                name={"number"}
                type={"text"}
                value={get(generalData, "panNumber.number", "")}
                onChange={(e) => {
                  let tempE = {
                    target: {
                      name: "panNumber",
                      value: {
                        ...generalData.panNumber,
                        number: e.target.value,
                      },
                    },
                  };
                  handleChange(tempE);
                }}
                placeholder={"PAN Number"}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter PAN number"}
              />
              <ImageInput
                id={"PAN"}
                title={"Select PAN"}
                src={get(generalData, "panNumber.picture", "")}
                onChange={(val) => {
                  let tempData = { ...generalData };
                  tempData["panNumber"] = { ...generalData.panNumber };
                  tempData.panNumber["picture"] = val;
                  setGeneralData(tempData);
                }}
                readOnly={readOnly}
              />
            </div>
            <Button2 title={"Submit"} handleClick={addPan} />
          </FormLayout>
        )}
      {!["create", "edit"].includes(action) && (
        <FormLayout title={"Completed Jobs"}>
          <ul>
            {generalData?.completedJobs?.map((job, index) => {
              return (
                <li
                  key={index}
                  className="flex justify-between mb-2 bg-gray-100 px-4 py-2 rounded-xl"
                >
                  <span>{`Earning : ${job.earning}`}</span>
                  <span>{`Is Completed : ${job.isComplete}`}</span>
                </li>
              );
            })}
          </ul>
        </FormLayout>
      )}
      {!["create"].includes(action) && (
        <FormLayout title={"Bank Details"}>
          <div
            className={`mx-2 grid grid-rows-5 sm:grid-rows-3 grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Bank Name"}
              name={"bankName"}
              type={"text"}
              value={get(generalData, "bankDetails.bankName", "")}
              onChange={(e) => {
                let tempE = { ...get(generalData, "bankDetails", {}) };
                tempE[e.target.name] = e.target.value;
                handleChange({ target: { name: "bankDetails", value: tempE } });
              }}
              placeholder={"Bank Name"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter bankName"}
            />
            <TextInput
              title={"Account No."}
              name={"accountNumber"}
              type={"text"}
              value={get(generalData, "bankDetails.accountNumber", "")}
              onChange={(e) => {
                let tempE = { ...get(generalData, "bankDetails", {}) };
                tempE[e.target.name] = e.target.value;
                handleChange({ target: { name: "bankDetails", value: tempE } });
              }}
              placeholder={"Account No."}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter accountNo"}
            />
            <TextInput
              title={"IFSC code"}
              name={"IFSCCode"}
              type={"text"}
              value={get(generalData, "bankDetails.IFSCCode", "")}
              onChange={(e) => {
                let tempE = { ...get(generalData, "bankDetails", {}) };
                tempE[e.target.name] = e.target.value;
                handleChange({ target: { name: "bankDetails", value: tempE } });
              }}
              placeholder={"IFSC code"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter ifscCode"}
            />
            <TextInput
              title={"Account Holder Name"}
              name={"accountHolderName"}
              type={"text"}
              value={get(generalData, "bankDetails.accountHolderName", "")}
              onChange={(e) => {
                let tempE = { ...get(generalData, "bankDetails", {}) };
                tempE[e.target.name] = e.target.value;
                handleChange({ target: { name: "bankDetails", value: tempE } });
              }}
              placeholder={"Account Holder Name"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter accountHolderName"}
            />
            <Dropdown
              title={"Account Type"}
              name={"accountType"}
              value={{
                value: get(generalData, "bankDetails.accountType", ""),
                label: "",
              }}
              onChange={(e) => {
                let tempE = { ...get(generalData, "bankDetails", {}) };
                tempE["accountType"] = e.value;
                handleChange({ target: { name: "bankDetails", value: tempE } });
              }}
              placeholder={"Account Type"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={""}
              options={allAccountTypes}
            />
          </div>
          <Button2 title={"Submit"} handleClick={addBankDetails} />
        </FormLayout>
      )}
      {!["create"].includes(action) && (
        <FormLayout title={"Emergency Contact"}>
          <div
            className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Contact Name"}
              name={"name"}
              type={"text"}
              value={get(generalData, "emergencyContact.name", "")}
              onChange={(e) => {
                let tempE = {
                  target: {
                    name: "emergencyContact",
                    value: {
                      ...generalData.emergencyContact,
                      [e.target.name]: e.target.value,
                    },
                  },
                };
                handleChange(tempE);
              }}
              placeholder={"Contact Name"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter contactName"}
            />
            <TextInput
              title={"Contact Number"}
              name={"contactNumber"}
              type={"text"}
              value={get(generalData, "emergencyContact.contactNumber", "")}
              onChange={(e) => {
                let tempE = {
                  target: {
                    name: "emergencyContact",
                    value: {
                      ...generalData.emergencyContact,
                      [e.target.name]: e.target.value,
                    },
                  },
                };
                handleChange(tempE);
              }}
              placeholder={"Contact Number"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter contactNumber"}
            />
          </div>
          <Button2 title={"Submit"} handleClick={addEmergencyContact} />
        </FormLayout>
      )}
    </>
  );
}
