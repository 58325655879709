import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const getAllJobTypeService = async (data) => {
  try {
    let URL = apiURL + "jobType/getAll";
    let config = {
      params: {
        page: data.page,
        perPage: data.perPage,
        status: data.status,
      },
    };
    let res = await authRequest(URL, "GET", {}, config);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const createJobTypeService = async (data) => {
  try {
    let URL = apiURL + "jobType/create";
    let res = await authRequest(URL, "POST", {}, data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateJobTypeService = async (data) => {
  try {
    let URL = apiURL + "jobType/update/" + data.id;
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const fetchJobTypeService = async (data) => {
  try {
    let URL = apiURL + "jobType/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const deleteJobTypeService = async (data) => {
  console.log("Delete ", data);
  try {
    let URL = apiURL + "jobType/delete/" + data.id;
    let res = await authRequest(URL, "DELETE", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
