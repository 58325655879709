import { useSelector } from "react-redux";

const TextInput = ({
  type,
  value,
  onChange,
  placeholder,
  showError,
  isReadOnly,
  errorMessage,
  title,
  name,
  inputProps,
  initial,
}) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);
  return (
    <section className={`flex flex-col mb-4`}>
      <h2
        className={`${
          darkTheme ? "text-white" : "text-text_inp_title"
        } cursor-default text-sm`}
      >
        {title}
      </h2>
      <input
        name={name}
        type={initial ? "text" : type}
        value={initial ? `${initial} ${value ? value : ""}` : value}
        onChange={(e) => {
          if (initial) {
            console.log(e.target.value);
            let newE = { ...e };
            let newVal = e.target.value.replace(`${initial}`, " ");
            newE.target.value = newVal.trim();
            onChange(newE);
          } else {
            onChange(e);
          }
        }}
        placeholder={placeholder}
        className={`p-2 mt-1 rounded-md bg-transparent text-black ${
          !darkTheme && "hover:border-black"
        } ${isReadOnly ? "" : "border"}`}
        disabled={isReadOnly}
        {...inputProps}
      />
      {!isReadOnly && (
        <span
          className={`text-red text-xs px-2 ${
            showError ? "visible" : "invisible"
          }`}
        >
          {errorMessage}
        </span>
      )}
    </section>
  );
};

export default TextInput;
