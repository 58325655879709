import ActionTypes from "../actions/types";

const businessTypeInitialState = {
  allBusinessTypes: [],
  selectedBusinessType: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const businessTypeReducer = (state = businessTypeInitialState, action) => {
  let tempAllBusinessTypes = state.allBusinessTypes;
  switch (action.type) {
    case ActionTypes.BusinessTypes.SET_ORDERS_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.BusinessTypes.GET_ALL_BUSINESS_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.BusinessTypes.GET_ALL_BUSINESS_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        allBusinessTypes: action.payload.businessCategory,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.BusinessTypes.GET_ALL_BUSINESS_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        pages: 1,
        allBusinessTypes: [],
      };

    case ActionTypes.BusinessTypes.CREATE_BUSINESS_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.BusinessTypes.CREATE_BUSINESS_TYPE.SUCCESS:
      tempAllBusinessTypes.push(action.payload.businessCategory);
      return {
        ...state,
        allBusinessTypes: tempAllBusinessTypes,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.BusinessTypes.CREATE_BUSINESS_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.BusinessTypes.GET_ONE_BUSINESS_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.BusinessTypes.GET_ONE_BUSINESS_TYPE.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedBusinessTypes: action.payload,
        error: null,
      };
    case ActionTypes.BusinessTypes.GET_ONE_BUSINESS_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.BusinessTypes.UPDATE_BUSINESS_TYPE.REQUEST:
      let index = tempAllBusinessTypes.findIndex(
        (bt) => bt._id === action.payload._id
      );
      tempAllBusinessTypes[index]["title"] = action.payload.title;
      return {
        ...state,
        allBusinessTypes: tempAllBusinessTypes,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.BusinessTypes.UPDATE_BUSINESS_TYPE.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        // selectedBusinessTypes: action.payload,
        error: null,
      };
    case ActionTypes.BusinessTypes.UPDATE_BUSINESS_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.BusinessTypes.DELETE_BUSINESS_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.BusinessTypes.DELETE_BUSINESS_TYPE.SUCCESS:
      console.log(action.payload.payload.id);
      tempAllBusinessTypes = tempAllBusinessTypes.filter(
        (bt) => bt._id !== action.payload.payload.id
      );
      return {
        ...state,
        allBusinessTypes: tempAllBusinessTypes,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.BusinessTypes.DELETE_BUSINESS_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default businessTypeReducer;
