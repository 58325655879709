import React, { useEffect, useState } from "react";
import { SecondaryNavbar, Table } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_ZONES, setZonePagination } from "../../store/actions";
import { isArray } from "lodash";
import { ZonesColumn } from "../../constants/tableHeaders";
import { useNavigate } from "react-router-dom";

const initBusinessType = {
  title: "",
  vendors: 0,
  _id: "",
};

const Zones = () => {
  const { paginationData, allZones: data, pages } = useSelector(
    (state) => state.zones
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    dispatch(GET_ALL_ZONES.REQUEST(paginationData));

    return () => {
      console.log("Component Unmounted from Zones");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState({ ...initBusinessType });

  const onChangePageNumber = (val) => {
    dispatch(setZonePagination({ ...paginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(setZonePagination({ ...paginationData, perPage: val }));
  };

  const onToggleEditModal = () => {
    if (window.location.pathname?.includes("create")) {
      navigate("/businessType");
    }
    if (showEditModal) {
      setEditModalData({ ...initBusinessType });
    }
    setShowEditModal(!showEditModal);
  };

  return (
    <>
      <SecondaryNavbar
        title="Zones"
        btnTitle="Add"
        onClick={() => {
          onToggleEditModal();
          navigate("/zones/create");
        }}
      />
      {isArray(data) && (
        <Table
          darkTheme={darkTheme}
          columns={ZonesColumn}
          data={data || []}
          paginationData={paginationData}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
          totalPages={pages}
        />
      )}
    </>
  );
};

export default Zones;
