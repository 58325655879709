import { get, isNil } from "lodash";

export const formatAddressData = (result) => {
  let temp = {
    city: "",
    state: "",
    country: "",
    postalCode: "",
    addressLine1: "",
    addressLine2: "",
  };
  result?.address_components?.map((item) => {
    if (
      item.types.includes("neighborhood") ||
      item.types.includes("sublocality")
    ) {
      temp.addressLine2 += item.long_name + " ";
    }
    if (
      item.types.includes("locality") ||
      item.types.includes("administrative_area_level_2")
    ) {
      temp.city = item.long_name;
    }
    if (item.types.includes("administrative_area_level_1")) {
      temp.state = item.long_name;
    }
    if (item.types.includes("country")) {
      temp.country = item.long_name;
    }
    if (item.types.includes("postal_code")) {
      temp.pinCode = item.long_name;
    }
  });
  return temp;
};

export const convertFullAddress = (address = null) => {
  if (!isNil(address) && address !== {} && typeof address === "object") {
    let temp = get(address, "addressLine1", "") + " , ";
    temp += get(address, "addressLine2", "") + " , ";
    temp += get(address, "city", "") + " , ";
    temp += get(address, "state", "") + " , ";
    temp += get(address, "country", "") + " ";
    temp += `(${get(address, "pinCode", "")})`;

    return temp;
  }

  return "No Address";
};
