import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  MdPersonOff,
  MdDelete,
  MdPerson,
  MdVisibility,
  MdMode,
} from "react-icons/md";
import ConfirmationModal from "../ConfirmationModal";
import { get } from "lodash";

export default function RenderActions(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    action,
    referenceId,
    handleAction,
    data,
    navigateTo,
    deleteAction,
  } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const renderAction = (action, Icon, color = "blue") => {
    return (
      <button
        onClick={() => {
          if (action === "editModal" || action === "blockModal")
            handleAction(referenceId, data);
          else if (!["delete", "block", "unblock"].includes(action)) {
            let route = navigateTo.replace("{{id}}", referenceId);
            navigate(route);
          } else setOpenDeleteModal(true);
        }}
        disabled={action === "blockModal" && get(data, "isBlocked", false)}
      >
        <Icon size={25} className={`mx-2 ${color}`} />
      </button>
    );
  };

  const deleteHandler = () => {
    dispatch(deleteAction({ id: referenceId }));
    setOpenDeleteModal(false);
  };
  return (
    <>
      <ConfirmationModal
        isOpen={openDeleteModal}
        action={action}
        onConfirm={deleteHandler}
        onReject={() => setOpenDeleteModal(false)}
      />
      {action === "edit"
        ? renderAction(action, MdMode, "hover:text-blue")
        : null}
      {action === "editModal"
        ? renderAction(action, MdMode, "hover:text-blue")
        : null}
      {action === "block" || action === "blockModal"
        ? renderAction(action, MdPersonOff, "hover:text-red")
        : null}
      {action === "unblock"
        ? renderAction(action, MdPerson, "hover:text-green")
        : null}
      {action === "delete"
        ? renderAction(action, MdDelete, "hover:text-red")
        : null}
      {action === "view"
        ? renderAction(action, MdVisibility, "hover:text-blue")
        : null}
    </>
  );
}
