import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormLayout from "../../components/FormLayout";
import {
  Dropdown,
  TextInput,
  Checkbox,
  Table,
  Button2,
} from "../../components";
import {
  ADD_VENDOR_AADHAR,
  ADD_VENDOR_ADDRESS,
  ADD_VENDOR_PAN,
  CREATE_VENDOR,
  FETCH_VENDOR,
  GET_ALL_BUSINESS_TYPES,
  UPDATE_VENDOR,
} from "../../store/actions";
import { get } from "lodash";
import { taskColumns } from "../../constants/tableHeaders";
import Address from "../../components/AddressAutoComplete";
import moment from "moment";
import Avatar from "../../components/Avatar";
import { toast } from "react-toastify";
import ImageInput from "../../components/ImageInput";
import {
  getBase64Image,
  isValidGSTNumber,
  isValid_Aadhaar_Number,
  isValid_Pan_Number,
  validateAddress,
  validateEmail,
  validatePhoneNumber,
} from "../../utils";

const style = {
  backgroundColor: "white",
  borderRadius: "10px",
  position: "relative",
  width: "90vw",
  overflow: "auto",
};

const allBusinessTypesOptions = [
  { label: "Private Limited", value: "privateLimited" },
  { label: "Public Limited", value: "publicLimited" },
  { label: "Unlimited Company", value: "unlimitedCompany" },
  { label: "Sole Proprietorship", value: "soleProprietorship" },
  { label: "Joint Hindu Family Business", value: "jointHinduFamilyBusiness" },
  { label: "Partnership", value: "partnership" },
  { label: "Cooperatives", value: "cooperatives" },
  {
    label: "Limited Liability Partnership",
    value: "limitedLiabilityPartnership",
  },
  { label: "Ngo", value: "Ngo" },
];

export default function VendorsForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const { selectedVendor } = useSelector((state) => state.vendor);
  const { allBusinessTypes } = useSelector((state) => state.businessType);

  useEffect(() => {
    return setGeneralData({});
  }, []);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
    return setGeneralData({});
  }, [action]);

  const [generalData, setGeneralData] = useState({});
  const [allBusinessCategories, setAllBusinessCategories] = useState([]);

  useEffect(() => {
    if (id && id !== "default") dispatch(FETCH_VENDOR.REQUEST({ id }));
  }, [id, action]);

  useEffect(() => {
    if (selectedVendor && id) {
      let tempGeneralData = { ...selectedVendor };
      setGeneralData(tempGeneralData);
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (
      allBusinessTypes &&
      Array.isArray(allBusinessTypes) &&
      allBusinessTypes.length
    ) {
      let tempAllBT = [];
      allBusinessTypes.map((bt, index) =>
        tempAllBT.push({ label: bt.title, value: bt._id })
      );
      setAllBusinessCategories(tempAllBT);
    } else {
      dispatch(GET_ALL_BUSINESS_TYPES.REQUEST());
    }
  }, []);

  useEffect(() => {
    if (
      allBusinessTypes &&
      Array.isArray(allBusinessTypes) &&
      allBusinessTypes.length
    ) {
      let tempAllBT = [];
      allBusinessTypes.map((bt, index) =>
        tempAllBT.push({ label: bt.title, value: bt._id })
      );
      setAllBusinessCategories(tempAllBT);
    }
  }, [allBusinessTypes]);

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  const validateVendorData = (vendorData) => {
    if (!validateEmail(vendorData.email)) {
      toast.error("Please enter valid email!");
      return false;
    }
    if (vendorData.name.length < 2) {
      toast.error("Name should be atleast 2 characters long!");
      return false;
    }
    if (!validatePhoneNumber(vendorData.contactNo)) {
      toast.error("Please enter correct contact number!");
      return false;
    }
    if (vendorData.storeName.length < 2) {
      toast.error("Store name should be atlease 2 characters long!");
      return false;
    }
    return true;
  };

  const handleClick = () => {
    let base64Logo = getBase64Image(get(generalData, "logo", ""));
    let vendorData = {
      name: get(generalData, "vendorId.name", ""),
      email: get(generalData, "vendorId.email", ""),
      contactNo: get(generalData, "contactNo", ""),
      alternateContactNo: get(generalData, "alternateContactNo", ""),
      storeName: get(generalData, "storeName", ""),
      gstNumber: get(generalData, "gstNumber", ""),
      logo: base64Logo,
      businessCategory: get(generalData, "businessCategory", ""),
      isRegisteredBusiness: get(generalData, "isRegisteredBusiness", false),
    };
    if (!validateVendorData(vendorData)) {
      return;
    }
    if (action === "create") {
      // create vendor
      if (!validatePhoneNumber(vendorData.alternateContactNo)) {
        toast.error("Please enter correct alternate phone number!");
        return;
      }
      dispatch(
        CREATE_VENDOR.REQUEST({
          value: { ...vendorData },
          navigate: navigate,
        })
      );
    } else if (action === "edit") {
      // edit vendor
      if (!isValidGSTNumber(vendorData.gstNumber)) {
        toast.error("Please enter correct GST number!");
        return;
      }
      if (!vendorData.businessCategory) {
        toast.error("Please select business category!");
        return;
      }
      if (vendorData.isRegisteredBusiness) {
        vendorData["businessType"] = get(generalData, "businessType", "");
        if (!vendorData.businessType) {
          toast.error("Select Business Type!");
          return;
        }
      }
      delete vendorData.alternateContactNo;
      vendorData.vendorId = selectedVendor?.vendorId?._id;
      dispatch(
        UPDATE_VENDOR.REQUEST({
          value: { ...vendorData },
          navigate: navigate,
        })
      );
    } else {
      return;
    }
  };

  const updateAddress = () => {
    if (validateAddress(get(generalData, "address", {}))) {
      if (get(generalData, "storeName", "").length < 2) {
        toast.error("Store name must be 2 characters long!");
        return;
      }
      dispatch(
        ADD_VENDOR_ADDRESS.REQUEST({
          value: {
            ...generalData.address,
            storeName: get(generalData, "storeName", ""),
            vendorId: get(selectedVendor, "vendorId._id", ""),
          },
          navigate: navigate,
        })
      );
    }
  };

  const addAadhar = () => {
    if (
      get(generalData, "aadharNumber.number", "") &&
      isValid_Aadhaar_Number(get(generalData, "aadharNumber.number", ""))
    ) {
      if (generalData.aadharNumber.front) {
        if (generalData.aadharNumber.back) {
          let base64Front = getBase64Image(
            get(generalData, "aadharNumber.front", "")
          );
          let base64Back = getBase64Image(
            get(generalData, "aadharNumber.back", "")
          );
          dispatch(
            ADD_VENDOR_AADHAR.REQUEST({
              value: {
                aadharNumber: generalData.aadharNumber.number,
                front: base64Front,
                back: base64Back,
                vendorId: get(generalData, "vendorId._id", ""),
              },
              navigate: navigate,
            })
          );
        } else {
          toast.error("Please select aadhar back!");
        }
      } else {
        toast.error("Please select aadhar front!");
      }
    } else {
      toast.error("Please add valid aadhar number!");
    }
  };

  const addPan = () => {
    if (
      get(generalData, "panNumber.number", "") &&
      isValid_Pan_Number(get(generalData, "panNumber.number", ""))
    ) {
      if (get(generalData, "panNumber.picture", "")) {
        let base64Pan = getBase64Image(
          get(generalData, "panNumber.picture", "")
        );
        dispatch(
          ADD_VENDOR_PAN.REQUEST({
            value: {
              panNumber: generalData.panNumber.number,
              pan: base64Pan,
              vendorId: get(generalData, "vendorId._id", ""),
            },
            navigate: navigate,
          })
        );
      } else {
        toast.error("Please select PAN image!");
      }
    } else {
      toast.error("Please add PAN number!");
    }
  };

  const handleChange = (e) => {
    let updatedGenetalData = { ...generalData };
    updatedGenetalData[e.target.name] = e.target.value;
    setGeneralData(updatedGenetalData);
  };

  const handleEditClick = () => {
    let url = window.location.pathname;
    url = url.replace("view", "edit");
    navigate(`${url}`);
  };

  return (
    <div className="">
      {readOnly && (
        <section className="flex justify-between items-center ml-4">
          <section>
            <span className="text-2xl">Vendor</span>
            {generalData && generalData.vendorId?.isBlocked && (
              <span
                className="mx-2 px-4 py-1 rounded-xl text-white text-xs"
                style={{ backgroundColor: "#BF2A0A" }}
              >
                Blocked
              </span>
            )}
          </section>
          {action === "view" && (
            <Button2 title={"Edit"} handleClick={handleEditClick} />
          )}
        </section>
      )}
      {readOnly && generalData.vendorId?.lastActive && (
        <span className="text-zinc-500 text-sm ml-6">{`Last active : ${moment(
          `${generalData.vendorId?.lastActive}`
        ).format("MMMM Do YYYY, h:mm:ss a")}`}</span>
      )}
      <FormLayout title={action === "create" ? "Create Vendor" : "General"}>
        <div>
          {!["create"].includes(action) && (
            <div className="w-full flex justify-center items-center mb-10">
              <Avatar
                src={get(generalData, "logo", " ")}
                name={get(generalData, "vendorId.name", " ")[0]}
                bgColor={"#8F00FF"}
                textColor={"white"}
                onChange={(val) => {
                  let tempGenData = { ...generalData };
                  tempGenData["logo"] = val;
                  setGeneralData(tempGenData);
                }}
                readOnly={readOnly}
              />
            </div>
          )}
          <div
            className={`mx-2 grid ${
              action === "create"
                ? "grid-rows-5 sm:grid-rows-3"
                : "grid-rows-7 sm:grid-rows-4"
            } grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Name"}
              name={"name"}
              type={"text"}
              value={get(generalData, "vendorId.name", "")}
              onChange={(e) => {
                let tempVal = {
                  ...generalData.vendorId,
                  [e.target.name]: e.target.value,
                };
                let tempE = { target: { name: "vendorId", value: tempVal } };
                handleChange(tempE);
              }}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter name"}
            />
            <TextInput
              title={"Email"}
              name={"email"}
              type={"text"}
              value={get(generalData, "vendorId.email", "")}
              onChange={(e) => {
                let tempVal = {
                  ...generalData.vendorId,
                  [e.target.name]: e.target.value,
                };
                let tempE = { target: { name: "vendorId", value: tempVal } };
                handleChange(tempE);
              }}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter email"}
            />
            <TextInput
              title={"Contact No"}
              name={"contactNo"}
              type={"text"}
              value={get(generalData, "contactNo", "")}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter contactNo"}
            />
            {action !== "create" && (
              <TextInput
                title={"GST No"}
                name={"gstNumber"}
                type={"text"}
                value={get(generalData, "gstNumber", "")}
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter gstNumber"}
              />
            )}
            {action === "create" && (
              <TextInput
                title={"Alternate Contact No"}
                name={"alternateContactNo"}
                type={"text"}
                value={get(generalData, "alternateContactNo", "")}
                onChange={handleChange}
                placeholder={""}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={"Please enter alternateContactNo"}
              />
            )}
            <TextInput
              title={"Store Name"}
              name={"storeName"}
              type={"text"}
              value={get(generalData, "storeName", "")}
              onChange={handleChange}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter storeName"}
            />
            {["view", "edit"].includes(action) && (
              <Dropdown
                title={"Business Category"}
                name={"businessCategory"}
                value={{
                  value: get(generalData, "businessCategory", ""),
                  label: "",
                }}
                onChange={(e) => {
                  let tempE = {
                    target: { name: "businessCategory", value: e.value },
                  };
                  handleChange(tempE);
                }}
                placeholder={"Business Category"}
                showError={false}
                isReadOnly={readOnly}
                errorMessage={""}
                options={allBusinessCategories}
              />
            )}
            {["view", "edit"].includes(action) && (
              <span className="w-full flex">
                <Checkbox
                  title={"Is Registered Business"}
                  name={"isRegisteredBusiness"}
                  value={get(generalData, "isRegisteredBusiness", false)}
                  onChange={() => {
                    let tempData = { ...generalData };
                    let val = get(generalData, "isRegisteredBusiness", false);
                    tempData["isRegisteredBusiness"] = !val;
                    setGeneralData(tempData);
                  }}
                  placeholder={""}
                  reverse={true}
                  showError={false}
                  isReadOnly={readOnly}
                  errorMessage={""}
                />
              </span>
            )}
            {["view", "edit"].includes(action) &&
              get(generalData, "isRegisteredBusiness", false) && (
                <Dropdown
                  title={"Business Type"}
                  name={"businessType"}
                  value={{
                    value: get(generalData, "businessType", ""),
                    label: "",
                  }}
                  onChange={(e) => {
                    let tempE = {
                      target: { name: "businessType", value: e.value },
                    };
                    handleChange(tempE);
                  }}
                  placeholder={"Business Type"}
                  showError={false}
                  isReadOnly={readOnly}
                  errorMessage={""}
                  options={allBusinessTypesOptions}
                />
              )}
          </div>
        </div>
        {!readOnly && <Button2 title={"Submit"} handleClick={handleClick} />}
      </FormLayout>
      {action !== "create" && (
        <Address
          address={get(generalData, "address", {})}
          readOnly={readOnly}
          handleChange={(data) => {
            let tempGeneralData = { ...generalData };
            tempGeneralData["address"] = { ...data };
            setGeneralData(tempGeneralData);
          }}
          updateAddress={(data) => {
            // add new or update address
            updateAddress();
          }}
          getPlaceId={true}
        />
      )}
      {action !== "create" && (
        <div className="flex justify-center	items-center my-4">
          <div style={style}>
            <iframe
              src={`https://maps.google.com/maps?q=${get(
                generalData,
                "address.geolocation.coordinates[1]",
                "76.7751434"
              )},${get(
                generalData,
                "address.geolocation.coordinates[0]",
                "76.7751434"
              )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
              width={(window.innerWidth / 100) * 90}
              height={(window.innerHeight / 100) * 40}
            />
          </div>
        </div>
      )}
      {!["create"].includes(action) &&
        (generalData?.aadharNumber?.front ||
          generalData?.aadharNumber?.back ||
          action === "edit") && (
          <FormLayout title={"Aadhar Details"}>
            <TextInput
              title={"Aadhar Number"}
              name={"aadharNumber"}
              type={"text"}
              value={get(generalData, "aadharNumber.number", "")}
              onChange={(e) => {
                let tempE = {
                  target: {
                    name: "aadharNumber",
                    value: {
                      ...generalData.aadharNumber,
                      number: e.target.value,
                    },
                  },
                };
                handleChange(tempE);
              }}
              placeholder={"Aadhar Number"}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter aadhar number"}
            />
            <div
              className={`mx-2 grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-4 my-2 mb-6`}
            >
              {(generalData?.aadharNumber?.front || action === "edit") && (
                <ImageInput
                  id={"aadhar_front"}
                  title={"Select Aadhar front"}
                  src={get(generalData, "aadharNumber.front", "")}
                  onChange={(val) => {
                    let tempData = { ...generalData };
                    tempData["aadharNumber"] = { ...generalData.aadharNumber };
                    tempData.aadharNumber["front"] = val;
                    setGeneralData(tempData);
                  }}
                  readOnly={readOnly}
                  styles={{ height: "250px", maxWidth: "393px" }}
                />
              )}
              {(generalData?.aadharNumber?.back || action === "edit") && (
                <ImageInput
                  id={"aadhar_back"}
                  title={"Select Aadhar back"}
                  src={get(generalData, "aadharNumber.back", "")}
                  onChange={(val) => {
                    let tempData = { ...generalData };
                    tempData["aadharNumber"] = { ...generalData.aadharNumber };
                    tempData.aadharNumber["back"] = val;
                    setGeneralData(tempData);
                  }}
                  readOnly={readOnly}
                  styles={{ height: "250px", maxWidth: "393px" }}
                />
              )}
            </div>
            <Button2 title={"Submit"} handleClick={addAadhar} />
          </FormLayout>
        )}
      {!["create"].includes(action) &&
        (generalData?.panNumber || action === "edit") && (
          <FormLayout title={"PAN number"}>
            <div
              className={`mx-2 flex flex-col sm:grid sm:grid-rows-1 sm:grid-flow-col gap-4 my-2 mb-6`}
            >
              <span className="w-full mr-2">
                <TextInput
                  title={"PAN Number"}
                  name={"number"}
                  type={"text"}
                  value={get(generalData, "panNumber.number", "")}
                  onChange={(e) => {
                    let tempE = {
                      target: {
                        name: "panNumber",
                        value: {
                          ...generalData.panNumber,
                          number: e.target.value,
                        },
                      },
                    };
                    handleChange(tempE);
                  }}
                  placeholder={"PAN Number"}
                  showError={false}
                  isReadOnly={readOnly}
                  errorMessage={"Please enter PAN number"}
                />
              </span>
              <ImageInput
                id={"PAN"}
                title={"Select PAN"}
                src={get(generalData, "panNumber.picture", "")}
                onChange={(val) => {
                  let tempData = { ...generalData };
                  tempData["panNumber"] = { ...generalData.panNumber };
                  tempData.panNumber["picture"] = val;
                  setGeneralData(tempData);
                }}
                readOnly={readOnly}
                styles={{
                  minHeight: "200px",
                  maxHeight: "250px",
                  maxWidth: "393px",
                  minWidth: "250px",
                }}
              />
            </div>
            <Button2 title={"Submit"} handleClick={addPan} />
          </FormLayout>
        )}
      {readOnly && (
        <FormLayout title={"Active Orders"}>
          <Table
            darkTheme={darkTheme}
            columns={taskColumns}
            data={get(generalData, "activeOrders", [])}
            paginationData={{}}
            onChangePageNumber={() => {}}
            onChangePageSize={() => {}}
            totalPages={1}
          />
        </FormLayout>
      )}
      {readOnly && (
        <FormLayout title={"Completed Orders"}>
          <Table
            darkTheme={darkTheme}
            columns={taskColumns}
            data={get(generalData, "completedOrders", [])}
            paginationData={{}}
            onChangePageNumber={() => {}}
            onChangePageSize={() => {}}
            totalPages={1}
          />
        </FormLayout>
      )}
    </div>
  );
}
