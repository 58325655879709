import { get, isNil } from "lodash";
import React, { useCallback, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
// import {
//   createZoneRequest,
//   fetchZoneRequest,
//   updateZoneRequest,
// } from "../../store/actions";
// import CustomTextInput from "../../components/CustomTextInput";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import { getMiddle } from "../../utils";
import { Button2, TextInput } from "../../components";
import { CREATE_ZONE, GET_ONE_ZONE, UPDATE_ZONE } from "../../store/actions";
// import EditBar from "../../components/EditBar";

export default function ZonesForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action, code } = useParams();
  const { selectedZone } = useSelector((state) => state.zones);
  const [zoneData, setZoneData] = useState({});
  const [path, setPath] = useState([]);
  const [readOnly, setReadOnly] = useState(true);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  useEffect(() => {
    if (action !== "create") dispatch(GET_ONE_ZONE.REQUEST({ id: code }));
  }, []);

  useEffect(() => {
    if (
      action !== "create" &&
      !isNil(selectedZone) &&
      typeof selectedZone === "object"
    ) {
      let data = get(selectedZone, "geolocation", {});
      setZoneData(data);
      if (data && !isNil(data.coordinates)) {
        let pathData = get(data, "coordinates[0]", []).map((point) => {
          return {
            lat: point[1],
            lng: point[0],
          };
        });
        setPath(pathData);
      }
    }
  }, [selectedZone]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onGeneralChange = (e) => {
    setZoneData({
      ...zoneData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {
    let points = path.map((point) => [point.lng, point.lat]);
    points.push(points[0]);
    let data = {};

    if (action === "edit") {
      data = {
        code: get(zoneData, "code", ""),
        name: get(zoneData, "name", ""),
        points: [[...points]],
      };
      data.id = get(selectedZone, "_id", "");
      dispatch(UPDATE_ZONE.REQUEST({ data: data, navigate: navigate }));
    } else {
      data = {
        code: get(zoneData, "code", ""),
        name: get(zoneData, "name", ""),
        points: [...points],
      };
      dispatch(CREATE_ZONE.REQUEST({ data: data, navigate: navigate }));
    }
  };

  return (
    <div>
      <div>
        <div>
          <TextInput
            title={"Name"}
            name={"name"}
            type={"text"}
            value={get(zoneData, "name", "")}
            onChange={onGeneralChange}
            placeholder={"Name"}
            showError={false}
            isReadOnly={action === "view"}
            errorMessage={"Please enter name"}
          />
          <TextInput
            title={"Code"}
            name={"code"}
            type={"text"}
            value={get(zoneData, "code", "")}
            onChange={onGeneralChange}
            placeholder={"Code"}
            showError={false}
            isReadOnly={action === "view"}
            errorMessage={"Please enter code"}
          />
        </div>
      </div>
      <div>
        <div style={{ height: "50vh" }}>
          <GoogleMap
            mapContainerClassName="App-map"
            center={{
              lat:
                path.length === 0
                  ? parseFloat(43.6532)
                  : getMiddle("lat", path),
              lng:
                path.length === 0
                  ? parseFloat(-79.3832)
                  : getMiddle("lng", path),
            }}
            zoom={11}
            version="weekly"
            on
            mapContainerStyle={{ height: "100%" }}
            options={{ gestureHandling: action === "view" ? "none" : "all" }}
            onClick={(event) => {
              if (!readOnly) {
                setPath([
                  ...path,
                  { lng: event.latLng.lng(), lat: event.latLng.lat() },
                ]);
              }
            }}
          >
            <Polygon
              // Make the Polygon editable / draggable
              editable={action !== "view"}
              draggable={false}
              path={path}
              onMouseUp={onEdit}
              onLoad={onLoad}
              onUnmount={onUnmount}
            />
          </GoogleMap>
        </div>
      </div>
      {action !== "view" && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <span className="mr-2">
              <Button2 title={"Clear Map"} handleClick={() => setPath([])} />
            </span>
            <Button2 title={"Submit"} handleClick={handleSubmit} />
          </div>
        </div>
      )}
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { Table, TextInput } from "../../components";
// import FormLayout from "../../components/FormLayout";
// import { vendorColumns } from "../../constants/tableHeaders";
// import { GET_ONE_BUSINESS_TYPE } from "../../store/actions";

// export default function ProductsForm() {
//   const { action, id } = useParams();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [readOnly, setReadOnly] = useState(true);
//   const { selectedBusinessTypes } = useSelector((state) => state.businessType);

//   useEffect(() => {
//     if (action === "view") setReadOnly(true);
//     else setReadOnly(false);
//   }, [action]);

//   const [data, setData] = useState({});
//   const [vendorPaginationData, setVendorPaginationData] = useState({
//     page: 1,
//     perPage: 5,
//   });

//   useEffect(() => {
//     if (id && id !== "default") dispatch(GET_ONE_BUSINESS_TYPE.REQUEST({ id }));
//   }, [id]);

//   useEffect(() => {
//     if (selectedBusinessTypes && id) {
//       setData(selectedBusinessTypes);
//     }
//   }, [selectedBusinessTypes]);

//   const handleChange = (e) => {
//     let updatedData = { ...data };
//     updatedData[e.target.name] = e.target.value;
//     setData(updatedData);
//   };
//   const onChangePageNumber = (val) => {
//     dispatch(setVendorPaginationData({ ...vendorPaginationData, page: val }));
//   };

//   const onChangePageSize = (val) => {
//     dispatch(
//       setVendorPaginationData({ ...vendorPaginationData, perPage: val })
//     );
//   };
//   const darkTheme = useSelector((state) => state.auth.darkTheme);

//   return (
//     <div className="">
//       <FormLayout title={"General"}>
//         <div>
//           <div
//             className={`div div-rows-2 sm:div-rows-1 div-flow-col gap-4 my-2`}
//           >
//             <TextInput
//               title={"Title"}
//               name={"title"}
//               type={"text"}
//               value={data?.title}
//               onChange={handleChange}
//               placeholder={""}
//               showError={false}
//               isReadOnly={readOnly}
//               errorMessage={"Please enter title"}
//             />
//           </div>
//         </div>
//       </FormLayout>
//       {readOnly && (
//         <FormLayout title={"Vendors"}>
//           <Table
//             darkTheme={darkTheme}
//             columns={vendorColumns}
//             data={data?.vendors || []}
//             paginationData={vendorPaginationData}
//             onChangePageNumber={onChangePageNumber}
//             onChangePageSize={onChangePageSize}
//             totalPages={
//               Array.isArray(data?.vendors)
//                 ? data?.vendors?.length / vendorPaginationData.perPage
//                 : 1
//             }
//           />
//         </FormLayout>
//       )}
//     </div>
//   );
// }
