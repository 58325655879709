import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button2, Dropdown, Table, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import { jobTypes } from "../../constants/common";
import { adminColumns } from "../../constants/tableHeaders";
import {
  CREATE_JOB_TYPE,
  GET_ONE_JOB_TYPE,
  UPDATE_JOB_TYPE,
} from "../../store/actions";

const allJobTypes = [
  { label: jobTypes.fullTime, value: jobTypes.fullTime },
  { label: jobTypes.partTime, value: jobTypes.partTime },
];

export default function ProductsForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const { selectedJobTypes } = useSelector((state) => state.jobType);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  const [data, setData] = useState({});
  const [agentPaginationData, setAgentPaginationData] = useState({
    page: 1,
    perPage: 5,
  });

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_JOB_TYPE.REQUEST({ id }));
  }, [id]);

  useEffect(() => {
    if (selectedJobTypes && id) {
      setData(selectedJobTypes);
    }
  }, [selectedJobTypes]);

  const handleChange = (e) => {
    let updatedData = { ...data };
    updatedData[e.target.name] = e.target.value;
    setData(updatedData);
  };
  const onChangePageNumber = (val) => {
    dispatch(setAgentPaginationData({ ...agentPaginationData, page: val }));
  };

  const onChangePageSize = (val) => {
    dispatch(setAgentPaginationData({ ...agentPaginationData, perPage: val }));
  };
  const darkTheme = useSelector((state) => state.auth.darkTheme);

  const onCreateJobType = () => {
    console.log(data);
    let jobTypeData = { ...data };
    delete jobTypeData._id;
    // delete jobTypeData.type;
    if (action === "create") {
      dispatch(CREATE_JOB_TYPE.REQUEST(jobTypeData));
      navigate("/jobType");
    } else {
      dispatch(
        UPDATE_JOB_TYPE.REQUEST({
          value: jobTypeData,
          navigate: navigate,
          id: id,
        })
      );
    }
  };

  return (
    <div className="">
      <FormLayout title={"General"}>
        <div>
          <div
            className={`grid grid-rows-6 sm:grid-rows-3 grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Title"}
              name={"title"}
              type={"text"}
              value={data?.title}
              onChange={handleChange}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter title"}
            />
            <Dropdown
              title={"Type"}
              name={"type"}
              value={{
                value: get(data, "type", ""),
                label: get(data, "type", ""),
              }}
              onChange={(e) => {
                let tempE = { target: { name: "type", value: e.value } };
                handleChange(tempE);
              }}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={""}
              options={allJobTypes}
            />
            <TextInput
              title={"Target Hours"}
              name={"targetHours"}
              type={"text"}
              value={data?.targetHours}
              onChange={handleChange}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter targetHours"}
            />
            <TextInput
              title={"Target Days"}
              name={"targetDays"}
              type={"text"}
              value={data?.targetDays}
              onChange={handleChange}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter targetDays"}
            />
            <TextInput
              title={"Expected Earning"}
              name={"expectedEarning"}
              type={"text"}
              value={data?.expectedEarning}
              onChange={handleChange}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter expectedEarning"}
            />
            <TextInput
              title={"Description"}
              name={"description"}
              type={"text"}
              value={data?.description}
              onChange={handleChange}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter description"}
            />
          </div>
        </div>
      </FormLayout>
      {readOnly && (
        <FormLayout title={"Agents"}>
          <Table
            darkTheme={darkTheme}
            columns={adminColumns}
            data={data?.agents || []}
            paginationData={agentPaginationData}
            onChangePageNumber={onChangePageNumber}
            onChangePageSize={onChangePageSize}
            totalPages={
              Array.isArray(data?.agents)
                ? data?.agents?.length / agentPaginationData.perPage
                : 1
            }
          />
        </FormLayout>
      )}
      {!readOnly && (
        <span className="mx-4">
          <Button2 title={"Submit"} handleClick={onCreateJobType} />
        </span>
      )}
    </div>
  );
}
