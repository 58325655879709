import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const getAllTaskService = async (data) => {
  try {
    let URL = apiURL + "task/getAll";
    let config = {
      params: {
        page: data.page,
        perPage: data.perPage,
        status: data.status,
      },
    };
    let res = await authRequest(URL, "GET", {}, config);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const createTaskService = async (data) => {
  try {
    let URL = apiURL + "task/create";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateTaskService = async (data) => {
  try {
    let URL = apiURL + "task/update";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const fetchTaskService = async (data) => {
  try {
    let URL = apiURL + "task/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const cancelTaskService = async (data) => {
  try {
    let URL = apiURL + "task/cancel/" + data.id;
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

//TODO: Need to add calls for finding and assigning agents
