import { get } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Layout } from "../components";
import { routes } from "../constants/routes";
import { checkTokenExist, onMessageListener } from "../firebase";
import NotificationSound from "../assets/sounds/notification.wav";
import { isJson } from "../utils";
import { actionsMap, updateDeviceToken } from "../store/actions";

const Routing = () => {
  const audioPlayer = useRef(null);
  const { isLoggedIn, deviceToken, notificationRegistered } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  useEffect(() => {
    checkTokenExist();

    onMessageListener()
      .then((payload) => {
        const { actionName, type, data, sound = "true" } = get(
          payload,
          "data",
          {}
        );
        if (payload && payload.notification) {
          if (audioPlayer?.current && sound === "true")
            audioPlayer.current.play();
          toast.info(get(payload, "notification.body", ""));
        }

        if (payload && payload.data) {
          if (type === "action") {
            let actionData = null;
            if (data !== "")
              actionData = isJson(data) ? JSON.parse(data) : data;
            dispatch(actionsMap[actionName](actionData));
          }
        }
      })
      .catch((err) => console.log("failed: ", err));
  }, []);

  useEffect(() => {
    if (isLoggedIn && deviceToken && !notificationRegistered) {
      dispatch(updateDeviceToken.REQUEST({ deviceToken }));
    }
  }, [isLoggedIn]);

  const PrivateRoute = ({ ChildComponent }) => {
    return isLoggedIn ? <ChildComponent /> : <Navigate to="/" />;
  };

  return (
    <BrowserRouter>
      <Layout>
        <ToastContainer
          autoClose={2000}
          position="bottom-center"
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="light"
        />
        <audio ref={audioPlayer} src={NotificationSound} />
        <Routes>
          {routes.map((route, index) => {
            let { path, Component, isPublic, exact } = route;
            return (
              <Route
                key={index}
                exact={exact}
                path={path}
                element={
                  isPublic ? (
                    <Component />
                  ) : (
                    <PrivateRoute ChildComponent={Component} />
                  )
                }
              />
            );
          })}
          <Route path="/404" element={<div>Not found!</div>} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default Routing;
