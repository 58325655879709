import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  loginWithEmailService,
  updateDeviceTokenService,
  logoutService,
  forgotPasswordService,
  resetPasswordService,
} from "../../services/auth";
import { toast } from "react-toastify";
import {
  FORGOT_PASSWORD,
  LOGIN_WITH_EMAIL,
  logout,
  RESET_PASSWORD,
  updateDeviceToken,
} from "../actions";

export function* loginWithEmailSaga(action) {
  try {
    let res = yield call(loginWithEmailService, action.payload);
    if (res.success) {
      yield put(LOGIN_WITH_EMAIL.SUCCESS(res.data));
      if (action.navigate) action.navigate("/orders");
    } else {
      yield put(LOGIN_WITH_EMAIL.FAILURE(res));
      toast.error("Incorrect Email/Password!");
    }
  } catch (e) {
    yield put(
      LOGIN_WITH_EMAIL.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Incorrect Email/Password!");
  }
}
export function* forgotPasswordSaga(action) {
  try {
    let res = yield call(forgotPasswordService, action.payload);
    if (res.success) {
      yield put(FORGOT_PASSWORD.SUCCESS(res.data));
      toast.success("Email sent!");
    } else {
      yield put(FORGOT_PASSWORD.FAILURE(res));
      toast.error("");
    }
  } catch (e) {
    yield put(
      FORGOT_PASSWORD.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Incorrect Email!");
  }
}
export function* resetPasswordSaga(action) {
  try {
    let res = yield call(resetPasswordService, action.payload);
    if (res.success) {
      yield put(RESET_PASSWORD.SUCCESS(res.data));
      // if (action.navigate) action.navigate("/");
      toast.success("Password reset successful!");
    } else {
      yield put(RESET_PASSWORD.FAILURE(res));
      toast.error("");
    }
  } catch (e) {
    yield put(
      RESET_PASSWORD.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("");
  }
}

export function* logoutSaga(action) {
  try {
    const res = yield call(logoutService, {
      deviceToken: action?.payload?.deviceToken,
      email: action?.payload?.email,
    });
    if (res.success) {
      yield put(logout.SUCCESS());
    } else {
      yield put(logout.FAILURE());
    }
  } catch (e) {
    yield put(logout.FAILURE());
    console.error(e);
  }
}

export function* updateDeviceTokenSaga(action) {
  try {
    let res = yield call(updateDeviceTokenService, action.payload);
    if (res.success) {
      yield put(updateDeviceToken.SUCCESS(res.data));
    } else {
      yield put(updateDeviceToken.FAILURE(res));
    }
  } catch (e) {
    yield put(
      updateDeviceToken.FAILURE({
        success: false,
        message: e.message,
      })
    );
  }
}

const authSaga = () => [
  takeLatest(ActionTypes.Auth.LOGIN_WITH_EMAIL.REQUEST, loginWithEmailSaga),
  takeLatest(
    ActionTypes.Auth.UPDATE_DEVICE_TOKEN.REQUEST,
    updateDeviceTokenSaga
  ),
  takeLatest(ActionTypes.Auth.LOGOUT.REQUEST, logoutSaga),
  takeLatest(ActionTypes.Auth.FORGOT_PASSWORD.REQUEST, forgotPasswordSaga),
  takeLatest(ActionTypes.Auth.RESET_PASSWORD.REQUEST, resetPasswordSaga),
];
export default authSaga();
