import { actionTypeCreator } from "../../utils";

const Auth = {
  TOGGLE_THEME: "TOGGLE_THEME",
  FORGOT_PASSWORD: actionTypeCreator("FORGOT_PASSWORD"),
  RESET_PASSWORD: actionTypeCreator("RESET_PASSWORD"),
  LOGIN_WITH_EMAIL: actionTypeCreator("LOGIN_WITH_EMAIL"),
  SAVE_DEVICE_TOKEN: "SAVE_DEIVCE_TOKEN",
  UPDATE_DEVICE_TOKEN: actionTypeCreator("UPDATE_DEVICE_TOKEN"),
  LOGOUT: actionTypeCreator("LOGOUT"),
};

const Admin = {
  CREATE_ADMIN: actionTypeCreator("CREATE_ADMIN"),
  VERIFY_ADMIN: actionTypeCreator("VERIFY_ADMIN"),
  GET_ONE_ADMIN: actionTypeCreator("GET_ONE_ADMIN"),
  GET_ALL_ADMINS: actionTypeCreator("GET_ALL_ADMINS"),
  UPDATE_PROFILE_BY_ADMIN: actionTypeCreator("UPDATE_PROFILE_BY_ADMIN"),
  BLOCK_ADMIN: actionTypeCreator("BLOCK_ADMIN"),
  SET_ACCESS_CONTROL_ADMIN_PAGINATION: "SET_ACCESS_CONTROL_ADMIN_PAGINATION",
};

const Vendors = {
  GET_ALL_VENDORS: actionTypeCreator("GET_ALL_VENDORS"),
  CREATE_VENDOR: actionTypeCreator("CREATE_VENDOR"),
  FETCH_VENDOR: actionTypeCreator("FETCH_VENDOR"),
  UPDATE_VENDOR: actionTypeCreator("UPDATE_VENDOR"),
  ADD_VENDOR_AADHAR: actionTypeCreator("ADD_VENDOR_AADHAR"),
  ADD_VENDOR_PAN: actionTypeCreator("ADD_VENDOR_PAN"),
  ADD_VENDOR_ADDRESS: actionTypeCreator("ADD_VENDOR_ADDRESS"),
  BLOCK_VENDOR: actionTypeCreator("BLOCK_VENDOR"),
  SET_VENDOR_PAGINATION: "SET_VENDOR_PAGINATION",
};

const Agents = {
  GET_ALL_AGENTS: actionTypeCreator("GET_ALL_AGENTS"),
  CREATE_AGENT: actionTypeCreator("CREATE_AGENT"),
  FETCH_AGENT: actionTypeCreator("FETCH_AGENT"),
  UPDATE_AGENT: actionTypeCreator("UPDATE_AGENT"),
  ADD_AGENT_AADHAR: actionTypeCreator("ADD_AGENT_AADHAR"),
  ADD_AGENT_PAN: actionTypeCreator("ADD_AGENT_PAN"),
  ADD_AGENT_BANK_DETAILS: actionTypeCreator("ADD_AGENT_BANK_DETAILS"),
  ADD_AGENT_EMERGENCY_CONTACT: actionTypeCreator("ADD_AGENT_EMERGENCY_CONTACT"),
  UPDATE_AGENT_JOB_TYPE: actionTypeCreator("UPDATE_AGENT_JOB_TYPE"),
  UPDATE_AGENT_OPERATIONAL_CITY: actionTypeCreator(
    "UPDATE_AGENT_OPERATIONAL_CITY"
  ),
  UPDATE_AGENT_VEHICLE_TYPE: actionTypeCreator("UPDATE_AGENT_VEHICLE_TYPE"),
  UPDATE_AGENT_ADDRESS: actionTypeCreator("UPDATE_AGENT_ADDRESS"),
  BLOCK_AGENT: actionTypeCreator("BLOCK_AGENT"),
  SET_AGENT_PAGINATION: "SET_AGENT_PAGINATION",
};

const Tasks = {
  CREATE_TASK: actionTypeCreator("CREATE_TASK"),
  UPDATE_TASK: actionTypeCreator("UPDATE_TASK"),
  GET_ONE_TASK: actionTypeCreator("GET_ONE_TASK"),
  GET_ALL_TASKS: actionTypeCreator("GET_ALL_TASKS"),
  CANCEL_TASK: actionTypeCreator("CANCEL_TASK"),
  SET_TASKS_PAGINATION: "SET_TASKS_PAGINATION",
};

const Zones = {
  CREATE_ZONE: actionTypeCreator("CREATE_ZONE"),
  UPDATE_ZONE: actionTypeCreator("UPDATE_ZONE"),
  GET_ONE_ZONE: actionTypeCreator("GET_ONE_ZONE"),
  GET_ALL_ZONES: actionTypeCreator("GET_ALL_ZONES"),
  DELETE_ZONE: actionTypeCreator("DELETE_ZONE"),
  SET_ZONES_PAGINATION: "SET_ZONES_PAGINATION",
};

const JobTypes = {
  CREATE_JOB_TYPE: actionTypeCreator("CREATE_JOB_TYPE"),
  UPDATE_JOB_TYPE: actionTypeCreator("UPDATE_JOB_TYPE"),
  GET_ONE_JOB_TYPE: actionTypeCreator("GET_ONE_JOB_TYPE"),
  GET_ALL_JOB_TYPES: actionTypeCreator("GET_ALL_JOB_TYPES"),
  DELETE_JOB_TYPE: actionTypeCreator("DELETE_JOB_TYPE"),
  SET_JOB_TYPES_PAGINATION: "SET_JOB_TYPES_PAGINATION",
};

const BusinessTypes = {
  CREATE_BUSINESS_TYPE: actionTypeCreator("CREATE_BUSINESS_TYPE"),
  UPDATE_BUSINESS_TYPE: actionTypeCreator("UPDATE_BUSINESS_TYPE"),
  GET_ONE_BUSINESS_TYPE: actionTypeCreator("GET_ONE_BUSINESS_TYPE"),
  GET_ALL_BUSINESS_TYPES: actionTypeCreator("GET_ALL_BUSINESS_TYPES"),
  DELETE_BUSINESS_TYPE: actionTypeCreator("DELETE_BUSINESS_TYPE"),
  SET_BUSINESS_TYPE_PAGINATION: "SET_BUSINESS_TYPE_PAGINATION",
};

const OperationalCity = {
  CREATE_OPERATIONAL_CITY: actionTypeCreator("CREATE_OPERATIONAL_CITY"),
  UPDATE_OPERATIONAL_CITY: actionTypeCreator("UPDATE_OPERATIONAL_CITY"),
  FETCH_OPERATIONAL_CITY: actionTypeCreator("FETCH_OPERATIONAL_CITY"),
  GET_ALL_OPERATIONAL_CITIES: actionTypeCreator("GET_ALL_OPERATIONAL_CITIES"),
  DELETE_OPERATIONAL_CITY: actionTypeCreator("DELETE_OPERATIONAL_CITY"),
  SET_OPERATIONAL_CITY_PAGINATION: "SET_OPERATIONAL_CITY_PAGINATION",
};

const ActionTypes = {
  Auth,
  Admin,
  Tasks,
  Vendors,
  Agents,
  JobTypes,
  BusinessTypes,
  OperationalCity,
  Zones,
};

export default ActionTypes;
