import ActionTypes from "../actions/types";

const agentInitialState = {
  allAgents: [],
  selectedAgent: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const agentReducer = (state = agentInitialState, action) => {
  switch (action.type) {
    case ActionTypes.Agents.SET_AGENT_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.Agents.GET_ALL_AGENTS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Agents.GET_ALL_AGENTS.SUCCESS:
      return {
        ...state,
        loading: false,
        allAgents: action.payload.agents,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Agents.GET_ALL_AGENTS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        pages: 1,
        allAgents: [],
      };

    case ActionTypes.Agents.CREATE_AGENT.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Agents.CREATE_AGENT.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Agents.CREATE_AGENT.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.Agents.FETCH_AGENT.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Agents.FETCH_AGENT.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedAgent: action.payload,
        error: null,
      };
    case ActionTypes.Agents.FETCH_AGENT.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.Agents.UPDATE_AGENT.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Agents.UPDATE_AGENT.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        // selectedAgents: action.payload,
        error: null,
      };
    case ActionTypes.Agents.UPDATE_AGENT.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Agents.BLOCK_AGENT.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Agents.BLOCK_AGENT.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Agents.BLOCK_AGENT.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default agentReducer;
