import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const Dropdown = ({
  type,
  value,
  onChange,
  placeholder,
  showError,
  isReadOnly,
  errorMessage,
  title,
  options,
  isClearable,
  isSearchable,
  name,
  inputProps,
}) => {
  const darkTheme = useSelector((state) => state.auth.darkTheme);
  const [val, setVal] = useState(value);
  const customStyles = {
    control: () => ({
      backgroundColor: "transparent",
      display: "flex",
      color: "black",
    }),
  };

  useEffect(() => {
    if (
      (val.value && !val.label) ||
      (val.value !== value.value && !value.label)
    ) {
      for (let i in options) {
        if (options[i].value === value.value) {
          setVal(options[i]);
          break;
        }
      }
    }
  }, [value]);
  return (
    <section className={`flex flex-col mb-4`}>
      <h2
        className={`${
          darkTheme ? "text-white" : "text-text_inp_title"
        } cursor-default text-sm`}
      >
        {title}
      </h2>
      <Select
        name={name}
        defaultValue={val}
        value={val}
        onChange={onChange}
        options={options}
        className={`mt-1 pt-1 rounded-md bg-transparent ${
          !darkTheme ? "hover:border-black" : ""
        } border`}
        isDisabled={isReadOnly}
        isClearable={isClearable}
        isSearchable={isSearchable}
        styles={customStyles}
        placeholder={placeholder}
        {...inputProps}
      />
      {!isReadOnly && (
        <span
          className={`text-red text-xs px-2 ${
            showError ? "visible" : "invisible"
          }`}
        >
          {errorMessage}
        </span>
      )}
    </section>
  );
};

export default Dropdown;
