import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button2, Checkbox, Dropdown, TextInput } from "../../components";
import FormLayout from "../../components/FormLayout";
import {
  GET_ONE_ADMIN,
  UPDATE_PROFILE_BY_ADMIN,
} from "../../store/actions/admin";

const initialAdmin = {
  name: "",
  email: "",
  contactNo: "",
  permissions: {},
};

const allPermissions = [
  { label: "read", value: "r" },
  { label: "write", value: "w" },
];

export default function AdminsForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);
  const { selectedAdmin } = useSelector((state) => state.admin);

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  const [admin, setAdmin] = useState(initialAdmin);

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_ADMIN.REQUEST({ id }));
    if (!id) {
      setAdmin(initialAdmin);
    }
  }, [id]);

  useEffect(() => {
    if (selectedAdmin && id) {
      setAdmin(selectedAdmin);
    }
  }, [selectedAdmin]);

  const checkPermission = (permission) => {
    if (permission) {
      if (["r", "w"].includes(permission)) {
        return true;
      }
    }
    return false;
  };

  const getLabel = useMemo(
    () => (val) => {
      if (["w"].includes(val)) {
        return "write";
      } else if (["r"].includes(val)) {
        return "read";
      } else {
        return "";
      }
    },
    [admin.permissions]
  );

  const handleSubmit = () => {
    if (admin?._id) {
      // edit case
      dispatch(UPDATE_PROFILE_BY_ADMIN.REQUEST(admin));
    }
  };
  const isAdminEnabled = get(admin, "permissions.admin", "") !== "";

  return (
    <div className="">
      {admin && (
        <FormLayout title={"Access Control"}>
          <div
            className={`grid grid-rows-3 sm:grid-rows-2 grid-flow-col gap-4 my-2`}
          >
            <TextInput
              title={"Name"}
              name={"name"}
              type={"text"}
              value={admin.name}
              onChange={(e) => {}}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter name"}
            />
            <TextInput
              title={"Email"}
              name={"email"}
              type={"text"}
              value={admin.email}
              onChange={(e) => {}}
              placeholder={""}
              showError={false}
              isReadOnly={true}
              errorMessage={"Please enter email"}
            />
            <TextInput
              title={"Contact Number"}
              name={"contactNo"}
              type={"text"}
              value={admin.contactNo}
              onChange={(e) => {}}
              placeholder={""}
              showError={false}
              isReadOnly={readOnly}
              errorMessage={"Please enter contactNo"}
            />
          </div>
          <span className={`font-bold text-lg`}>Permissions</span>
          <div
            // className={getClass(admin.permissions)}
            // className="mt-6"
            className={`grid grid-rows-10 sm:grid-rows-5 grid-flow-col gap-4 my-2 min-w-fit	`}
          >
            {admin.permissions &&
              Object.keys(admin.permissions)?.map((permission, index) => {
                let checkedVal = checkPermission(admin.permissions[permission]);
                const isAdmin = permission === "admin";
                return !isAdmin ? (
                  <section
                    key={index}
                    className={`flex flex-wrap	w-full items-center min-w-max `}
                  >
                    <span className="w-1/3">
                      <Checkbox
                        title={permission}
                        name={"permissionType"}
                        value={checkedVal}
                        onChange={() => {
                          let val = "";
                          if (!checkedVal) {
                            val = "r";
                          }
                          let tempAdmin = { ...admin };
                          tempAdmin.permissions[permission] = val;
                          setAdmin(tempAdmin);
                        }}
                        placeholder={""}
                        reverse={true}
                        showError={false}
                        isReadOnly={!isAdminEnabled || readOnly}
                        errorMessage={""}
                      />
                    </span>
                    <span className="grow">
                      <Dropdown
                        title={""}
                        name={"permissionType"}
                        value={{
                          value: admin.permissions[permission],
                          label: getLabel(admin.permissions[permission]),
                        }}
                        onChange={(e) => {
                          let tempAdmin = { ...admin };
                          tempAdmin.permissions[permission] = e.value;
                          setAdmin(tempAdmin);
                        }}
                        placeholder={""}
                        showError={false}
                        isReadOnly={readOnly || !checkedVal || !isAdminEnabled}
                        errorMessage={""}
                        options={allPermissions}
                      />
                    </span>
                  </section>
                ) : (
                  <section
                    key={index}
                    className={`flex mt-10 mb-4 w-full ${
                      darkTheme ? "" : "bg-gray-100"
                    } items-center rounded`}
                  >
                    <span className="flex items-center mt-4">
                      <Checkbox
                        title={permission}
                        name={"permissionType"}
                        value={checkedVal}
                        onChange={() => {
                          let val = "";
                          if (!checkedVal) {
                            val = "w";
                          }
                          let tempAdmin = { ...admin };
                          Object.keys(tempAdmin.permissions).map(
                            (p) => (tempAdmin.permissions[p] = val)
                          );
                          setAdmin(tempAdmin);
                        }}
                        placeholder={""}
                        reverse={true}
                        showError={false}
                        isReadOnly={readOnly}
                        errorMessage={""}
                      />
                    </span>
                  </section>
                );
              })}
          </div>
        </FormLayout>
      )}
      {!readOnly && <Button2 title={"Submit"} handleClick={handleSubmit} />}
    </div>
  );
}
