import { useState } from "react";

const ImageInput = ({ title, src, onChange, readOnly, id, styles }) => {
  const [loader, setLoader] = useState(false);

  const handleChange = (event) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0] && files[0]["type"].split("/")[0] === "image") {
      var reader = new FileReader();

      reader.onloadstart = () => setLoader(true);

      reader.onload = (event) => {
        onChange(event.target.result);
        setLoader(false);
      };

      reader.readAsDataURL(files[0]);
    }
  };
  console.log("Styles : ", styles);

  return (
    <label
      className="flex justify-between items-center cursor-pointer"
      htmlFor={`avatar1${id}`}
    >
      {loader ? (
        <button className="btn loading">loading</button>
      ) : src && src.length > 1 ? (
        <section className="w-full">
          <img
            src={src}
            alt=""
            className="w-full h-48 rounded mt-2"
            style={styles ? { ...styles } : {}}
          />
          {/* <div
            style={{
              backgroundImage: src ? `url(${src})` : null,
              backgroundSize: "cover",
              border: "1px solid black",
            }}
            className="w-full h-48 rounded mt-2 border-2"
          ></div> */}
        </section>
      ) : (
        <div
          className="w-full h-48 px-4 border-2 flex justify-center items-center"
          style={styles ? { ...styles } : {}}
        >
          <div className="w-24 rounded flex justify-center items-center">
            <span>{title}</span>
          </div>
        </div>
      )}
      {!readOnly && (
        <input
          id={`avatar1${id}`}
          type={"file"}
          accept="image/*"
          capture="camera"
          onChange={handleChange}
          style={{
            width: "0px",
            height: "0px",
            opacity: "0",
            position: "absolute",
            zIndex: -1,
          }}
        />
      )}
    </label>
  );
};

export default ImageInput;
