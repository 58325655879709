import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const LOGIN_WITH_EMAIL = actionCreator(
  ActionTypes.Auth.LOGIN_WITH_EMAIL
);
export const FORGOT_PASSWORD = actionCreator(ActionTypes.Auth.FORGOT_PASSWORD);
export const RESET_PASSWORD = actionCreator(ActionTypes.Auth.RESET_PASSWORD);

export const toggleTheme = (payload) => ({
  type: ActionTypes.Auth.TOGGLE_THEME,
  payload,
});

export const logout = actionCreator(ActionTypes.Auth.LOGOUT);

export const updateDeviceToken = actionCreator(
  ActionTypes.Auth.UPDATE_DEVICE_TOKEN
);
export const saveDeviceToken = (payload) => ({
  type: ActionTypes.Auth.SAVE_DEVICE_TOKEN,
  payload,
});
