import { get } from "lodash";
import { toast } from "react-toastify";

export const actionTypeCreator = (action) => {
  return {
    REQUEST: action + "_REQUEST",
    SUCCESS: action + "_SUCCESS",
    FAILURE: action + "_FAILURE",
  };
};

export const actionCreator = (ACTION) => {
  return {
    REQUEST: (payload = null, navigate = null, ...rest) => ({
      type: ACTION.REQUEST,
      payload,
      navigate,
    }),
    SUCCESS: (payload = null, navigate = null, ...rest) => ({
      type: ACTION.SUCCESS,
      payload,
      navigate,
    }),
    FAILURE: (payload = null, navigate = null, ...rest) => ({
      type: ACTION.FAILURE,
      payload,
      navigate,
    }),
  };
};

export const isFile = (input) => "File" in window && input instanceof File;

export const getMiddle = (prop, markers) => {
  let values = markers.map((m) => m[prop]);
  let min = Math.min(...values);
  let max = Math.max(...values);
  if (prop === "lng" && max - min > 180) {
    values = values.map((val) => (val < max - 180 ? val + 360 : val));
    min = Math.min(...values);
    max = Math.max(...values);
  }
  let result = (min + max) / 2;
  if (prop === "lng" && result > 180) {
    result -= 360;
  }
  return result;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const validateVehicleNumber = (number) => {
  let regex = new RegExp(
    /^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/
  );
  if (number == null) {
    return false;
  }
  if (regex.test(number) === true) {
    return true;
  } else {
    return false;
  }
};

export const isValid_Aadhaar_Number = (aadhaar_number) => {
  let regex = new RegExp(/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/);
  if (aadhaar_number == null) {
    return false;
  }
  if (regex.test(aadhaar_number) === true) {
    return true;
  } else {
    return false;
  }
};

export const isValid_Pan_Number = (pan_number) => {
  let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
  if (pan_number == null) {
    return false;
  }
  if (regex.test(pan_number) === true) {
    return true;
  } else {
    return false;
  }
};

export const getBase64Image = (image) => {
  let base64result = image.split(",")[1];
  return base64result;
};

export const validateEmail = (email) => {
  let re = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  return re.test(String(email).toLowerCase());
};

export const validatePhoneNumber = (input_str) => {
  let re = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
  return re.test(input_str);
  // formats for true validation response
  // (123) 456-7890
  // (123)456-7890
  // 123-456-7890
  // 1234567890
};

export const validateAddress = (address) => {
  if (!get(address, "addressLine1", "")) {
    toast.error("Please enter address line 1!");
    return false;
  }
  if (!get(address, "addressLine2", "")) {
    toast.error("Please enter address line 2!");
    return false;
  }
  if (!get(address, "city", "")) {
    toast.error("Please enter city!");
    return false;
  }
  if (!get(address, "state", "")) {
    toast.error("Please enter state!");
    return false;
  }
  if (!get(address, "country", "")) {
    toast.error("Please enter country!");
    return false;
  }
  if (!get(address, "pinCode", "")) {
    toast.error("Please enter pin-code!");
    return false;
  }
  return true;
};

export const isValidGSTNumber = (gst) => {
  let re = new RegExp(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  );
  return re.test(gst);
};
