import * as authActions from "./auth";
import * as adminActions from "./admin";
import * as taskActions from "./task";
import * as vendorsActions from "./vendors";
import * as agentsActions from "./agents";
import * as jobTypesActions from "./jobTypes";
import * as businessTypesActions from "./businessTypes";
import * as operationalCityActions from "./operationalCity";
import * as zonesActions from "./zones";

export * from "./auth";
export * from "./admin";
export * from "./task";
export * from "./vendors";
export * from "./agents";
export * from "./jobTypes";
export * from "./businessTypes";
export * from "./operationalCity";
export * from "./zones";

export const actionsMap = {
  ...authActions,
  ...adminActions,
  ...taskActions,
  ...vendorsActions,
  ...agentsActions,
  ...jobTypesActions,
  ...businessTypesActions,
  ...operationalCityActions,
  ...zonesActions,
};
