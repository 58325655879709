import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const GET_ALL_OPERATIONAL_CITIES = actionCreator(
  ActionTypes.OperationalCity.GET_ALL_OPERATIONAL_CITIES
);

export const CREATE_OPERATIONAL_CITY = actionCreator(
  ActionTypes.OperationalCity.CREATE_OPERATIONAL_CITY
);

export const FETCH_OPERATIONAL_CITY = actionCreator(
  ActionTypes.OperationalCity.FETCH_OPERATIONAL_CITY
);

export const UPDATE_OPERATIONAL_CITY = actionCreator(
  ActionTypes.OperationalCity.UPDATE_OPERATIONAL_CITY
);

export const DELETE_OPERATIONAL_CITY = actionCreator(
  ActionTypes.OperationalCity.DELETE_OPERATIONAL_CITY
);

export const setOperationalCityPagination = (payload) => ({
  type: ActionTypes.OperationalCity.SET_OPERATIONAL_CITY_PAGINATION,
  payload,
});
