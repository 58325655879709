import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createTaskService,
  updateTaskService,
  fetchTaskService,
  getAllTaskService,
  cancelTaskService,
} from "../../services/task";
import { toast } from "react-toastify";
import {
  CREATE_TASK,
  GET_ONE_TASK,
  GET_ALL_TASKS,
  UPDATE_TASK,
  CANCEL_TASK,
} from "../actions";

export function* createTaskSaga(action) {
  try {
    let res = yield call(createTaskService, action.payload);
    if (res.success) {
      yield put(CREATE_TASK.SUCCESS(res.data));
      toast.success("Task created successfully!");
      action.payload.navigate("/task");
    } else {
      yield put(CREATE_TASK.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_TASK.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateTaskSaga(action) {
  try {
    let res = yield call(updateTaskService, action.payload);
    if (res.success) {
      yield put(UPDATE_TASK.SUCCESS(res.data));
      toast.success("Task updated successfully!");
    } else {
      yield put(UPDATE_TASK.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_TASK.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getOneTaskSaga(action) {
  try {
    let res = yield call(fetchTaskService, action.payload);
    if (res.success) {
      yield put(GET_ONE_TASK.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_TASK.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_TASK.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllTasksSaga(action) {
  try {
    let res = yield call(getAllTaskService, action.payload);
    if (res.success) {
      yield put(GET_ALL_TASKS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_TASKS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_TASKS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* cancelTaskSaga(action) {
  try {
    let res = yield call(cancelTaskService, action.payload);
    if (res.success) {
      yield put(CANCEL_TASK.SUCCESS(action.payload));
    } else {
      yield put(CANCEL_TASK.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CANCEL_TASK.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const taskSaga = () => [
  takeLatest(ActionTypes.Tasks.CREATE_TASK.REQUEST, createTaskSaga),
  takeLatest(ActionTypes.Tasks.UPDATE_TASK.REQUEST, updateTaskSaga),
  takeLatest(ActionTypes.Tasks.GET_ONE_TASK.REQUEST, getOneTaskSaga),
  takeLatest(ActionTypes.Tasks.GET_ALL_TASKS.REQUEST, getAllTasksSaga),
  takeLatest(ActionTypes.Tasks.CANCEL_TASK.REQUEST, cancelTaskSaga),
];
export default taskSaga();
