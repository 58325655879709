import { call, takeLatest, put, select } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createOperationalCityService,
  getAllOperationalCityService,
  fetchOperationalCityService,
  updateOperationalCityService,
  deleteOperationalCityService,
} from "../../services/operationalCity";

import { toast } from "react-toastify";
import {
  CREATE_OPERATIONAL_CITY,
  FETCH_OPERATIONAL_CITY,
  GET_ALL_OPERATIONAL_CITIES,
  UPDATE_OPERATIONAL_CITY,
  DELETE_OPERATIONAL_CITY,
} from "../actions";

export function* getAllOperationalCitySaga(action) {
  try {
    const { paginationData } = yield select((state) => state.operationalCity);
    let res = yield call(
      getAllOperationalCityService,
      action.payload ? action.payload : paginationData
    );
    if (res.success) {
      yield put(GET_ALL_OPERATIONAL_CITIES.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_OPERATIONAL_CITIES.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_OPERATIONAL_CITIES.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* createOperationalCitySaga(action) {
  try {
    let res = yield call(createOperationalCityService, action.payload);
    if (res.success) {
      yield put(CREATE_OPERATIONAL_CITY.SUCCESS(res.data));
      action.payload.navigate("/operationalCity");
      toast.success("Operational City Created!");
    } else {
      yield put(CREATE_OPERATIONAL_CITY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_OPERATIONAL_CITY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateOperationalCitySaga(action) {
  try {
    let res = yield call(updateOperationalCityService, action.payload);
    if (res.success) {
      yield put(UPDATE_OPERATIONAL_CITY.SUCCESS(res.data));
      action.payload.navigate("/operationalCity");
      toast.success("Operational City Updated!");
    } else {
      yield put(UPDATE_OPERATIONAL_CITY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_OPERATIONAL_CITY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* fetchOperationalCitySaga(action) {
  try {
    let res = yield call(fetchOperationalCityService, action.payload);
    if (res.success) {
      yield put(FETCH_OPERATIONAL_CITY.SUCCESS(res.data));
    } else {
      yield put(FETCH_OPERATIONAL_CITY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      FETCH_OPERATIONAL_CITY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteOperationalCitySaga(action) {
  try {
    let res = yield call(deleteOperationalCityService, action.payload);
    if (res.success) {
      yield put(DELETE_OPERATIONAL_CITY.SUCCESS(action.payload));
      toast.success("Operational City Deleted!");
    } else {
      yield put(DELETE_OPERATIONAL_CITY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_OPERATIONAL_CITY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const operationalCitySaga = () => [
  takeLatest(
    ActionTypes.OperationalCity.CREATE_OPERATIONAL_CITY.REQUEST,
    createOperationalCitySaga
  ),
  takeLatest(
    ActionTypes.OperationalCity.GET_ALL_OPERATIONAL_CITIES.REQUEST,
    getAllOperationalCitySaga
  ),
  takeLatest(
    ActionTypes.OperationalCity.FETCH_OPERATIONAL_CITY.REQUEST,
    fetchOperationalCitySaga
  ),
  takeLatest(
    ActionTypes.OperationalCity.UPDATE_OPERATIONAL_CITY.REQUEST,
    updateOperationalCitySaga
  ),
  takeLatest(
    ActionTypes.OperationalCity.DELETE_OPERATIONAL_CITY.REQUEST,
    deleteOperationalCitySaga
  ),
];
export default operationalCitySaga();
