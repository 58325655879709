import { cloneDeep } from "lodash";
import { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

const SidebarTree = ({
  sidebarTreeData,
  isExpandedTree,
  toggleShowSidebar,
}) => {
  const [expanded, setExpanded] = useState([]);

  const handleClick = (e, hasChildren, index, label) => {
    e.stopPropagation();
    if (!hasChildren) {
      toggleShowSidebar();
      return;
    }
    let tempExpanded = cloneDeep(expanded);
    if (expanded.includes(index)) {
      // need to close expanded
      tempExpanded = tempExpanded.filter((i) => i !== index);
    } else {
      // need to expand
      tempExpanded.push(index);
    }
    setExpanded(tempExpanded);
  };

  const checkChildActive = (children) => {
    for (let child of children) {
      if (location.pathname.includes(child?.navigationRoute)) {
        return true;
      }
    }
    return false;
  };

  const location = useLocation();

  return (
    <div
      className={`w-full ${
        isExpandedTree ? "SidebarTree" : "SidebarTreeCollapsed"
      }`}
    >
      {sidebarTreeData?.map((sidebar, index) => {
        let { label, hasChildren, children, navigationRoute = "/" } = sidebar;
        let isExpanded = expanded.includes(index);

        let isAnyChildActive = hasChildren && checkChildActive(children);

        return (
          <div
            className={`flex w-full text-left my-1 cursor-pointer flex-col pl-4`}
            key={index}
            onClick={(e) => handleClick(e, hasChildren, index, label)}
          >
            {hasChildren ? (
              <section
                className={`flex w-full justify-between hover:bg-white hover:text-blue px-3 py-3 rounded-md items-center btn btn-ghost ${
                  isAnyChildActive && "text-blue"
                }`}
              >
                <span>{label}</span>
                <span>{isExpanded ? <FaAngleUp /> : <FaAngleDown />}</span>
              </section>
            ) : (
              <Link to={navigationRoute}>
                <section
                  className={`flex w-full justify-between px-3 py-3 rounded-md items-center btn btn-ghost ${
                    location.pathname.includes(navigationRoute)
                      ? "bg-blue text-white hover:bg-blue hover:text-white"
                      : "hover:bg-white hover:text-blue"
                  }`}
                >
                  <span>{label}</span>
                </section>
              </Link>
            )}
            <SidebarTree
              sidebarTreeData={children}
              isExpandedTree={isExpanded}
              toggleShowSidebar={toggleShowSidebar}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SidebarTree;
