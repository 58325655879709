import ActionTypes from "../actions/types";

const jobTypeInitialState = {
  allJobTypes: [],
  selectedJobType: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const jobTypeReducer = (state = jobTypeInitialState, action) => {
  let tempAllJobTypes = state.allJobTypes;
  switch (action.type) {
    case ActionTypes.JobTypes.SET_JOB_TYPES_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.JobTypes.GET_ALL_JOB_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.JobTypes.GET_ALL_JOB_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        allJobTypes: action.payload.jobType,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.JobTypes.GET_ALL_JOB_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        pages: 1,
        allJobTypes: [],
      };

    case ActionTypes.JobTypes.CREATE_JOB_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.JobTypes.CREATE_JOB_TYPE.SUCCESS:
      tempAllJobTypes.push(action.payload);
      return {
        ...state,
        allJobTypes: tempAllJobTypes,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.JobTypes.CREATE_JOB_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.JobTypes.GET_ONE_JOB_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.JobTypes.GET_ONE_JOB_TYPE.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedJobTypes: action.payload,
        error: null,
      };
    case ActionTypes.JobTypes.GET_ONE_JOB_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.JobTypes.UPDATE_JOB_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.JobTypes.UPDATE_JOB_TYPE.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        // selectedJobTypes: action.payload,
        error: null,
      };
    case ActionTypes.JobTypes.UPDATE_JOB_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.JobTypes.DELETE_JOB_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.JobTypes.DELETE_JOB_TYPE.SUCCESS:
      tempAllJobTypes = tempAllJobTypes.filter(
        (jt) => jt._id !== action.payload.id
      );
      return {
        ...state,
        allJobTypes: tempAllJobTypes,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.JobTypes.DELETE_JOB_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default jobTypeReducer;
