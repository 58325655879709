import ActionTypes from "../actions/types";

const vendorInitialState = {
  allVendors: [],
  selectedVendor: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const vendorReducer = (state = vendorInitialState, action) => {
  let tempAllVendors = [...state.allVendors];
  switch (action.type) {
    case ActionTypes.Vendors.SET_VENDOR_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.Vendors.GET_ALL_VENDORS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Vendors.GET_ALL_VENDORS.SUCCESS:
      return {
        ...state,
        loading: false,
        allVendors: action.payload.vendors,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Vendors.GET_ALL_VENDORS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        pages: 1,
        allVendors: [],
      };

    case ActionTypes.Vendors.CREATE_VENDOR.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Vendors.CREATE_VENDOR.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Vendors.CREATE_VENDOR.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.Vendors.FETCH_VENDOR.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Vendors.FETCH_VENDOR.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedVendor: action.payload,
        error: null,
      };
    case ActionTypes.Vendors.FETCH_VENDOR.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.Vendors.UPDATE_VENDOR.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Vendors.UPDATE_VENDOR.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        // selectedVendors: action.payload,
        error: null,
      };
    case ActionTypes.Vendors.UPDATE_VENDOR.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Vendors.BLOCK_VENDOR.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Vendors.BLOCK_VENDOR.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Vendors.BLOCK_VENDOR.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default vendorReducer;
