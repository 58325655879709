const sidebarContent = [
  {
    label: "Task",
    hasChildren: false,
    navigationRoute: "/task",
  },
  {
    label: "Vendors",
    hasChildren: false,
    navigationRoute: "/vendor",
  },
  {
    label: "Agents",
    hasChildren: false,
    navigationRoute: "/agent",
  },
  {
    label: "Platform Data",
    hasChildren: true,
    children: [
      {
        label: "Job Types",
        hasChildren: false,
        navigationRoute: "/jobType",
      },
      {
        label: "Business Types",
        hasChildren: false,
        navigationRoute: "/businessType",
      },
      {
        label: "Operational City",
        hasChildren: false,
        navigationRoute: "/operationalCity",
      },
      {
        label: "Zones",
        hasChildren: false,
        navigationRoute: "/zones",
      },
    ],
  },
  {
    label: "Settings",
    hasChildren: true,
    children: [
      {
        label: "Access Control",
        hasChildren: false,
        navigationRoute: "/all-admins",
      },
    ],
  },
];
export default sidebarContent;
