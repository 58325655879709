import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const GET_ALL_JOB_TYPES = actionCreator(
  ActionTypes.JobTypes.GET_ALL_JOB_TYPES
);

export const CREATE_JOB_TYPE = actionCreator(
  ActionTypes.JobTypes.CREATE_JOB_TYPE
);

export const GET_ONE_JOB_TYPE = actionCreator(
  ActionTypes.JobTypes.GET_ONE_JOB_TYPE
);

export const UPDATE_JOB_TYPE = actionCreator(
  ActionTypes.JobTypes.UPDATE_JOB_TYPE
);

export const DELETE_JOB_TYPE = actionCreator(
  ActionTypes.JobTypes.DELETE_JOB_TYPE
);

export const setJobTypePagination = (payload) => ({
  type: ActionTypes.JobTypes.SET_JOB_TYPES_PAGINATION,
  payload,
});
