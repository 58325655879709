import { useState } from "react";

const Avatar = ({ src, name, bgColor, textColor, onChange, readOnly }) => {
  const [loader, setLoader] = useState(false);

  const handleChange = (event) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      var reader = new FileReader();

      reader.onloadstart = () => setLoader(true);

      reader.onload = (event) => {
        onChange(event.target.result);
        setLoader(false);
      };

      reader.readAsDataURL(files[0]);
    }
  };
  return (
    <label
      className="flex flex-col items-center justify-center"
      htmlFor="avatar"
    >
      {loader ? (
        <button className="btn loading">loading</button>
      ) : src && src.length > 1 ? (
        <section className="w-24">
          <img src={`${src}`} alt="" className="w-24 h-24 rounded-full" />
        </section>
      ) : (
        <div className="avatar placeholder w-24">
          <div
            className="bg-neutral-focus text-neutral-content rounded-full w-24"
            style={bgColor ? { backgroundColor: bgColor } : {}}
          >
            <span
              className="text-3xl"
              style={textColor ? { color: textColor } : {}}
            >
              {name}
            </span>
          </div>
        </div>
      )}
      {!readOnly && (
        <input
          id="avatar"
          type={"file"}
          accept="image/*"
          capture="camera"
          onChange={handleChange}
          style={{
            width: "0px",
            height: "0px",
            opacity: "0",
            position: "absolute",
            zIndex: -1,
          }}
        />
      )}
    </label>
  );
};

export default Avatar;
