import FormLayout from "../../components/FormLayout";
import AutocompleteAddress from "./AddressAutoComplete";
import { Button2 } from "../../components";
import { useDispatch } from "react-redux";

const Address = ({
  address,
  readOnly,
  handleChange,
  updateAddress,
  hideTitle,
  title,
  hideBtn,
  getPlaceId,
}) => {
  return (
    <>
      {hideTitle ? (
        <>
          <AutocompleteAddress
            address={address}
            setAddress={(updatedAddress) => {
              handleChange({ ...address, ...updatedAddress });
            }}
            readOnly={readOnly}
            allowMultiple={false}
            getPlaceId={getPlaceId}
          />
          {!readOnly && !hideBtn && (
            <Button2 title={"Update Address"} handleClick={updateAddress} />
          )}
        </>
      ) : (
        <FormLayout title={title ? title : "Address"}>
          <AutocompleteAddress
            address={address}
            setAddress={(updatedAddress) => {
              handleChange(updatedAddress);
            }}
            readOnly={readOnly}
            allowMultiple={false}
            getPlaceId={getPlaceId}
          />
          {!readOnly && !hideBtn && (
            <Button2 title={"Update Address"} handleClick={updateAddress} />
          )}
        </FormLayout>
      )}
    </>
  );
};

export default Address;
