export const orderStatus = [
  { label: "Fresh", value: "fresh" },
  { label: "Assigned", value: "assigned" },
  { label: "Packed", value: "packed" },
  { label: "Shipped", value: "shipped" },
  { label: "Delivered", value: "delivered" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Return Requested", value: "returnRequested" },
  { label: "Return Approved", value: "returnApproved" },
  { label: "Return Rejected", value: "returnRejected" },
  { label: "Partially Refunded", value: "partiallyRefunded" },
  { label: "Fully Refunded", value: "fullyRefunded" },
];

export const gender = {
  male: "Male",
  female: "Female",
  other: "Other",
};

export const jobTypes = {
  fullTime: "fullTime",
  partTime: "partTime",
};
