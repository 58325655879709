import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createVendorService,
  getAllVendorService,
  fetchVendorService,
  updateVendorService,
  blockVendorService,
  addVendorAddressService,
  addVendorAadharService,
  addVendorPanNumberService,
} from "../../services/vendor";
import { toast } from "react-toastify";
import {
  CREATE_VENDOR,
  FETCH_VENDOR,
  GET_ALL_VENDORS,
  UPDATE_VENDOR,
  BLOCK_VENDOR,
  ADD_VENDOR_ADDRESS,
  ADD_VENDOR_AADHAR,
  ADD_VENDOR_PAN,
} from "../actions";

export function* getAllVendorSaga(action) {
  try {
    let res = yield call(getAllVendorService, action.payload);
    if (res.success) {
      yield put(GET_ALL_VENDORS.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_VENDORS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_VENDORS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* createVendorSaga(action) {
  try {
    let res = yield call(createVendorService, action.payload);
    if (res.success) {
      yield put(CREATE_VENDOR.SUCCESS(res.data));
      action.payload.navigate("/vendor");
      toast.success("Vendor created successfully!");
    } else {
      yield put(CREATE_VENDOR.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_VENDOR.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}
export function* addVendorAddressSaga(action) {
  try {
    let res = yield call(addVendorAddressService, action.payload);
    if (res.success) {
      yield put(ADD_VENDOR_ADDRESS.SUCCESS(res.data));
      // action.payload.navigate("/vendor");
      toast.success("Address added successfully!");
    } else {
      yield put(ADD_VENDOR_ADDRESS.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      ADD_VENDOR_ADDRESS.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* addVendorAadharSaga(action) {
  try {
    let res = yield call(addVendorAadharService, action.payload);
    if (res.success) {
      yield put(ADD_VENDOR_AADHAR.SUCCESS(res.data));
      // action.payload.navigate("/vendor");
      toast.success("Aadhar Updated!");
    } else {
      yield put(ADD_VENDOR_AADHAR.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      ADD_VENDOR_AADHAR.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}
export function* addVendorPanNumberSaga(action) {
  try {
    let res = yield call(addVendorPanNumberService, action.payload);
    if (res.success) {
      yield put(ADD_VENDOR_PAN.SUCCESS(res.data));
      // action.payload.navigate("/vendor");
      toast.success("PAN Updated!");
    } else {
      yield put(ADD_VENDOR_PAN.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      ADD_VENDOR_PAN.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateVendorSaga(action) {
  try {
    let res = yield call(updateVendorService, action.payload);
    if (res.success) {
      yield put(UPDATE_VENDOR.SUCCESS(res.data));
      action.payload.navigate("/vendor");
      toast.success("Vendor updated successfully!");
    } else {
      yield put(UPDATE_VENDOR.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_VENDOR.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* fetchVendorSaga(action) {
  try {
    let res = yield call(fetchVendorService, action.payload);
    if (res.success) {
      yield put(FETCH_VENDOR.SUCCESS(res.data));
    } else {
      yield put(FETCH_VENDOR.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      FETCH_VENDOR.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* blockVendorSaga(action) {
  try {
    let res = yield call(blockVendorService, action.payload);
    if (res.success) {
      yield put(BLOCK_VENDOR.SUCCESS(res.data));
      toast.success("Vendor blocked!");
    } else {
      yield put(BLOCK_VENDOR.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      BLOCK_VENDOR.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const vendorSaga = () => [
  takeLatest(ActionTypes.Vendors.CREATE_VENDOR.REQUEST, createVendorSaga),
  takeLatest(ActionTypes.Vendors.GET_ALL_VENDORS.REQUEST, getAllVendorSaga),
  takeLatest(ActionTypes.Vendors.FETCH_VENDOR.REQUEST, fetchVendorSaga),
  takeLatest(ActionTypes.Vendors.UPDATE_VENDOR.REQUEST, updateVendorSaga),
  takeLatest(
    ActionTypes.Vendors.ADD_VENDOR_AADHAR.REQUEST,
    addVendorAadharSaga
  ),
  takeLatest(
    ActionTypes.Vendors.ADD_VENDOR_PAN.REQUEST,
    addVendorPanNumberSaga
  ),
  takeLatest(ActionTypes.Vendors.BLOCK_VENDOR.REQUEST, blockVendorSaga),
  takeLatest(
    ActionTypes.Vendors.ADD_VENDOR_ADDRESS.REQUEST,
    addVendorAddressSaga
  ),
];
export default vendorSaga();
