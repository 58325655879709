import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const getAllOperationalCityService = async (data) => {
  try {
    let URL = apiURL + "operationalCity/getAll";
    let config = {
      params: {
        page: data.page,
        perPage: data.perPage,
        status: data.status,
      },
    };
    let res = await authRequest(URL, "GET", {}, config);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const createOperationalCityService = async (data) => {
  try {
    let URL = apiURL + "operationalCity/create";
    let res = await authRequest(URL, "POST", {}, data.data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateOperationalCityService = async (data) => {
  try {
    let URL = apiURL + "operationalCity/update/" + data.data.id;
    delete data.data.id;
    let res = await authRequest(URL, "POST", {}, data.data);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const fetchOperationalCityService = async (data) => {
  try {
    let URL = apiURL + "operationalCity/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const deleteOperationalCityService = async (data) => {
  try {
    console.log(".......... ", data);
    let URL = apiURL + "operationalCity/delete/" + data.id;
    let res = await authRequest(URL, "DELETE", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
